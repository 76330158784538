import React from 'react';
import Checkbox from '@mui/material/Checkbox';

interface CustomCheckboxWrapperProps {
    label?: string;
    checked: boolean;
    ml?: string;
    onChange: (checked: boolean) => void;
    isLast?: boolean;
}

const CustomCheckboxWrapper: React.FC<CustomCheckboxWrapperProps> = ({
    label,
    checked,
    ml = '8px',
    onChange,
    isLast,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    return (
        <div
            style={{
                padding: '8px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Checkbox checked={checked} onChange={handleChange} />
            <span style={{ marginLeft: ml }}>{label}</span>
        </div>
    );
};

export default CustomCheckboxWrapper;
