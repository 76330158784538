import React from 'react';
import { useState } from 'react';
import { IoChatbubblesOutline } from 'react-icons/io5';
import {  useNavigate } from 'react-router-dom';
import { hospitalRoutes } from '../../../utils/routeNames';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import { LoginCurve } from 'iconsax-react';
import Modal from '../../../reusable/LogoutModal';
import PrivateMode from '../../../components/Settings/Hospital/PrivateMode';
import BankAccount from '../../../components/Settings/Hospital/BankAccount';
import NonAdminProfile from '../../../components/Settings/MyProfile/NonAdminProfile';
import DashboardLayout from '../Dashboard/DashboardLayout';

const NonAdminSettings = () => {
    const hospitalAuthStore = useHospitalAuthStore((state) => state.hospital);
    const Tabs = ['Profile', 'Bank Account'];
    const [activeTab, setActiveTab] = useState('Profile');
    const [isActive, setIsActive] = useState(hospitalAuthStore.privateMode);
    const [showPrivateMode, setShowPrivateMode] = useState(false);

    const handlePrivateMode = (privateMode) => {
        setIsActive(privateMode);
    };
    // const handleShowPrivateMode = () => {
    //     setShowPrivateMode(true);
    // };
    const handleClosePrivateMode = () => {
        setShowPrivateMode(false);
    };

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const handleTab = (tab) => {
        setActiveTab(tab);
    };
    // const coordinates = hospitalAuthStore.location?.coordinates;
    const closeModal = () => {
        setShowModal(false);
    };
    const setModal = () => {
        setShowModal(true);
    };
    const clearStore = useHospitalAuthStore((state) => state.clearToken);
    const handleLogout = () => {
        clearStore();

        // Clear the token from sessionStorage
        sessionStorage.removeItem('persist:hospital-auth-store');
        navigate(hospitalRoutes.healthID, { replace: true });
    };
    // const specialty = hospitalAuthStore.metadata.specialties.map(
    //     (specialtyName: any) => {
    //         return specialtyName.name;
    //     }
    // );
    return (
        <DashboardLayout>
            <div className="px-2 md:px-8 py-6 ">
                {/*Title */}
                <div className="flex justify-between mb-[24px] items-center">
                    <h2 className="text-[28px] font-semibold">Settings</h2>

                    <div className="flex gap-6 items-center w-full sm:flex-1 justify-end">
                        <div className="flex gap-1 items-center">
                            <IoChatbubblesOutline size={24} />
                            <a
                                href="mailto:support@trackmedrec.com"
                                className="text-Tmred text-[16px]"
                            >
                                Chat with support
                            </a>
                        </div>
                        <button
                            onClick={setModal}
                            type="button"
                            className="bg-Tmred text-white px-6 py-[10px] text-base rounded-2xl flex items-center space-x-1"
                        >
                            <LoginCurve size="24" color="#fff" />
                            <p> Log Out</p>
                        </button>
                    </div>
                </div>

                <div className="bg-white px-8 py-[18px] rounded-lg">
                    <div className="border-b-[1px]">
                        <div className=" flex">
                            {Tabs?.map((tab, index) => (
                                <button
                                    key={index}
                                    className={`text-[14px] px-[16px] py-[8px] hover:text-Tmred ${
                                        activeTab === tab
                                            ? 'border-b-[2px] border-Tmred'
                                            : ''
                                    }`}
                                    onClick={() => handleTab(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="mt-6">
                        {/* Body */}
                        {activeTab === 'Profile' && <NonAdminProfile />}
                        {activeTab === 'Bank Account' && <BankAccount />}

                        {showPrivateMode && (
                            <PrivateMode
                                closeModal={handleClosePrivateMode}
                                privateModeChange={handlePrivateMode}
                                isActive={isActive}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Modal
                isVisible={showModal}
                onClose={() => closeModal()}
                onSave={handleLogout}
                buttonEnabled
                setButton
                saveText="Yes log out"
                closeText="No"
                showCloseButtonBorder={false}
            >
                <div className="bg-white">
                    <div className="bg-white">
                        <h1 className="text-[24px] font-semibold leading-[32px] mb-[16px]">
                            Log Out?
                        </h1>
                        <p className="text-[14px] font-medium text-[#534341]">
                            Are you sure you want to log out?
                        </p>
                    </div>
                </div>
            </Modal>
        </DashboardLayout>
    );
};
export default NonAdminSettings;
