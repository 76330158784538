import React, { Fragment, useEffect, useState } from 'react';

import HospitalLogo from '../../../reusable/HospitalLogo';
import Passcode from '../../../reusable/Passcode';
import Layout from '../Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import ButtomLogo from '../../../reusable/ButtomLogo';
import { pharmacyService } from '../../../services/pharmacyService';
import { usePharmacyAccountStore, usePharmacyAuthStore } from '../../../store/pharmacy/auth';

const OTP = () => {
    const [passcode, setPasscode] = useState<any>('');
    const [seconds, setSeconds] = useState(60);
    const [formValid, setFormValid] = useState(false);
    const pharmacyAccount = usePharmacyAccountStore((state) => state);
    const pharmacyAuthStore=usePharmacyAuthStore((state) => state)
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const formData = location.state?.formData;
    const email = location.state?.email;

    const validateAccessCodeMutation = useMutation((data:any) => pharmacyService.verifyOTP(data), {
        onSuccess: (data) => {
          console.log('handleLogin - OTP Validation Response:', data);

          if (data.data.success) {
            // Validation successful
            toast.success('Email successfully verified.');
            pharmacyAuthStore.updateUser(data.data.payload.data.user);
            
            setTimeout(() => {
              toast.dismiss();
              navigate(pharmacyRoutes.applicationProcessing);
            }, 1000);
            
            setIsLoading(false);
            setFormValid(false)
          
          } else {
            // Show an error toast for unsuccessful validation
            const errorMessage =data.data.response.data.error.message;
            console.log({errorMessage})
            setTimeout(() => {
              toast.error('error validating email');
            }, 1000);
            
            setIsLoading(false);
            setFormValid(false)
          }
        },
        onError: (error:any) => {
          // Handle API request error
          console.error("handleLogin - OTP Validation Error:", error);
          const errorMessage = error.response.data.error.message;
          console.log(errorMessage)
          setTimeout(() => {
            toast.error(errorMessage);
          }, 1000);
          setFormValid(false)
          setIsLoading(false);
        },
    });
    useEffect(() => {
        // console.log(hospitalAuthStore)
        let timer: NodeJS.Timeout;

        if (seconds > 0) {
            timer = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [seconds]);

    const handleRetryClick = () => {
        setSeconds(60);
        pharmacyService.resendOTP({
            email: email
        }) 
        .then((res) => {
            toast.success(res?.data?.message)
        })
        .catch((err) =>{
            console.log(err); 
            
            toast.error(err.response.data.error.message)
        })
    };

    
    useEffect(() => {
        if (passcode.length === 4) {
            setFormValid(true);
        } else {
            setFormValid(false);
            setIsLoading(false)
        }
    }, [passcode]);
   

      const handleNext = () => {
       
        setIsLoading(true)
    
        validateAccessCodeMutation.mutate({
            email: formData.email,
            otp: passcode,
        })
        
      setFormValid(false)
    
    };

    return (
        <Layout>
            <Fragment>
                <ToastContainer/>
                <div className="flex items-center justify-center flex-col   w-full  py-8 overflow-x-hidden ">
                    <div className="mx-auto flex flex-col items-center justify-center gap-4">
                        <HospitalLogo
                            logo={pharmacyAccount.pharmacy.logo}
                            text={pharmacyAccount.pharmacy.tagline}
                            title={pharmacyAccount.pharmacy.name}
                        />
                        <div className="flex items-center flex-col justify-center  border rounded-xl bg-[#FFf] py-[40px] sm:px-[80px] px-[40px] mt-7 max-w-[592px]">
                            <div className="flex items-center flex-col">
                                <div className="flex flex-col items-center justify-center">
                                    <h3 className="font-semibold text-[#130b0a] text-center text-[32px] ">
                                    Verify Super Admin Email
                                    </h3>
                                    <p className="font-normal text-[#534341] text-[14px] text-center max-w-[432px] ">
                                        Kindly enter the otp sent to{' '}
                                        <span className="text-[#C00010] font-semibold">
                                           
                                        {formData.email}
                                        </span>
                                    </p>
                                </div>
                                <form action="" className="pt-5">
                                    <Passcode
                                        passcode={passcode}
                                        setPasscode={setPasscode}
                                    />
                                </form>
                                <div className="mt-5 text-center flex items-center justify-center w-full">
                                    {passcode.length === 4 ? null : (
                                        <>
                                            {seconds ? (
                                                <p className="font-medium text-[#534341] text-[16px] text-center">
                                                    {seconds}s
                                                </p>
                                            ) : (
                                                <p className="font-medium text-[#534341] text-[16px] text-center">
                                                    Didn't receive code?{' '}
                                                    <span
                                                        className="text-[#C00010] underline cursor-pointer "
                                                        onClick={
                                                            handleRetryClick
                                                        }
                                                    >
                                                        Retry
                                                    </span>
                                                </p>
                                            )}
                                        </>
                                    )}
                                </div>

                                <button
                                    onClick={handleNext}
                                    disabled={!formValid}
                                    className={`w-full h-[40px] flex justify-center rounded-lg mt-10 items-center text-[#9d9b9b] font-semibold  hover:cursor-not-allowed transition-all duration-500  ${
                                        formValid
                                            ? 'bg-Tmred text-white hover:cursor-pointer '
                                            : 'bg-[#1D1B201F] opacity-50 cursor-not-allowed'
                                    }`}
                                >
                                     {isLoading ? 'validating...' : ' Next'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="ml-auto mt-10 w-full flex justify-end">
                    <ButtomLogo/>
                    </div>
                </div>
            </Fragment>
        </Layout>
    );
};

export default OTP;
