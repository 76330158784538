import React from 'react';
import { useQuery } from 'react-query';
import search from '../Images/Leading-icon.png';
import hospitalService from '../services/hospitalService';

export interface IOption {
    name: string;
    _id: string;
}

export const SpecialtyModal = ({
    searchQuery,
    setSearchQuery,
    selectedSpecialtyIds,
    onSpecialtyCheckboxChange,
    onClose,
}) => {
    const { data, isError, isLoading } = useQuery(
        'specialty',
        hospitalService.getSpecialties
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error loading speciality, please refresh your page</div>;
    }

    const filteredOptions = data.specialty.filter(({ name }) =>
        name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="modal fixed inset-0 flex items-center justify-center z-50">
            <div className="modal-content bg-white p-6 rounded shadow-xl lg:shadow-lg lg:max-w-[464px] lg:w-full w-[85%]">
                <div className="mb-4 flex items-center gap-2 border bg-lightTmrRed border-[#FFF8F7] p-[4px] rounded w-full">
                    <img src={search} alt="search" />
                    <input
                        type="text"
                        placeholder="Search for speciality"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full font-normal text-[16px] bg-transparent text-[#534341] border-transparent focus:border-transparent focus:ring-0"
                    />
                </div>
                <div className="max-h-[264px] overflow-y-auto px-3">
                    {filteredOptions.map((option: IOption, index: number) => (
                        <label
                            key={index}
                            className="flex items-center justify-between mb-4 font-normal text-[16px] text-[#201A19]"
                            style={{ lineHeight: '24px' }}
                        >
                            {option.name}
                            <input
                                type="checkbox"
                                value={option._id}
                                checked={selectedSpecialtyIds.includes(
                                    option._id
                                )}
                                onChange={() =>
                                    onSpecialtyCheckboxChange(option)
                                }
                                className="mr-2 custom-checkbox"
                            />
                        </label>
                    ))}
                </div>
                <button
                    className="bg-Tmred text-white hover:cursor-pointer font-normal py-2 px-4 rounded mt-5 w-full"
                    onClick={onClose}
                >
                    Done
                </button>
            </div>
        </div>
    );
};
