import { useEffect, useState } from 'react';
import Layout from '../Layout';
import DoctorAvatar from '../../../reusable/DoctorAvatar';
import Passcode from '../../../reusable/Passcode';
import { useNavigate } from 'react-router-dom';
import { hospitalRoutes } from '../../../utils/routeNames';
import HospitalAuthHeader from '../../../reusable/HospitalAuthHeader';
import {
    useHospitalAuthStore,
    useScreenTimeOut,
} from '../../../store/hospital/auth';
import { useMutation } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import BlockButton from '../../../reusable/BlockButton';
import ErrorState from '../../../reusable/ErrorState';
import Modal from '../../../reusable/LogoutModal';

type ScreenTimeoutProps = {
    code: string;
    token: string;
};

const ScreenTimeout = () => {
    const [showModal, setShowModal] = useState(false);
    const [code, setCode] = useState('');
    const hospitalAuthStore = useHospitalAuthStore((state) => state);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate();
    const timeOutStore = useScreenTimeOut();

    const mutation = useMutation((data: ScreenTimeoutProps) =>
        hospitalService.verifyPasscode(data)
    );

    useEffect(() => {
        if (code.length === 4) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [code]);
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            // let response: any = mutation.data;
            // const data = response?.data?.payload;
            // logger(data);
            timeOutStore.setIsTimeOut(false);
            navigate(hospitalRoutes.dashboard);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            setErrorMessage(err?.response?.data?.error?.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);
    const submitCode = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        mutation.mutate({
            code,
            token: hospitalAuthStore.token,
        });
    };
    const clearStore = useHospitalAuthStore((state) => state.clearToken);

    const handleLogout = () => {
        clearStore();
        sessionStorage.removeItem('hospital-auth-store'); // clear auth store
        timeOutStore.setIsTimeOut(false); // reset timeout store
        navigate(hospitalRoutes.healthID, { replace: true });
    };
    const closeModal = () => {
        setShowModal(false);
    };
    const setModal = () => {
        setShowModal(true);
    };
    return (
        <Layout>
            <HospitalAuthHeader
                logo={hospitalAuthStore.hospital.logo}
                text={hospitalAuthStore.hospital.tagline}
                title={hospitalAuthStore.hospital.name}
            />
            <Modal
                isVisible={showModal}
                onClose={() => closeModal()}
                onSave={handleLogout}
                buttonEnabled
                setButton
                saveText="Yes log out"
                closeText="No"
                showCloseButtonBorder={false}
            >
                <div className="bg-white">
                    <div className="bg-white">
                        <h1 className="text-[24px] font-semibold leading-[32px] mb-[16px]">
                            Log Out?
                        </h1>
                        <p className="text-[14px] font-medium text-[#534341]">
                            Are you sure you want to log out?
                        </p>
                    </div>
                </div>
            </Modal>

            <div className="flex flex-col justify-center items-center  md:mb-[80px]">
                <div className="flex flex-col justify-center border border-[#00000033] bg-white rounded-[25px] px-[80px] py-[40px] max-w-[592px]">
                    <div className="flex flex-col items-center">
                        <h1 className=" font-semibold text-[32px] text-center ">
                            Welcome Back
                        </h1>

                        <p className="font-normal text-[#534341] text-[14px] text-center tracking-widest mb-1">
                            Kindly enter your passcode
                        </p>

                        <DoctorAvatar
                            name={`${hospitalAuthStore.user.firstName} ${hospitalAuthStore.user.lastName}`}
                            img={hospitalAuthStore.user.image}
                        />

                        <p className=" text-[16px] tracking-wide">
                            Screen lock activates after{' '}
                            <span className=" text-[22px] font-semibold">
                                5mins
                            </span>{' '}
                            of inactivity
                        </p>
                        {errorMessage && <ErrorState message={errorMessage} />}
                        <form onSubmit={submitCode}>
                            <Passcode passcode={code} setPasscode={setCode} />
                            <BlockButton
                                text="Log in"
                                disabled={buttonDisabled}
                                type="submit"
                                isLoading={isLoading}
                            />
                        </form>

                        <button
                            className="text-[16px] text-Tmred mt-7"
                            onClick={setModal}
                        >
                            Log out
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ScreenTimeout;
