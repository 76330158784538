import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import hospitalService from '../../../../services/hospitalService';
import { formatDateDmy, formatDatesDmy } from '../../../../utils/helpers';
import drugType from '../../../../Images/drugType.png';
import ViewMedicationModal from '../PatientModals/ViewMedicationModal';
import { ToastContainer } from 'react-toastify';

interface PatientProps {
    tmrNum?: any;
}
interface MedicHistory {
    id: number;
    createdAt: string;
}

interface MedicationHistory {
    medications: MedicHistory[]; // Define the medicalReport property here
}
interface Prescription {
    drugName: string;
    dosage: string;
    sideEffects: string;
    doctorsNote: string;
}

interface MedicDetails {
    createdAt: string;
    doctorId: string;
    doctorName: string;
    hospitalId: string;
    hospitalName: string;
    id: number;
    medicalReportId: number;
    patientTmrNumber: string;
    prescriptions: Prescription;
    specialty: string;
    updatedAt: string;
}
interface MedicationDetails {
    medication: MedicDetails;
}

interface PatientProps {
    tmrNum?: any;
}
interface MedicalReport {
    id: number;
    createdAt: string;
}

interface MedicalReportHistory {
    patientTmrNumber: string;
    medicalReport: MedicalReport[]; // Define the medicalReport property here
}
interface Prescription {
    drugName: string;
    dosage: string;
    sideEffects: string;
    doctorsNote: string;
}

interface Medication {
    id: number;
    medicalReportId: number;
    patientTmrNumber: string;
    prescriptions: Prescription;
    createdAt: string;
}
interface MedicalReportDetails {
    tmrNumber: string;
    medicalReportId: string | number | null;
    medicalReport: {
        createdAt: string;
        doctorName: string;
        specialty: string;
        diagnosis: {
            observation: string;
            treatment: string;
            verdicts: string;
            // Add other properties as needed
        };
        // Add other properties of the medical report as needed
    } | null;
    medications: Medication[];
}

const useMedicationDetails = (
    tmrNumber: string,
    medicalReportId: string | number | null
) => {
    return useQuery<MedicationDetails>(
        ['medictionDetails', tmrNumber, medicalReportId], // Unique query key
        async () => {
            // Fetch medical report details using HospitalService
            return await hospitalService.getMedicationDetails(
                tmrNumber,
                medicalReportId
            );
        }
    );
};
const useMedicalReportDetails = (
    patientTmrNumber: string,
    medicalsReportId: string | number | null
) => {
    return useQuery<MedicalReportDetails>(
        ['medicalReport', patientTmrNumber, medicalsReportId], // Unique query key
        async () => {
            // Fetch medical report details using HospitalService
            return await hospitalService.getMedicalReportDetails(
                patientTmrNumber,
                medicalsReportId
            );
        }
    );
};
// const useMedicalReportDetails = (
//     tmrNumber: string,
//     medicalReportId: string | number | null
// ) => {
//     return useQuery<MedicalReportDetails>(
//         ['medicalReport', tmrNumber, medicalReportId], // Unique query key
//         async () => {
//             // Fetch medical report details using HospitalService
//             return await hospitalService.getMedicalReportDetails(
//                 tmrNumber,
//                 medicalReportId
//             );
//         }
//     );
// };
const useMedicationHistory = (patientTmrNumber: string) => {
    return useQuery<MedicationHistory>(
        ['medicationHistory', patientTmrNumber], // Unique query key
        async () => {
            return await hospitalService.getMedicationHistory(patientTmrNumber);
        }
    );
};
const MedicationsTab: React.FC<PatientProps> = ({ tmrNum }) => {
    const [activeItemId, setActiveItemId] = useState<number | null>(null);
    const [open, setOpen] = useState(false);
    const [medicalReportId, setMedicalReportId] = useState<
        string | number | null
    >(1);

    const {
        data: historyData,
        isError: historyError,
        isLoading: historyLoading,
    } = useMedicationHistory(tmrNum);
    const history = historyData?.medications;

    const {
        data: medicationDetails,
        isError: detailsError,
        isLoading: detailsLoading,
        refetch: refetchMedicationDetails,
    } = useMedicationDetails(tmrNum, medicalReportId);

    const medication = medicationDetails?.medication;
    const {
        data: reportDetails,
        isError: detailError,
        isLoading: detailLoading,
        refetch: refetchReportDetails,
    } = useMedicalReportDetails(tmrNum, medicalReportId);
    const medicals = reportDetails?.medicalReport;

    const handleReportClick = (id: number) => {
        setMedicalReportId(id);
        setActiveItemId(id);
    };
    const openModal = () => {
        setOpen(true);
    };
    const closeModal = () => {
        setOpen(false);
    };
    useEffect(() => {
        // Set the first item as active when component mounts
        if (history && history.length > 0) {
            const firstItemId = history[0].id;
            setActiveItemId(firstItemId);
            setMedicalReportId(firstItemId);
        }
    }, [history]);
    useEffect(() => {
        // Fetch report details when medicalReportId changes
        if (medicalReportId) {
            refetchReportDetails();
        }
    }, [medicalReportId]);
    useEffect(() => {
        // Fetch report details when medicalReportId changes
        if (medicalReportId) {
            refetchMedicationDetails();
        }
    }, [medicalReportId]);

    const createdAtString = medication?.createdAt; // Assuming medicalReportDetails is your data object
    let formattedDate: string;

    if (createdAtString) {
        const createdAtDate = new Date(createdAtString);
        if (!isNaN(createdAtDate.getTime())) {
            formattedDate = createdAtDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            });
        } else {
            formattedDate = 'Invalid Date';
        }
    } else {
        formattedDate = 'Unknown';
    }

    // console.log('the dateeeeee:', formattedDate);
    // console.log(medicationDetails);

    if (historyLoading || detailsLoading || detailLoading) {
        return <div>Loading...</div>;
    }

    if (historyError || detailsError || detailError) {
        return <div>An error occurred</div>;
    }

    return (
        <div className="bg-white p-1 sm:p-4 max-w-[590px]">
            <ToastContainer />
            {/* {access ? ( */}
            <div className="flex justify-between space-x-5">
                <div className="flex-1">
                    <h2 className="text-[22px] font-normal text-lightBrown mb-2">
                        Medications
                    </h2>
                    {!medicationDetails?.medication && (
                        <div>
                            <h2>EMPTY!!!!</h2>
                        </div>
                    )}
                    {medicationDetails && medicationDetails?.medication && (
                        <>
                            <div className="pb-5 border-b-[1px] border-brown100">
                                <div className="flex flex-col sm:flex-row justify-between mb-3">
                                    <div className="flex items-center space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Date:
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {medication?.createdAt
                                                ? formatDateDmy(
                                                      medication?.createdAt
                                                  )
                                                : 'N/A'}
                                        </p>
                                    </div>
                                    <div className="flex items-center space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Time:
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {medication && medication?.createdAt
                                                ? (() => {
                                                      const createdAtDate =
                                                          new Date(
                                                              medication?.createdAt
                                                                  ? medication?.createdAt
                                                                  : ''
                                                          );
                                                      const hours =
                                                          createdAtDate.getHours();
                                                      const minutes =
                                                          createdAtDate.getMinutes();
                                                      const formattedHours =
                                                          hours % 12 === 0
                                                              ? 12
                                                              : hours % 12;
                                                      const amPm =
                                                          hours >= 12
                                                              ? 'pm'
                                                              : 'am';
                                                      return `${formattedHours}:${
                                                          minutes < 10
                                                              ? '0'
                                                              : ''
                                                      }${minutes}${amPm}`;
                                                  })()
                                                : 'N/A'}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row justify-between mb-3">
                                    <div className="flex space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Prescribed By:{' '}
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {medication?.doctorName}
                                        </p>
                                    </div>
                                    <div className="flex space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Speciality:
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {medication?.specialty}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row justify-between">
                                    <div className="flex space-x-1 sm:space-x-2">
                                        <p className="text-[11px] font-medium text-dark100">
                                            Medicatl Verdict:{' '}
                                        </p>
                                        <p className="text-xs font-medium text-lightBrown">
                                            {medicals?.diagnosis?.verdicts}
                                        </p>
                                    </div>
                                    <div className="flex space-x-1 sm:space-x-2">
                                        <a
                                            href=""
                                            className="text-Tmred text-xs cursor-pointer hover:underline"
                                        >
                                            View Diagnosis
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ul>
                                    {medicationDetails?.medication && (
                                        <div>
                                            {/* {medicationDetails?.medications.map( */}
                                            {/* (medication) => ( */}
                                            <div
                                                key={medication?.id}
                                                className="mt-2 flex items-center justify-between"
                                            >
                                                <div className="flex items-center gap-2">
                                                    <img
                                                        src={drugType}
                                                        alt="drugPicture"
                                                    />
                                                    <div>
                                                        <p className="text-xs font-medium text-lightBrown">
                                                            {
                                                                medication
                                                                    ?.prescriptions
                                                                    ?.drugName
                                                            }
                                                        </p>
                                                        <p className="text-[11px] font-medium mt-2 text-lightBrown">
                                                            Dosage:{' '}
                                                            {
                                                                medication
                                                                    ?.prescriptions
                                                                    ?.dosage
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <button
                                                    onClick={openModal}
                                                    type="button"
                                                    className="text-Tmred text-xs cursor-pointer"
                                                >
                                                    View
                                                </button>
                                            </div>
                                            {/* ) */}
                                            {/* )} */}
                                        </div>
                                    )}
                                </ul>
                            </div>
                            {open && (
                                <ViewMedicationModal
                                    closeModal={closeModal}
                                    medication={medication}
                                />
                            )}
                        </>
                    )}
                </div>
                <div className="text-center bg-lightGray200 px-2 rounded-lg">
                    <p className="mb-3 text-xs pt-1">History</p>
                    <ul className="max-h-[18rem] overflow-y-auto">
                        {history?.map((report) => {
                            return (
                                <li
                                    className={`p-1 my-2 text-[11px] rounded-md font-medium ${
                                        activeItemId === report.id
                                            ? 'bg-lightPink100 '
                                            : ''
                                    } text-dark100`}
                                    style={{ cursor: 'pointer' }}
                                    key={report.id}
                                    onClick={() => handleReportClick(report.id)}
                                >
                                    {formatDateDmy(report.createdAt)}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default MedicationsTab;
