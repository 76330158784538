import React, { Fragment, useEffect, useState } from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import { BsTrash } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../Dashboard/DashboardLayout';
import Input from '../../../reusable/Forms/Input';
import SelectElement from '../../../reusable/Forms/Select';
import { useMutation, useQuery } from 'react-query';
import patientService from '../../../services/patientService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logger from '../../../utils/logger';
import { hospitalRoutes } from '../../../utils/routeNames';
import {
    resolveCountryCode,
    resolveCountryNameToCode,
} from '../../../utils/countryState';
import ReactFlagsSelect from 'react-flags-select';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import DatePickerWithMinAge from '../../../reusable/Forms/DatePickerwithMinAge';
import { Helmet } from 'react-helmet';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CustomizedProgressBars from '../../../reusable/Forms/ProgressBar';
import EmergencyPasscode from './EmergencyPasscode';
import Exit from './Exit';
import hospitalService from '../../../services/hospitalService';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
const convertCountryNamesToCodes = (countries) => {
    return countries.map(
        ({ country }) => resolveCountryNameToCode(country) || ''
    );
};
const NewPatient = () => {
    const navigate = useNavigate();
    // interface FormData {
    //     [key: string]: string;
    // }
    const [countryCode, setCountryCode] = useState<string>('NG');
    const defaultCountry = resolveCountryCode('NG');
    const [formData, setformData] = useState<any>({
        countryCode,
        countryStates: defaultCountry?.states || [],
        country: defaultCountry?.name || '',
        firstName: '',
        lastName: '',
        email: '',
        dob: '',
        Gender: '',
        phone_number: '',
        state: '',
        city: '',
        address: '',
        Genotype: '',
        BloodGroup: '',
        allergies: [],
        emergencyNumber: '',
        planId: '',
    });
    const handleCountryChange = (code: string) => {
        const newCountry = resolveCountryCode(code);
        setCountryCode(code);
        console.log(countryCode);
        setformData((prevData: any) => ({
            ...prevData,
            country: newCountry?.name || '',
            countryStates: newCountry?.states || [],
        }));
    };

    const [formValid, setFormValid] = useState(false);
    const [allergy, setAllergy] = useState('');
    const [allergiesList, setAllergiesList] = useState<string[]>([]);
    const mutation = useMutation((data: any) =>
        patientService.addHospitalPatient(data)
    );
    const [showEmergencyNo, setShowEmergencyNo] = useState(false);
    const [formDataCopy, setFormDataCopy] = useState<any>(null);
    const [exitPage, setShowExitPage] = useState(false);
    const [formModified, setFormModified] = useState(false);
    const country = useHospitalAuthStore((state) => state.hospital.country);
    const [countryCodes, setCountryCodes] = useState<string[]>([]);
    //Get country for planId
    const { data } = useQuery(
        ['plans', country],
        () =>
            country
                ? hospitalService.plans(country)
                : Promise.reject('No country'),
        {
            enabled: !!country,
        }
    );
    const plans = data?.plans;
    console.log(plans);

    useEffect(() => {
        if (mutation.isSuccess) {
            setFormValid(false);
            console.log('success', mutation.data);
            let payload: any = mutation.data;
            const data = payload.data;
            logger(data.message);
            toast.success('Patient successfully added');
            setTimeout(() => {
                toast.dismiss();
                navigate(hospitalRoutes.patient);
            }, 1000);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setFormValid(false);
            let err: any = mutation.error;
            toast.error(err.response.data.error.message);
        } 
    }, [mutation.isError, mutation.error]);

    useEffect(() => {
        if (
            formData.firstName !== '' &&
            formData.lastName !== '' &&
            formData.email !== '' &&
            formData.dob !== '' &&
            formData.Gender !== '' &&
            formData.country !== '' &&
            formData.phone_number !== '' &&
            formData.state !== '' &&
            formData.city !== '' &&
            formData.address !== '' &&
            formData.Genotype !== '' &&
            formData.BloodGroup !== ''
        ) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [
        formData.city,
        formData.address,
        formData.state,
        formData.firstName,
        formData.lastName,
        formData.email,
        formData.dob,
        formData.Gender,
        formData.country,
        formData.phone_number,
        formData.Genotype,
        formData.BloodGroup,
    ]);
    useEffect(() => {
        console.log('formDataCopy:', formDataCopy);
    }, [formDataCopy]);
    const {
        data: countryData,
        isError: countryError,
        isLoading: countryLoading,
    } = useQuery('countries', patientService.getCountries);

    useEffect(() => {
        if (countryData && Array.isArray(countryData?.countries)) {
            console.log('API countryData:', countryData.countries);
            const countryCodesArray = convertCountryNamesToCodes(
                countryData?.countries
            );
            setCountryCodes(countryCodesArray);
        } else {
            console.error(
                'Expected an array of country names but got:',
                countryData
            );
        }
    }, [countryData]);

    if (countryLoading) {
        return <div>Loading...</div>;
    }

    if (countryError) {
        return <div>Error loading country, please refresh your page</div>;
    }

    const handleChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { name, value } = e.target;
        setformData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        setFormModified(true);
    };

    const handleDateChange = (date: any) => {
        setformData((prevData: any) => ({
            ...prevData,
            dob: date,
        }));
    };
    const handleCloseModal = () => {
        setShowEmergencyNo(false);
    };

    const handleleaveModal = () => {
        setShowExitPage(false);
    };

    // const handleAddPatient = () => {
    //     const {
    //         firstName,
    //         lastName,
    //         email,
    //         dob,
    //         Gender,
    //         country,
    //         phone_number,
    //         state,
    //         city,
    //         address,
    //         Genotype,
    //         BloodGroup,
    //     } = formData;

    //     const formattedDob = dayjs(dob).format('MM-DD-YYYY');

    //     //  request body object
    //     const requestBody = {
    //         firstName,
    //         lastName,
    //         email,
    //         dob: formattedDob,
    //         phoneNumber: phone_number,
    //         address,
    //         gender: Gender,
    //         city,
    //         state,
    //         country,
    //         bloodGroup: BloodGroup,
    //         genotype: Genotype,
    //         allergies: allergiesList.join(', '),
    //     };

    //     setFormValid(false);
    //     mutation.mutate(requestBody);
    //     console.log({ requestBody });
    // };

    const handleAllergyChange = (e: any) => {
        setAllergy(e.target.value);
    };
    const handleAddAllergy = (e: any) => {
        e.preventDefault();
        if (allergy !== '') {
            setAllergiesList([...allergiesList, allergy]);
            setAllergy('');
        }
    };
    const handleSelectChange = (e: any) => {
        const { name, value } = e.target;
        setformData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // const handleProceed= ()=>{
    //     const copy = { ...formData };
    //     setFormDataCopy(copy);
    //     setShowEmergencyNo(true)
    // }
    const handleProceed = () => {
        const copy = { ...formData };

        // Include allergies only if allergiesList is not empty
        if (allergiesList.length > 0) {
            copy.allergies = allergiesList;
        } else {
            // If no allergies are entered, set allergies field to an empty array
            copy.allergies = [];
        }

        // Save the copy to formDataCopy state
        setFormDataCopy(copy);
        setShowEmergencyNo(true);
    };
    const updateFormDataWithEmergencyNumber = (emergencyNumber) => {
        setformData((prevFormData) => ({
            ...prevFormData,
            emergencyNumber: emergencyNumber,
        }));
    };
    const handleExit = () => {
        if (formModified) {
            setShowExitPage(true);
        } else {
            leavePage();
        }
    };

    const leavePage = () => {
        setFormModified(false);
        setShowExitPage(false);
        navigate(-1);
    };

    const handleDeleteAllergy = (index: number) => {
        const updatedAllergies = [...allergiesList];
        updatedAllergies.splice(index, 1);
        setAllergiesList(updatedAllergies);
    };

    return (
        <Fragment>
            <Helmet>
                <title>TMR | New Patient</title>
            </Helmet>
            <ToastContainer />
            <DashboardLayout>
                {/* {errorMessage && <ErrorState message={errorMessage} />} */}
                <div className="my-4 mx-1 sm:mx-6">
                    <div
                        className="flex gap-4 items-center cursor-pointer"
                        onClick={handleExit}
                    >
                        <BsChevronLeft className="text-dark100 w-4 h-4 " />
                        <h1 className="font-semibold md:text-[28px] text-lg  text-lightBrown">
                            New Patient
                        </h1>
                    </div>
                    <div className="py-1">
                        <p className="flex items-end justify-end text-right font-medium text-[16px] pb-1">
                            1 out of 2
                        </p>
                        <CustomizedProgressBars value={50} />
                    </div>
                    <div className="flex flex-col-reverse sm:flex-row sm:gap-5 mt-12 ">
                        <div className="flex flex-col basis-4/6 bg-white rounded-2xl p-4 mb-[80px]">
                            <h1 className="text-center rounded-tl-2xl rounded-tr-2xl py-2 text-[22px] font-medium tracking-wide bg-lightTmrRed w-[stretch]">
                                Patient Details
                            </h1>
                            <div className="my-6">
                                <div className="flex gap-1 sm:gap-6 my-4">
                                    <Input
                                        type="text"
                                        name="firstName"
                                        onChange={handleChange}
                                        value={formData.firstName}
                                        label="First Name"
                                        className="py-4 rounded-lg border border-[#534341] pl-4 outline-none mt-4 placeholder:text-sm font-normal w-full"
                                    />
                                    <Input
                                        type="text"
                                        name="lastName"
                                        onChange={handleChange}
                                        value={formData.lastName}
                                        label="Last Name"
                                        className="py-4 rounded-lg border border-[#534341] pl-4 outline-none mt-4 placeholder:text-sm font-normal w-full"
                                    />
                                </div>
                                <div className="flex gap-1 sm:gap-6 my-4 w-full">
                                    <div className="w-full">
                                        <Input
                                            type="text"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            label="Email Address"
                                            className="py-4 rounded-lg border border-[#534341] pl-4 outline-none mt-4 placeholder:text-sm font-normal w-full"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <DatePickerWithMinAge
                                            label="Date of Birth (16+)"
                                            value={formData.dob}
                                            onChange={(date) =>
                                                handleDateChange(date)
                                            }
                                            className="!w-[100%]"
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-1 sm:gap-6 my-4 w-full">
                                    <div className="w-full ">
                                        <SelectElement
                                            onChange={handleSelectChange}
                                            value={formData.Gender}
                                            name="Gender"
                                            options={['female', 'male']}
                                        />
                                    </div>
                                    {/* <Input
                                    type="text"
                                    name="phone_number"
                                  
                                    onChange={handleChange}
                                    value={formData.phone_number}
                                    label="Phone No(with country code)"
                                    className="py-4 rounded-lg border border-[#534341] pl-4 outline-none mt-4 placeholder:text-sm font-normal w-full "
                                /> */}
                                    <PhoneInput
                                        country={countryCode.toLowerCase()}
                                        disableDropdown={true}
                                        enableSearch={true}
                                        value={formData.phone_number}
                                        onChange={(value) =>
                                            setformData({
                                                ...formData,
                                                phone_number: `+${value}`,
                                            })
                                        }
                                        placeholder="Phone Number"
                                        inputClass="!h-[100%] !w-[100%]"
                                    />
                                </div>
                                <div className="flex gap-1 sm:gap-5 my-4">
                                    <div className="w-full ">
                                        <ReactFlagsSelect
                                            countries={countryCodes}
                                            selected={countryCode}
                                            searchable
                                            fullWidth={false}
                                            placeholder="Country"
                                            onSelect={handleCountryChange}
                                            className="!h-[110%]"
                                        />
                                    </div>

                                    <div className="w-full ">
                                        <div className="md:flex-1">
                                            <FormControl
                                                fullWidth
                                                sx={{ minWidth: 120 }}
                                                size="small"
                                            >
                                                <InputLabel
                                                    id="demo-select-small-label"
                                                    htmlFor={'state'}
                                                >
                                                    {'State'}
                                                </InputLabel>

                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    label="State"
                                                    onChange={
                                                        handleSelectChange
                                                    }
                                                    name="state"
                                                    value={formData.state}
                                                    className="w-full  p-2 "
                                                >
                                                    {formData.countryStates.map(
                                                        (state: any) => (
                                                            <MenuItem
                                                                key={state.code}
                                                                value={
                                                                    state.name
                                                                }
                                                            >
                                                                {state.name}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <Input
                                        type="text"
                                        name="city"
                                        onChange={handleChange}
                                        value={formData.city}
                                        label="City"
                                        className="text-[#534341] font-medium py-4 rounded-lg border border-[#534341] px-4 outline-none mt-4 placeholder:text-sm w-full"
                                    />
                                </div>
                                <textarea
                                    rows={3}
                                    className="rounded-lg border border-[#534341] p-4 outline-none mt-4 placeholder:text-sm font-normal w-full"
                                    placeholder="Address"
                                    name="address"
                                    onChange={handleChange}
                                />

                                <div className="flex justify-end border-t-[2px] border-[#D8C2BF] my-4 ">
                                    <button
                                        onClick={handleProceed}
                                        disabled={!formValid}
                                        className={`mt-4 py-[10px] px-6 rounded-lg  ${
                                            !formValid
                                                ? 'bg-[#1D1B201F] text-[#999797] cursor-not-allowed'
                                                : 'bg-Tmred text-white hover:cursor-pointer '
                                        } `}
                                    >
                                        {/* {isLoading ? 'creating...':'Proceed'} */}
                                        {'Proceed'}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col basis-2/6 bg-white  rounded-2xl p-4 mb-[80px]">
                            <h1 className="text-center rounded-tl-2xl rounded-tr-2xl py-2 text-[22px] font-medium tracking-wide bg-lightTmrRed w-[stretch]">
                                Medical Form
                            </h1>
                            <div className="my-4">
                                <SelectElement
                                    options={[
                                        'A negative [A-]',
                                        'B positive [B+]',
                                        'B negative [B-]',
                                        'AB positive [AB+]',
                                        'AB negative [AB-]',
                                        'O positive [O+]',
                                        'O negative [O-]',
                                    ]}
                                    onChange={handleSelectChange}
                                    value={formData.BloodGroup}
                                    name="BloodGroup"
                                />
                                <div className="my-4"></div>
                                <SelectElement
                                    options={['AA', 'AS', 'SS', 'SC']}
                                    onChange={handleSelectChange}
                                    value={formData.Genotype}
                                    name="Genotype"
                                />
                                <div className="my-3"></div>
                                <Input
                                    type="text"
                                    name="allergy"
                                    onChange={handleAllergyChange}
                                    value={allergy}
                                    label="Enter Allergy"
                                    className="text-[#534341] font-medium py-4 rounded-lg border border-[#534341] pl-4 outline-none mt-4 placeholder:text-sm w-full"
                                />
                                <div className="flex justify-center flex-wrap gap-4">
                                    {allergiesList.map((allergyItem, index) => (
                                        <button className="flex gap-2  items-center text-[#534341] font-medium py-4 rounded-lg border border-[#534341] p-4 outline-none mt-4">
                                            <span className="truncate  w-20">
                                                {allergyItem}
                                            </span>
                                            <div
                                                onClick={() =>
                                                    handleDeleteAllergy(index)
                                                }
                                            >
                                                <BsTrash />
                                            </div>
                                        </button>
                                    ))}
                                </div>
                                <div className="flex justify-center">
                                    <button
                                        className="flex gap-2 items-center text-[#534341] font-medium py-4 rounded-lg border border-[#534341] p-4 outline-none mt-4"
                                        onClick={handleAddAllergy}
                                    >
                                        <FaPlus /> <span>Add Allergy</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showEmergencyNo && (
                        <EmergencyPasscode
                            closeModal={handleCloseModal}
                            formData={formDataCopy}
                            setFormData={updateFormDataWithEmergencyNumber}
                        />
                    )}

                    {exitPage && (
                        <Exit
                            closeModal={handleleaveModal}
                            leavePage={leavePage}
                        />
                    )}
                </div>
            </DashboardLayout>
        </Fragment>
    );
};

export default NewPatient;
