import React, { useEffect, useState, memo } from 'react';
import Modal from '../../../../reusable/Modal';
import search from '../../../../Images/Leading-icon.png';
import { useMutation, useQuery } from 'react-query';
import hospitalService from '../../../../services/hospitalService';
import { toast } from 'react-toastify';
import { useHospitalAuthStore } from '../../../../store/hospital/auth';

const AssignModal = ({
    showModal,
    onClose,
    modalText,
    headingText,
    appointmentDetails,
    refetch,
    assignType,
}) => {
    const [doctors, setDoctors] = useState<any[]>([]);
    const [filteredDoctors, setFilteredDoctors] = useState<any[]>([]);
    const [assignNewDocLoading, setAssignNewDocLoading] = useState<boolean>(false);
    const [doctorSearchKeyword, setDoctorSearchKeyword] = useState<string>('');
    const [filteredSpecialty, setFilteredSpecialty] = useState<any[]>([]);
    const [specialtySearchKeyword, setSpecialtySearchKeyword] = useState<string>('');
    const [selectedDoctor, setSelectedDoctor] = useState<{[key: string]: any}>({});
    const [selectedSpecialty, setSelectedSpecialty] = useState<{[key: string]: any}>({});
    const [buttonEnabled, setButtonEnabled] = useState(false);

    // get doctors available doctors 
    // const { data: doctorData } = useQuery(['doctors'], () =>
    //     hospitalService.getUsers('doctor')
    // );
    // get active doctors 
const { data: doctorData } = useQuery(['doctors', 'active'], () =>
    hospitalService.getUsers('doctor', 'active')
);

    // update doctor state
    useEffect(() => {
        if (doctorData && doctorData?.users) {
            setDoctors(doctorData.users);
        }
    }, [doctorData]);
    // set and update filtered doctors based on search keyword
    useEffect(() => {
        if (doctors) {
            const filteredDoctors = doctorSearchKeyword ? doctors.filter((doctor) => {
                const searchQueryLower = doctorSearchKeyword.toLowerCase();
                const fullName = `${doctor.firstName.toLowerCase()} ${doctor.lastName.toLowerCase()}`;
                return fullName.includes(searchQueryLower);
            }) : doctors;
            setFilteredDoctors(filteredDoctors)
        }
    }, [doctors, doctorSearchKeyword]);

    // get hospital specialty from store
    const specialties = useHospitalAuthStore(
        (state) => state.hospital.metadata.specialties
    );
    // set and update filtered specialty based on search keyword
    useEffect(() => {
        if (specialties) {
            const filteredSpecializations = specialtySearchKeyword ? specialties?.filter((spec) => {
                const searchQueryLower = specialtySearchKeyword?.toLowerCase();
                const fullName = spec.name.toLowerCase();
                return fullName.includes(searchQueryLower);
            }) : specialties;
            setFilteredSpecialty(filteredSpecializations)
        }
    }, [specialties, specialtySearchKeyword]);

    // update filtered doctor when specialty is selected
    useEffect(() => {
        if (Object.keys(selectedSpecialty).length) {
            const filteredDoctors = doctors.filter(doctor =>
                doctor.specialties.some(specialty => specialty._id === selectedSpecialty._id)
            );
            setFilteredDoctors(filteredDoctors)
        }
    }, [selectedSpecialty]);

    // assign doctor mutation    
    const assignMutation = useMutation(
        (doctorId: string) =>
            hospitalService.assignNewDoctor(appointmentDetails?._id, doctorId),
        {
            onSuccess: (response) => {
                setAssignNewDocLoading(false);
                toast.success(
                    response.data?.message ||
                        'Doctor assigned to appointment successfully'
                );
                closeModal3();
                setButtonEnabled(true);
                refetch();
            },
            onError: (error: any) => {
                setAssignNewDocLoading(false);
                toast.error(
                    error.response?.data?.error?.message ||
                        'Error assigning Doctor'
                );
            },
        }
    );

    // invite staff mutation
    const inviteStaffMutation = useMutation(
        (data: { appointmentId: string, userId: string }) =>
            hospitalService.inviteStaffToTelemedicine(data.appointmentId, { userId: data.userId }),
        {
            onSuccess: (response) => {
                setAssignNewDocLoading(false);
                toast.success(
                    response.data?.message || 'Staff has been invited successfully'
                );
                closeModal3();
                setButtonEnabled(true);
            },
            onError: (error: any) => {
                setAssignNewDocLoading(false);
                toast.error(
                    error.response?.data?.error?.message || 'Error inviting Staff'
                );
            },
        }
    );

    const handleAssignNewDoc = (doctorId: string) => {
        setButtonEnabled(false);
        setAssignNewDocLoading(true);
        if(assignType === 'appointment') {
            assignMutation.mutate(doctorId);
        } else if((assignType === 'telemedicine')) {
            inviteStaffMutation.mutate({ appointmentId: appointmentDetails._id, userId: doctorId });
        } else {
            toast.error('Invalid assign type')
        }
    };

    const closeModal3 = () => {
        onClose();
        setButtonEnabled(false);
        //TODO: set state back to default
    };

    const handleSpecializationChange = (e: any) => {
        setSpecialtySearchKeyword(e.target.value);
        setButtonEnabled(true);
    };

    const handleDoctorSearchChange = (e: any) => {
        setDoctorSearchKeyword(e.target.value);
        setButtonEnabled(true);
    };

    return (
        <Modal
            isVisible={showModal}
            onClose={onClose}
            onSave={() => handleAssignNewDoc(selectedDoctor?._id)}
            buttonEnabled={buttonEnabled}
            setButton={setButtonEnabled}
            saveText={modalText}
            closeText="Cancel"
            showCloseButtonBorder={true}
        >
            <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 ">
                <h2 className="font-semibold text-2xl mb-5">
                    {headingText}
                </h2>
                <div className="bg-[#1D1B2008] p-2 h-[40rem] lg:h-[450px] lg:w-[664px]  rounded-2xl flex flex-col overflow-y-auto sm:flex-row gap-6 lg:items-center">
                    <div className=" lg:px-2 pt-3 lg:pt-0 overflow-y-auto  lg:w-[322px] lg:h-[434px] rounded-2xl flex items-center flex-col">
                        <div className="mb-5 flex items-center gap-2 rounded w-full bg-[#FFF]">
                            <img src={search} alt="search" />
                            <input
                                type="text"
                                placeholder="Search speciality "
                                value={specialtySearchKeyword}
                                onChange={handleSpecializationChange}
                                className="w-full font-normal text-[16px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                            />
                        </div>
                        <div className="lg:h-[240px] px-3 w-full  bg-[#F8F8F8] pt-3 overflow-y-auto">
                            {filteredSpecialty.length === 0 ? (
                                <p className="text-center text-gray-500 py-2">
                                    Search does not match
                                </p>
                            ) : (
                                filteredSpecialty.map((row, index) => (
                                    <div
                                        key={index}
                                        className={`flex py-2 px-4 items-center gap-8 cursor-pointer ${
                                            Object.keys(selectedSpecialty).length &&
                                            selectedSpecialty._id ===
                                                row._id
                                                ? 'bg-[#EDE0DE] rounded-tl-2xl rounded-bl-2xl'
                                                : 'hover:bg-[#EDE0DE] '
                                        } justify-between mb-4 font-normal text-[12px] text-[#201A19] `}
                                        onClick={() => {
                                            setSelectedSpecialty(row)
                                            setButtonEnabled(true);
                                        }}
                                        
                                        style={{ lineHeight: '24px' }}
                                    >
                                        <div className="flex flex-col">
                                            <div className="flex items-center ">
                                                <div>
                                                    <p className="truncate w-[226px] font-normal text-deep_black text-sm ">
                                                        {row.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div className=" px-2 lg:h-[434px] lg:w-[342px] bg-[#fff] rounded-2xl flex items-center flex-col">
                        <div className="mb-4 flex items-center gap-2 rounded w-full">
                            <img src={search} alt="search" />
                            <input
                                type="text"
                                placeholder="Search doctor"
                                value={doctorSearchKeyword}
                                onChange={handleDoctorSearchChange}
                                className="w-full font-normal text-[16px] bg-transparent text-[#534341] border-transparent outline-none focus:ring-0"
                            />
                        </div>
                        <div className="h-full lg:h-[240px] w-full overflow-y-auto">
                            {filteredDoctors.length === 0 ? (
                                <p className="text-center text-gray-500 py-2">
                                    Search does not match
                                </p>
                            ) : (
                                filteredDoctors.map((row, index) => (
                                    <div
                                        key={index}
                                        className={`flex p-2 items-center gap-8 cursor-pointer ${
                                            Object.keys(selectedDoctor).length &&
                                            selectedDoctor._id ===
                                                row._id
                                                ? 'bg-[#EDE0DE] rounded-tl-2xl rounded-bl-2xl'
                                                : 'hover:bg-[#EDE0DE] hover:rounded-tl-2xl hover:rounded-bl-2xl'
                                        } justify-between mb-4 font-normal text-[12px] text-[#201A19] border-b-[2px]`}
                                        onClick={() => {
                                            setSelectedDoctor(row)
                                            setButtonEnabled(true);
                                        }}
                                        style={{ lineHeight: '24px' }}
                                    >
                                        <div className="flex flex-col">
                                            <div className="flex items-center gap-x-[10px]">
                                                <img
                                                    className="object-contain h-[40px] w-[40px] rounded-full"
                                                    style={{
                                                        boxShadow:
                                                            'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
                                                    }}
                                                    src={row.image}
                                                    alt=""
                                                />{' '}
                                                <div>
                                                    <p className="truncate w-[226px] font-normal text-deep_black text-sm ">
                                                       
                                                        {`${row?.salutation && row?.salutation !== "undefined" ? row?.salutation + ' ' : ''} ${row.firstName} ${row.lastName}`}
                                                    </p>
                                                    <div className="flex w-full items-center justify-between ">
                                                        <p className="text-light_gray text-[12px] font-medium">
                                                            {row.specialties
                                                                .length > 0
                                                                ? row
                                                                      .specialties[0]
                                                                      .name
                                                                : ''}
                                                        </p>
                                                        {row.specialties
                                                            .length > 1 && (
                                                            <div className="flex items-center py-2">
                                                                <p className="text-xs font-medium text-lightBrown100 bg-lightGray rounded-xl px-3 py-1">
                                                                    +
                                                                    {row
                                                                        .specialties
                                                                        .length -
                                                                        1}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default memo(AssignModal);
