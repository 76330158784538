import { useState, useEffect } from 'react';
import add from '../../../Images/user-add.svg';
import Filter from '../../../Images/filtericon.svg';
import search from '../../../Images/Leading-icon.png';
import TotalAdmin from './TotalAdmin';
import { Link } from 'react-router-dom';
import { useAdminStore } from '../../../store/pharmacy/auth';
import Modal from '../../../reusable/Modal';
import dayjs, { Dayjs } from 'dayjs';
import DatePickerElementX from '../../../reusable/Forms/DatePickerElementX';

const UserManagement = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedFromDate, setSelectedFromDate] = useState<Dayjs | null>(
        dayjs('')
        );
    const [selectedToDate, setSelectedToDate] = useState<Dayjs | null>(
        dayjs('')
    );
    const [selectedUserGroupButtons, setSelectedUserGroupButtons] = useState<any>([]);
    
    const [selectedGenderButton, setGenderButton] = useState<string | null>(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [buttonEnabled, setButton] = useState(false);
    const [selectedSpecialty, setSelectedSpecialty] = useState('')
    const [queryParamStrings, setQueryParamStrings] = useState<any>();
    const [queryParam, setQueryParam] = useState<any>();


    const formatDate = (dateString) => {
        if (!dateString) return null;  // Immediately return null if input is null or empty

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {  // Check if the date object is valid
            return null;
        } else {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, hence add 1
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    };


    useEffect(() => {
        //Checks if the params are selected then set it to the queryParam that will be used to fetch the data from the endpoint
      let queryParamsArr: string[] = [];

        const formattedFromDate = selectedFromDate ? formatDate(selectedFromDate) : null;
        const formattedToDate = selectedToDate ? formatDate(selectedToDate) : null;

        if (formattedFromDate && formattedToDate) {
        queryParamsArr.push(`from=${formattedFromDate}`);
        queryParamsArr.push(`to=${formattedToDate}`);
    }
        if (selectedUserGroupButtons) {
            queryParamsArr.push(`permission=${selectedUserGroupButtons.join(', ')}`);
        }
        if (selectedGenderButton) {
            queryParamsArr.push(`gender=${selectedGenderButton}`);
        }   
          
        if (selectedOrder) {
            queryParamsArr.push(`order=${selectedOrder}`);
        }

        const queryParams = '&' + queryParamsArr.join('&');
        console.log(queryParams);
    
        setQueryParamStrings(queryParams);
      
    }, [selectedOrder,selectedFromDate,selectedToDate, selectedUserGroupButtons, selectedSpecialty, selectedGenderButton])

    const handleOptionChange = (option: any) => {
        setSelectedOrder(option);
    };
    const adminCount = useAdminStore((state) => state.adminCount);
    const closeModal = () => {
        setShowModal(false);
    };
    const setModal = () => {
        setShowModal(true);
    };
    const handleUserGroupButtonsClick = (value: string) => {
        if(value === "All"){
            setSelectedUserGroupButtons('')
        } else{
            if (selectedUserGroupButtons.includes(value)) {
                setSelectedUserGroupButtons(selectedUserGroupButtons.filter(group => group !== value));
            } else {
                setSelectedUserGroupButtons([...selectedUserGroupButtons, value]);
            }
        }
        
        console.log(value);
    };
    const handleGenderButtonClick = (value: string) => {
        setGenderButton(value); 
        console.log(value);
    };

 
    
    const handleSubmit = (e: React.FormEvent) => {
        console.log('Form submitted!');
        setShowModal(false);
        setButton(false);
        // onFilter(queryParam) 
        setQueryParam(queryParamStrings);
    };

    const handleClearAll = () => {
        setShowModal(false);
        setSelectedFromDate(null)
        setSelectedToDate(null)
        setGenderButton('')
        setSelectedUserGroupButtons('') 
        setSelectedSpecialty('')
        setSelectedOrder(null)
        setQueryParam('')
    }



    const userGroupArr = ["Inventory", "Admin", "Order", "Pharmacy Wallet", "Setting" ] 

    return (
        <div className="mx-8 mt-6">
            <div className="flex justify-between">
                <h2 className="font-semibold text-[28px]">User Management</h2>
                <Link
                    to="/pharmacy/add-admin"
                    className="bg-Tmred text-white rounded-lg p-3 flex items-center space-x-2 cursor-pointer"
                >
                    <img src={add} alt="" />
                    <span> Add Admin</span>
                </Link>
            </div>
            <div className="bg-white p-4 rounded-2xl mt-8">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                        <h2 className="md:text-2xl text-sm font-semibold text-dark">
                            Total Admins
                        </h2>
                        <button
                            type="button"
                            className="md:text-[22px] text-sm bg-red py-1 text-white px-1 md:px-[11.64px] rounded-[290.909px]"
                        >
                            {adminCount}
                        </button>
                    </div>
                    <div className="flex space-x-[52px] justify-between">
                        <div className=" flex items-center gap-2 border h-11 max-w-[328px] w-full bg-lightGray300 border-lightGray300 rounded-[28px]">
                            <img
                                src={search}
                                alt="search"
                                className="w-10 h-10"
                            />
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="bg-lightGray300 focus:outline-none outline-none focus:ring-0"
                            />
                        </div>
                        <div className=" "
                            onClick={setModal}
                        >
                            <img
                                src={Filter}
                                alt="filterIcon"
                                className="cursor-pointer h-10 w-10"
                            />
                        </div>
                    </div>
                </div>
                <TotalAdmin isData={true} searchTerm={searchTerm} queryParam = {queryParam}/>
            </div>
            <Modal
                isVisible={showModal}
                onClose={() => closeModal()}
                onSave={handleSubmit}
                buttonEnabled={!buttonEnabled}
                setButton={setButton}
                saveText="Save"
                closeText="Close"
                showCloseButtonBorder={false}
                clearAll = {true}
                onClearAll={handleClearAll}
            >
                <div className=" ">
                    <div className="bg-white md:p-6 p-2 rounded-[28px] mx-2 ">
                        <h2 className="font-semibold text-2xl mb-5">
                            Filter By
                        </h2>
                       
                        
                        <div className=" my-9">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                User Group
                            </h2>
                            <div className="md:flex max-w-[500px] flex-wrap gap-2 items-center  ">
                                <button
                                    type="button"
                                    className={`px-4 border-[1px] text-lightBrown border-lightBrown100 font-medium text-sm py-[6px] rounded-lg ${
                                        selectedUserGroupButtons === '' ? 'bg-[#1D1B2014]' : ''
                                    }`}
                                    onClick={() => handleUserGroupButtonsClick("All")} 
                                >
                                    All 
                                </button>
                                {
                                    userGroupArr.map((userGroup, index) =>(
                                        <button
                                            key={index}
                                            type="button"
                                            className={`px-4 border-[1px] text-lightBrown border-lightBrown100 font-medium text-sm py-[6px] rounded-lg ${
                                                selectedUserGroupButtons.includes(userGroup) ? 'bg-[#1D1B2014]' : ''
                                            }`}
                                            onClick={() => handleUserGroupButtonsClick(userGroup)}
                                        >
                                            {userGroup}
                                        </button>
                                    ))
                                }
                            </div>
                        </div>
                        <div className=" my-9">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                Gender
                            </h2>
                            <div className="md:flex items-center sm:space-x-3 space-y-1 md:space-y-0 ml-4 ">
                                <button
                                    type="button"
                                    className={`px-4 border-[1px] text-lightBrown border-lightBrown100 font-medium text-sm py-[6px] rounded-lg ${
                                        selectedGenderButton === 'male' ? 'bg-[#1D1B2014]' : ''
                                    }`}
                                    onClick={() => handleGenderButtonClick('male')}
                                >
                                    Male
                                </button>
                                <button
                                    type="button"
                                    className={`px-4 border-[1px] text-lightBrown border-lightBrown100 font-medium text-sm py-[6px] rounded-lg ${
                                        selectedGenderButton === 'female' ? 'bg-[#1D1B2014]' : ''
                                    }`}
                                    onClick={() => handleGenderButtonClick('female')}
                                >
                                    Female
                                </button>
                                
                            </div>
                        </div>
                        <div className="mb-5">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                Date Joined
                            </h2>
                            <div className="md:flex md:space-x-6 space-y-1 md:space-y-0 ml-4 sm:max-w-[362px]">
                                <DatePickerElementX
                                    label="From"
                                    value={selectedFromDate}
                                    onChange={(date) => {
                                        setSelectedFromDate(date);
                                    }}
                                />

                                <DatePickerElementX
                                    label="To"
                                    value={selectedToDate}
                                    onChange={(date) => {setSelectedToDate(date)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="">
                            <h2 className="font-medium text-base text-dark100 mb-3">
                                Order
                            </h2>
                            <div className="ml-4">
                                <label className="flex items-center space-x-2 cursor-pointer mb-2">
                                    <input
                                        type="checkbox"
                                        className="p-[2px] rounded-sm cursor-pointer"
                                        checked={selectedOrder === 'asc'}
                                        onChange={() =>
                                            handleOptionChange('asc')
                                        }
                                    />
                                    <span className="text-base font-normal">
                                        Ascending
                                    </span>
                                </label>
                                <label className="flex items-center space-x-2 cursor-pointer ">
                                    <input
                                        type="checkbox"
                                        className="p-[2px] rounded-sm  cursor-pointer"
                                        checked={
                                            selectedOrder === 'desc'
                                        }
                                        onChange={() =>
                                            handleOptionChange('desc')
                                        }
                                    />
                                    <span className="text-base font-normal">
                                        Descending
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default UserManagement;