import { useEffect, useState } from 'react';
import Profile from './Profile';
import { formatDateDmy, standardAmountFormat } from '../../utils/helpers';
import { useHospitalAuthStore } from '../../store/hospital/auth';
import adPattern1 from '../../Images/adPattern1.png';
import adPattern2 from '../../Images/adPattern2.png';

const ProfileDetails = ({ ad }: any) => {
  
 
  const [AdsDetails, setAdsDetails] = useState<any>({});
  const currency = useHospitalAuthStore((state) => state.hospital.currency);
  
  useEffect(() => {
    if (ad) {
        setAdsDetails(ad?.ads);
    }
  }, [AdsDetails, ad]);
  
  return (
    <div>
      <Profile ads={AdsDetails} />
     
      <div className="flex xl:space-x-32 items-start md:flex-col lg:flex-row flex-col sm:flex-row ">
        <div>
          <div>
          <h2 className="sm:text-[22px] text-sm font-medium  text-[#201A19] my-3 pb-3">
      Ads Details
          </h2>
          {AdsDetails?.adContent?.type === "template" && (<>
            <p className="text-xs sm:text-base font-medium text-[#201A19] pb-1">
              Content Title
            </p>
            <p className="text-xs sm:text-sm font-medium text-[#857371]">
            {AdsDetails?.adContent?.template?.contentTitle || ''}
            </p>
            </>
          )}
          </div>
          {AdsDetails?.adContent?.type === "template" && (
          <div className="my-6">
            <p className="text-xs sm:text-base font-medium text-[#201A19] pb-1 ">
              Content Description
            </p>
            <p className="text-base sm:text-sm font-medium text-[#857371] max-w-72">
            {AdsDetails?.adContent?.template?.description || ''}
            </p>
          </div>
          )}
          <div className="my-6">
            <p className="text-xs sm:text-base font-medium text-[#201A19] pb-1">
              Ad Format
            </p>
            <p className="text-xs sm:text-sm font-medium text-[#857371]">
            {AdsDetails?.adContent?.type || ''}
            </p>
          </div>
          
          <div className="my-6">
            <p className="text-xs sm:text-base font-medium text-[#201A19] pb-1">
              Amount
            </p>
            <p className="text-xs sm:text-sm font-medium text-[#857371]">
             {standardAmountFormat(AdsDetails?.fee, currency)}
            </p>
          </div>

          <div className="my-6">
            <p className="text-xs sm:text-base font-medium text-[#201A19] pb-1">
              Start Date
            </p>
            <p
              style={{ color: '#857371' }}
              className="text-xs sm:text-sm font-medium text-lightBrown100 w-full">
               {formatDateDmy(AdsDetails?.schedule?.starts)}
            </p>
          </div>
          <div className="my-6">
            <p className="text-xs sm:text-base font-medium text-[#201A19] pb-1">
              Expiry Date
            </p>
            <p
              style={{ color: '#857371' }}
              className="text-xs sm:text-sm font-medium text-lightBrown100 w-full">
              {formatDateDmy(AdsDetails?.schedule?.expires)}
            </p>
          </div>
          <div className="my-6">
            <p className="text-xs sm:text-base font-medium text-[#201A19] pb-1">
              URL
            </p>
            { AdsDetails?.targetUrl === "website" ? 
                <a 
                  className='text-xs sm:text-sm font-medium text-Tmred underline w-full' 
                  href={`${AdsDetails?.websiteUrl}?utm_source=trackmedrec.com&utm_content=${AdsDetails?.adContent?.template?.contentTitle}&utm_medium=com.trackmedrec`} 
                  target='_blank'
                  rel="noreferrer"
                >
                    {AdsDetails?.targetUrl}
                </a>
            : '-'
            }
          </div>
         
        </div>
        <div>
          <div className="">
          <h2 className="sm:text-[22px] text-sm font-medium  text-[#201A19] my-3 pb-3">
          Audience
          </h2>
            <p className="text-xs sm:text-base font-medium text-[#201A19] pb-2">
              Country
            </p>
            <div
              className="text-sm text-[#857371]
                            text-center flex flex-wrap gap-x-3 justify-start ">
             
               
                  <button
                    className=" border-[1px]
                             px-[10px] py-[3px]
                              border-gray-400 rounded-lg mb-3 text-sm font-medium text-[#696969]">
                       {AdsDetails?.audience?.country || ''} 
                  </button>
                
            
            </div>
          </div>
          <div className="my-6">
            <p className="text-xs sm:text-base font-medium text-[#201A19] pb-2">
              State
            </p>
            <div
              className="text-sm text-[#857371]
                            text-center flex flex-wrap gap-x-3 justify-start ">
          
                  <button
                    className=" border-[1px]
                             px-[10px] py-[3px]
                              border-gray-400 rounded-lg mb-3 text-sm font-medium text-[#696969]">
                    {AdsDetails?.audience?.state || ''} 
                  </button>
               
            </div>
          </div>
          <div className="my-6">
            <p className="text-xs sm:text-base font-medium text-[#201A19] pb-2">
              Age
            </p>
            <div
              className="text-sm text-[#857371]
                            text-center flex flex-wrap gap-x-3 justify-start ">
           
                
                  <button
                    className=" border-[1px]
                             px-[10px] py-[3px]
                              border-gray-400 rounded-lg mb-3 text-sm font-medium text-[#696969]">
                   {`${AdsDetails?.audience?.ageRange?.min || ''} - ${AdsDetails?.audience?.ageRange?.max || ''} years`}
                  </button>
                
            </div>
          </div>
          <div className="my-6">
            <p className="text-xs sm:text-base font-medium text-[#201A19] pb-2">
              Gender
            </p>
            <div
              className="text-sm text-[#857371]
                            text-center flex flex-wrap gap-x-3 justify-start ">
              
                  <button
                    className=" border-[1px]
                             px-[10px] py-[3px]
                              border-gray-400 rounded-lg mb-3">
                    {AdsDetails?.audience?.gender || ''}
                  </button>
               
            </div>
          </div>
          <div className="mt-16">
            <p className="text-xs sm:text-base font-medium text-[#201A19] pb-2">
              Preview
            </p>
            {AdsDetails?.adContent?.type === "template" && (
              <div
                className='flex sm:w-[341px] h-[162px] w-[241px] bg-no-repeat bg-center bg-cover rounded-xl p-4'
                style={{ 
                  backgroundImage: AdsDetails?.adContent?.template?.pattern === '1' ? `url(${adPattern1})` : `url(${adPattern2})`, 
                  backgroundColor: AdsDetails?.adContent?.template?.color || '#000'
              }}
              >
                <div>
                  <p className='text-[16px] font-semibold text-white mb-2'>
                    {AdsDetails?.adContent?.template?.contentTitle || ''}
                  </p>
                  <p className='text-sm text-white font-normal mb-4'>
                    {AdsDetails?.adContent?.template?.description || ''}
                  </p>
                  <button
                    style={{ color: AdsDetails?.adContent?.template?.color || '#C00010' }}
                    className='bg-[#FFF8F7]  py-2 px-4 rounded pointer-events-none'
                  >
                    {AdsDetails?.adContent?.template?.callToAction || 'Learn More'}
                  </button>
                </div>
              </div>
            )}

           { AdsDetails?.adContent?.type === "banner" && (
            <img
              src={AdsDetails?.adContent?.banner}
              alt=""
              className="sm:w-[341px] h-[162px] w-[241px] rounded-xl object-contain"
            />)}
          </div>
        </div>
      </div>
     
     

    </div>
  );
};
export default ProfileDetails;
