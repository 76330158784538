import React from 'react';
const Modal = ({
    isVisible,
    onClose,
    children,
    buttonEnabled,
    setButton,
    onSave,
    closeText,
    saveText,
    clearAll = false,
    onClearAll = () => {},
    showCloseButtonBorder,
    allowCloseOnOuterClick = true
}) => {
    if (!isVisible) return null;
    const handleClose = (e) => {
        if (allowCloseOnOuterClick && e.target.id === 'wrapper') {
            onClose();
        }

    };
    const handleCancelClick = () => {
        // if (!allowCloseOnOuterClick) {
        //     toast.info('Please accept the condition to proceed');
        // } else {
        //     onClose();
        // }
        onClose();
    };
    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        
        <div
            className="fixed z-10 inset-0 flex justify-center items-center bg-[#2826265E] bg-opacity-20 "
            id="wrapper"
            onClick={handleClose}
        >
            <div className="max-w-[560px] sm:max-w-[744px] mx-auto flex flex-col">
                <div className="bg-white p-[24px] rounded-[28px] border max-h-[80vh] overflow-y-auto">
                    {children}
                    <div className="mb-2 mt-10 flex flex-col sm:flex-row gap-6">
                        {
                            clearAll &&
                            <button
                                className={`w-auto h-[40px] text-[14px] flex p-2 sm:px-3 rounded-lg items-center text-center justify-center text-[#C00010] ${
                                    showCloseButtonBorder
                                        ? 'border hover:border-[#C00010] hover:border-2 rounded-lg border-[#C00010]'
                                        : 'hover:border hover:bg-[#C00010] hover:text-white'
                                }`}
                                onClick={() => onClearAll()}
                            >
                                Clear All
                            </button>
                        }

                        <div className="ml-auto flex gap-3 ">
                            <button
                                className={`w-auto h-[40px] text-[14px] flex p-2 sm:px-3 rounded-lg items-center text-center justify-center text-[#C00010] ${
                                    showCloseButtonBorder
                                        ? 'border hover:border-[#C00010] hover:border-2 rounded-lg border-[#C00010]'
                                        : 'hover:border hover:border-[#C00010]'
                                }`}
                                // onClick={() => onClose()}
                                onClick={handleCancelClick}
                            >
                                {closeText}
                            </button>
                            <button
                                className={`w-auto h-[40px] p-2 sm:px-4 text-[14px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold  hover:cursor-not-allowed transition-all duration-500  ${
                                    buttonEnabled
                                        ? 'bg-Tmred text-white hover:cursor-pointer '
                                        : 'bg-[#1D1B201F] '
                                } `}
                                onClick={onSave}
                            >
                                {saveText}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default Modal;