import { useState, useEffect, useRef } from 'react';
import { useModal } from '../../../../contexts/ModalContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import info from '../../../../Images/info-circle.svg';
import SwitchON from '../../../../Images/Switch-on.svg';
import SwitchOFF from '../../../../Images/Switch-off.svg';
import scanIcon from '../../../../Images/scan.png';
import hospitalService from '../../../../services/hospitalService';
import patientService from '../../../../services/patientService';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import Input from '../../../../reusable/Forms/Input';
import logger from '../../../../utils/logger';
import { usePatientDataSearchStore } from '../../../../store/hospital/auth';
import HealthRequestModal from './healthRequestModal';
import BlockButton from '../../../../reusable/BlockButton';
import { QrReader } from 'react-qr-reader';

type SearchProps = {
    tmrNumber: string;
};

const EmergencyModal = () => {
    const mutation = useMutation((data: SearchProps) =>
        hospitalService.checkPatientTmrNum(data)
    );
    const [isLoading, setIsLoading] = useState(false);
    const [tmrId, setTmrID] = useState('');
    const { setSearchedPatient } = usePatientDataSearchStore();
    const [showModal, setShowModal] = useState(false);
    const [tmrSwitch, setTmrSwitch] = useState(false);
    const [eNumDescription, setENumDescription] = useState('');
    const [eNumCondition, setENumCondition] = useState<[]>();
    const [eNum, setENum] = useState('');
    const [checkEmNum, setCheckEmNum] = useState(true);
    const [startScan, setStartScan] = useState(false);
    const [qrData, setQrData] = useState('');
    const [userCam, setUserCam] = useState('');

    //to set user's camera
    const constraints = {
        facingMode: userCam,
    };

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let response: any = mutation.data;
            const data = response.data;
            logger(data.message);
            const patientSearched = data?.payload?.data?.patients;
            logger(patientSearched);
            console.log('patient searched', patientSearched);

            setSearchedPatient(patientSearched);
            if (data?.payload?.data?.patients !== null) {
                setShowModal(true);
            } else {
                toast.error('Failed to Retrieved Patient');
            }
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            logger(err.response.data.error.message);
            toast.error(err.response.data.error.message);
        } else {
            logger('');
        }
    }, [mutation.isError, mutation.error]);

    useEffect(() => {
        if (tmrSwitch) {
            if (eNum.length === 3) {
                setCheckEmNum(true);
            } else {
                setCheckEmNum(false);
            }
        }
    }, [eNum]);

    const handleChange = (e) => {
        setENum(e.target.value);
    };

    const { closeModal } = useModal();

    const modalRef = useRef<HTMLDivElement | null>(null);

    const handleCloseModal = () => {
        closeModal();
    };

    const toggleSwitch = (e: any) => {
        e.preventDefault();
        setTmrSwitch((prevState) => !prevState);
        if (tmrSwitch === !true) {
            setCheckEmNum(false);
            patientService
                .getEmergencyNumCondition()
                .then((res) => {
                    console.log(res);
                    setENumDescription(res.description);
                    setENumCondition(res.condition);
                    console.log(res.condition);
                })
                .catch((err) => {
                    toast.error(err);
                });
        } else {
            setCheckEmNum(true);
        }
    };

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
    } = useForm<SearchProps>();

    const submitSearch: SubmitHandler<SearchProps> = (data) => {
        const { tmrNumber } = data;
        setTmrID(tmrNumber);
        console.log('submit data', data);

        mutation.mutate(data);
        setIsLoading(true);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <div
                ref={modalRef}
                className="bg-white w-[490px] md:p-6 p-2 rounded-[28px] mx-2 "
            >
                <ToastContainer />
                <h2 className="font-semibold text-2xl mb-5 text-Tmred">
                    Global TMR Number Search 
                </h2>
                <p className="text-sm">
                    Kindly fill the patient Name or TMR number{' '}
                </p>

                <form onSubmit={handleSubmit(submitSearch)}>
                    <div className="flex items-center w-[stretch]  rounded-lg my-5">
                        <Controller
                            name="tmrNumber"
                            rules={{ required: 'Tmr number is required' }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    label="Patient Name or TMR Number"
                                    {...field}
                                    value={qrData ? qrData : field.value}
                                />
                            )}
                        />
                        <div
                            className="flex h-14 rounded-md items-center p-2 border-[#0000007c] border"
                            onClick={() => {
                                setStartScan(!startScan);
                            }}
                        >
                            <img src={scanIcon} alt="" />
                        </div>
                    </div>

                    {startScan && (
                        <>
                            <select
                                onChange={(e) => setUserCam(e.target.value)}
                            >
                                <option value={'environment'}>
                                    Back Camera
                                </option>
                                <option value={'user'}>Front Camera</option>
                            </select>

                            <QrReader
                                constraints={constraints}
                                onResult={(result: any, error) => {
                                    if (!!result) {
                                        console.log('qr-result', result.text);

                                        setQrData(result.text);
                                        mutation.mutate({
                                            tmrNumber: result.text,
                                        });
                                        console.log(result.text);

                                        setStartScan(!startScan);
                                    }

                                    if (!!error) {
                                        console.info(error);
                                    }
                                }}
                                videoContainerStyle={{ padding: '0' }}
                            />
                        </>
                    )}

                    <div className="flex gap-6 my-4">
                        <div className="flex gap-3 items-center">
                            <p className="text-[12px]">Emergency Number</p>
                            <div onClick={toggleSwitch}>
                                {tmrSwitch ? (
                                    <img
                                        src={SwitchON}
                                        alt=""
                                        className=" w-[40px] h-[25px] transition ease-in"
                                    />
                                ) : (
                                    <img
                                        src={SwitchOFF}
                                        alt=""
                                        className="w-[40px] h-[25px] transition ease-in"
                                    />
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col justify-center ">
                            <input
                                type="text"
                                placeholder="Emergency Number"
                                className=" py-2 rounded-lg border pl-4 outline-none mt-4 placeholder:text-sm font-normal w-full"
                                name="emergencyNumber"
                                disabled={!tmrSwitch}
                                value={eNum}
                                onChange={handleChange}
                            />
                            <p
                                className={`ml-5 my-1 text-[12px] ${
                                    tmrSwitch ? 'text-black' : 'text-[#aaa8a8]'
                                }`}
                            >
                                {eNumDescription}
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center items-center gap-4 px-8 py-2 rounded-lg bg-lightTmrRed text-[#BA1A1A] text-[12px] my-6">
                        <div>
                            <img
                                src={info}
                                alt="info-icon"
                                className="h-[30px] w-[35px]"
                            />
                        </div>
                        <p>
                            Automatic access enter TMR number + Emergency number
                        </p>
                    </div>

                    <div className="flex justify-end space-x-6">
                        <button
                            type="button"
                            onClick={handleCloseModal}
                            className="px-[12px] py-[10px]"
                        >
                            Close
                        </button>

                        <BlockButton
                            text="Search Patient"
                            disabled={!isDirty || !isValid || !checkEmNum}
                            type="submit"
                            isLoading={isLoading}
                            className="!w-auto p-3"
                        />
                    </div>
                </form>
            </div>
            {showModal && (
                <HealthRequestModal
                    eNumCondition={eNumCondition}
                    result={eNum}
                    tmrId={tmrId}
                />
            )}
        </div>
    );
};

export default EmergencyModal;
