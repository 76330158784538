import React from 'react';
import Icon from '../../../../Images/wallet-icon.svg';
import openIcon from '../../../../Images/eye-icon.svg';
import closeIcon from '../../../../Images/eye-slash.png';
import CardIcon from '../../../../Images/card-icon.svg';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import hospitalService from '../../../../services/hospitalService';
import Modal from '../../../../reusable/FundWallet';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Input from '../../../../reusable/Forms/Input';
import { useEffect, useState } from 'react';
import { standardAmountFormat } from '../../../../utils/helpers';
import { adminService } from '../../../../services/adminService';
import { useHospitalAuthStore } from '../../../../store/hospital/auth';
import RecentTransactions from '../../../PharmacyComponent/Billing/RecentTransaction';
import { pharmacyRoutes } from '../../../../utils/pharmacyRouteNames';
import { pharmacyService } from '../../../../services/pharmacyService';

type InputProps = {
    amount: string;
};
const MWallet = () => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const walletType = 'personal';
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    const navigate = useNavigate();
    // useEffect(() => {
    //     if (hospitalStore.userType === 'superadmin') {
    //         setWalletType('hospital');
    //     } else {
    //         setWalletType('personal');
    //     }
    // }, []);

   

    // const { data,isLoading } = useQuery('balance', hospitalService.getWalletBalance);

    const { data, isLoading } = useQuery(['balance', walletType], () =>
        pharmacyService.getWalletBalance('personal')
    );

    const walletBalance = data?.balance;
    console.log(walletBalance);
    const { data: billsdata } = useQuery('bills', hospitalService.getBills);
    console.log('here is the bills:', billsdata);

    const { data: outstandingBalancedata, isLoading: outstandLoading } =
        useQuery('outstanding', hospitalService.getOutstandingBalance);
    console.log('here is the outstanding Balance:', outstandingBalancedata);
    const billCount = outstandingBalancedata;
    console.log(billCount);

    const { data: escrowBalancedata, isLoading: escrowLoading } = useQuery(
        'escrow',
        adminService.getEscrowBalance
    );
    console.log('here is the escrow Balance:', escrowBalancedata);
    const [viewBalance, setViewBalance] = useState(true);
    // const history = useNavigate();

    // const gotoWithdraw = (e: any) => {
    //     e.preventDefault();

    //     history(pharmacyRoutes.withdrawMoney);
    // };

    const toggleBalance = (e: any) => {
        e.preventDefault();
        setViewBalance((prevState) => !prevState);
    };
    const closeModal = () => {
        setShowModal(false);
    };

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
    } = useForm<InputProps>();

    const mutation = useMutation((data: InputProps) =>
        pharmacyService.fundWallet(data, walletType)
    );

    useEffect(() => {
        console.log(walletBalance);
    }, [walletBalance]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setLoading(false);
            console.log('success', mutation.data);
            const response = mutation.data;
            const message = response.data.message;
            console.log('Success:', message);
            toast.success(message);
            const link = response.data.payload.data.paymentLink;
            const openWindowWithDelay = () => {
                window.open(link, '_blank');
            };

            // Set a 3-second delay
            setTimeout(openWindowWithDelay, 2000);
            closeModal();
            reset({});
        }
    }, [mutation.isSuccess, mutation.data, reset]);

    useEffect(() => {
        if (mutation.isError) {
            setLoading(false);
            let err: any = mutation.error;
            console.log('is error', err);
           
            toast.error(err.response?.data?.error?.message);
            reset({});
        } 
    }, [mutation.isError, mutation.error]);

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        console.log('submit data:', data);
        setLoading(true);
        mutation.mutate(data);
    };
    if (outstandLoading) {
        return <p>Fetching balance</p>;
    }
    if (escrowLoading) {
        return <p>Fetching Escrow balance</p>;
    }
    return (
        <div className="w-full flex flex-col md:px-8 py-4 px-2">
            <div className="flex justify-between items-center py-4">
                <p className="font-bold sm:text-[28px] text-[20px]">
                    My Wallet
                </p>
            </div>
            <div className="flex flex-col lg:flex-row items-center gap-10 w-fit flex-wrap mb-8">
                <div className="w-full sm:w-4/5 lg:w-[360px] h-[225px]">
                    <div className="w-full h-full">
                        <div
                            style={{
                                background:
                                    'linear-gradient(289deg, #410002 5.11%, #C00010 97.64%)',
                            }}
                            className=" w-full px-4 pt-4 pb-7 rounded-[20px] relative"
                        >
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl text-white font-bold">
                                    Wallet
                                </h2>
                                <img
                                    src={Icon}
                                    alt=""
                                    className="rounded-full bg-[#ffffff0e]"
                                />
                            </div>
                            <div className="flex justify-between items-center mt-4">
                                <div className="space-y-2 text-white">
                                    <p className="text-xs font-medium opacity-70">
                                        Available Balance
                                    </p>
                                    <p className="font-semibold text-[28px]">
                                        {viewBalance ? (
                                            <span>
                                                {/* <span>&#8358;</span>  */}
                                                {isLoading
                                                    ? '0'
                                                    : standardAmountFormat(
                                                          walletBalance,
                                                          currency
                                                      )}
                                            </span>
                                        ) : (
                                            <span>
                                                <span>&#8358;</span>*****
                                            </span>
                                        )}
                                    </p>
                                </div>

                                <div
                                    onClick={toggleBalance}
                                    className="cursor-pointer"
                                >
                                    {viewBalance ? (
                                        <img
                                            className="h-10 w-10"
                                            src={openIcon}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className=""
                                            src={closeIcon}
                                            alt=""
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-between items-center mt-6 w-full">
                                <button
                                    onClick={() =>
                                        navigate(pharmacyRoutes.withdrawMoney)
                                    }
                                    className="px-2 cursor-pointer  py-1 flex justify-center items-center gap-2 font-medium text-xs text-white border-[1px] rounded-[18px] mt-2 sm:mt-0 sm:ml-2 w-[316px]"
                                >
                                    <img src={CardIcon} alt="" />
                                    withdraw
                                </button>
                            </div>
                            {/* <div className="absolute top-0 bottom-0 z-10">
                    <img src={BackG} alt="" />
                </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <RecentTransactions type="personal" />
            <Modal onClose={closeModal} isVisible={showModal}>
                <div className="bg-white">
                    <div className="bg-white">
                        <h1 className="text-[24px] font-semibold leading-[32px] mb-[16px]">
                            Add Money
                        </h1>
                        <p className="text-[14px] mb-[40px] font-medium text-[#534341]">
                            Enter the amount you want to add
                        </p>
                        <form onSubmit={handleSubmit(onSubmitForm)}>
                            <div>
                                <Controller
                                    name="amount"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'amount is required' }}
                                    render={({ field }) => (
                                        <Input
                                            type="number"
                                            label="Enter Amount"
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <div className="mb-2 mt-[24px] flex flex-col sm:flex-row gap-6 ml-auto justify-end">
                                <button onClick={closeModal}> Cancel</button>
                                <button
                                    type="submit"
                                    disabled={!isValid || !isDirty}
                                    className={`w-auto h-[40px] p-2 sm:px-4 text-[14px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold  hover:cursor-not-allowed transition-all duration-500  ${
                                        !isValid || !isDirty || loading
                                            ? ' bg-[#1D1B201F] hover:cursor-not-allowed '
                                            : 'bg-Tmred text-white hover:cursor-pointer '
                                    } `}
                                >
                                    {loading ? 'Proceeding' : 'Proceed'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default MWallet;
