import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import count2 from './../../../Images/Frame2.svg';
import arrowLeft from './../../../Images/arrow-left.svg';
import medicalTitles from '../../../services/constants/medicalTitles';
import upload from '../../../Images/upload.png';
import Layout from '../Layout';
import {  SubmitHandler, useForm } from 'react-hook-form';
import { useHospitalAccountStore } from '../../../store/hospital/auth';
import HospitalAuthHeader from '../../../reusable/HospitalAuthHeader';
import BlockButton from '../../../reusable/BlockButton';
import hospitalService from '../../../services/hospitalService';
import { useMutation } from 'react-query';
import ErrorState from '../../../reusable/ErrorState';
import { hospitalRoutes } from '../../../utils/routeNames';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';
import ButtomLogo from '../../../reusable/ButtomLogo';

interface ShowFirstComponent {
    showFirstComponent: () => void;
    formData: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        password: string;
        Gender: string;
        Salutation: string;
    };
    email: string
}
type InputProps = {
    title: string;
    licenseNumber: string;
};

const ProfessionalInformation: React.FC<ShowFirstComponent> = ({
    showFirstComponent,
    formData,
    email,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [displayImg, setDisplayImg] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const hospitalAccountStore = useHospitalAccountStore((state) => state);
    const navigate = useNavigate();

    // specialty modal state and method
    const [selectedSpecialtyIds, setSelectedSpecialtyIds] = React.useState<
        string[]
    >([]);
   

    // const onSpecialtyCheckboxChange = (option: IOption) => {
    //     setSelectedSpecialtyIds((prev) =>
    //         !selectedSpecialtyIds.includes(option._id)
    //             ? [...prev, option._id]
    //             : prev.filter((_id) => _id !== option._id)
    //     );
    //     setSelectedOSpecialtyNames((prev) =>
    //         !selectedOSpecialtyNames.includes(option.name)
    //             ? [...prev, option.name]
    //             : prev.filter((name) => name !== option.name)
    //     );
    // };

    // const toggleModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    //     e.preventDefault();
    //     setShowModal(!showModal);
    // };

    const handleBack = () => {
        showFirstComponent(); // Call the function to show the first component
    };

    const {
        register,
        formState: { isDirty, isValid },
        handleSubmit,
       
    } = useForm<InputProps>();

    const mutation = useMutation((data: any) =>
        hospitalService.createHospitalUser(data)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let payload: any = mutation.data;
            // hospitalAccountStore.resetHospital();
            hospitalAccountStore.updateToken(payload.data.payload);
            // hospitalAuthStore.updateUser(payload.data.payload)
            console.log(hospitalRoutes.otp)
            navigate(hospitalRoutes.otp, { state: { formData, email: formData.email } });
        }
    }, [mutation.isSuccess, mutation.data]);

    const onImageChange = (e: any) => {
        e.preventDefault();
        const image_as_base64 = URL.createObjectURL(e.target.files[0]);
        const image_as_files = e.target.files[0];
        setDisplayImg(image_as_base64);
        setImageFile(image_as_files);
    };

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        setErrorMessage('');
        // if (imageFile === '') {
        //     setErrorMessage('Please upload a profile image');
        //     return;
        // }
        setIsLoading(true);
        const hospitalFormData = new FormData();
        hospitalFormData.append('firstName', formData.firstName);
        hospitalFormData.append('lastName', formData.lastName);
        hospitalFormData.append('gender', formData.Gender);
        hospitalFormData.append('salutation', formData.Salutation);
        hospitalFormData.append('email', email);
        hospitalFormData.append('phoneNumber', formData.phoneNumber);
        
        hospitalFormData.append('specialties', selectedSpecialtyIds.toString());

        // hospitalFormData.append('image', imageFile);
        if (imageFile) {
            hospitalFormData.append('image', imageFile);
        }
        hospitalFormData.append('password', formData.password);
        hospitalFormData.append('title', data.title);
        // mutation.mutate({
        //     hospitalFormData,
        //     token: hospitalAccountStore.token,
        // });
        navigate(pharmacyRoutes.otp, { state: { formData, email: formData.email } })
    console.log({hospitalFormData})
    };

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation?.error;
            // console.log(err);
            setErrorMessage(err.response.data.error.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError]);

    const onInvalid = (errors) => console.error(errors);

    return (
        <Layout>
            <div className=" flex items-center justify-center flex-col w-full py-8 overflow-x-hidden ">
                <div className="mx-auto flex flex-col items-center justify-center gap-4">
                    <HospitalAuthHeader
                        logo={hospitalAccountStore.hospital.logo}
                        text={hospitalAccountStore.hospital.tagline}
                        title={hospitalAccountStore.hospital.name}
                    />
                    <div className="flex items-center flex-col justify-center  border rounded-xl bg-[#FFf] py-[40px] px-[40px] sm:px-[80px] mt-7 max-w-[592px]">
                        <div className="flex items-start gap-1">
                            <div className="flex">
                                <button onClick={handleBack} className="flex">
                                    <img src={arrowLeft} alt="arrow-left" />
                                </button>
                            </div>
                            <div>
                                <h3 className="font-semibold text-[#201A19] text-center sm:text-[32px] text-[24px]">
                                Super Admin Professional Information
                                </h3>
                                <p className="font-normal text-[#534341] text-[14px] max-w-[432px] pt-4 text-center">
                                    Help us understand your professional
                                    background better.{' '}
                                </p>
                            </div>
                            <div className="text-end">
                                <img src={count2} alt="count2" />
                            </div>
                        </div>
                        {errorMessage && <ErrorState message={errorMessage} />}
                        <form
                            className="w-full flex flex-col gap-4 sm:gap-8"
                            onSubmit={handleSubmit(onSubmitForm, onInvalid)}
                            noValidate
                        >
                            <div className="select-wrapper">
                                <select
                                    className="w-full border border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider mt-2"
                                    {...register('title', {
                                        required: 'Title is required',
                                    })}
                                >
                                    <option value="" className="mb-2">
                                        Select a Title
                                    </option>
                                    {medicalTitles.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* <button
                                onClick={(e) => toggleModal(e)}
                                className="w-full flex items-center justify-between border border-[#4d4544] pl-4 py-3 rounded-[8px] text-[16px] tracking-wider  text-left"
                            >
                                <span>{'Specialization'}</span>
                                <img
                                    src={drop}
                                    alt=""
                                    className="text-[#000]"
                                />
                            </button>

                            {showModal && (
                                <SpecialtyModal
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                    selectedSpecialtyIds={selectedSpecialtyIds}
                                    onSpecialtyCheckboxChange={
                                        onSpecialtyCheckboxChange
                                    }
                                    onClose={(_) => {
                                        setShowModal(false); // Close the modal
                                    }}
                                />
                            )}
                            {selectedOSpecialtyNames.length > 0 && (
                                <div className="mt-2">
                                    <ul className="flex flex-wrap gap-2">
                                        {selectedOSpecialtyNames.map(
                                            (option, index) => {
                                                const isLongOption =
                                                    option.length > 20;
                                                const maxContentWidth =
                                                    isLongOption
                                                        ? '300px'
                                                        : '600px';

                                                return (
                                                    <li
                                                        key={index}
                                                        className={`flex items-center border rounded-lg py-[6px] pr-[8px] pl-[12px] border-[#4D4544] ${
                                                            isLongOption
                                                                ? 'flex-row'
                                                                : 'flex-row'
                                                        }`}
                                                        style={{
                                                            maxWidth:
                                                                maxContentWidth,
                                                        }}
                                                    >
                                                        <span className="font-normal text-[16px] text-[#201A19]">
                                                            {option}
                                                        </span>
                                                        
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                            )} */}

                            {/* <Controller
                                name="licenseNumber"
                                rules={{
                                    required: 'License Number is required',
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Input label="License Number" {...field} />
                                )}
                            /> */}
                            <div className="flex items-center sm:flex-row flex-col gap-2">
                                <div className="flex flex-col  max-w-[152px]">
                                    <p className="font-normal text-[#534341] text-[16px] text-left">
                                        Profile Image
                                    </p>
                                    <span className="font-normal py-6 text-[#534341] text-[14px] text-left">
                                        PNG or JPG <br />
                                        (max. 4MB)
                                    </span>
                                </div>
                                <div className="relative mb-5 w-max mx-auto">
                                    <div className="w-[150px] h-[150px] rounded-full bg-[#FFDAD5] overflow-hidden">
                                        {displayImg ? (
                                            <img
                                                src={displayImg}
                                                alt=""
                                                className="object-cover w-full h-full rounded-full max-w-[100%]"
                                            />
                                        ) : (
                                            <div className="w-full h-full mx-auto flex flex-col max-w-[134px] justify-center items-center px-3">
                                                <img src={upload} alt="" />
                                                <p className="text-center mt-2 text-[14px] text-[#534341]">
                                                    Click to upload or drag and
                                                    drop
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <input
                                        type="file"
                                        accept=".png, .jpeg, .jpg"
                                        name="uploads"
                                        style={{
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                        className="bg-[#FFDAD5] w-[100%] h-[100%] top-[50%] left-[50%] z-[4] opacity-0 cursor-pointer absolute"
                                        onChange={onImageChange}
                                    />
                                </div>
                            </div>
                            <BlockButton
                                text="Create Account"
                                disabled={!isDirty || !isValid}
                                type="submit"
                                isLoading={isLoading}
                            />
                        </form>
                    </div>
                </div>
                <div className="ml-auto mt-10 w-full flex justify-end">
                <ButtomLogo/>
                </div>
            </div>
        </Layout>
    );
};

export default ProfessionalInformation;
