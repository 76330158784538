import React from 'react';
import styled from 'styled-components';
import prevPageIcon from './icons/prevPageIcon.svg';
import nextPageIcon from './icons/nextPageIcon.svg';
import Flex from '../common/Flex';
import Button, { ButtonClass } from '../common/Button';
// import { Flex, Button, ButtonClass } from 'src/components/common';

const PaginationContainer = styled.div`
    padding: 20px;
    width: -webkit-fill-available;
    margin: auto;
    @media (max-width: 600px) {
        width: 100%;
    }

    .pagination {
        display: flex;
        gap: 5px;
        align-items: center;

        ul {
            li {
                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 37px;
                    height: 37px;
                    padding: 2px 4px;
                    background-color: transparent;
                    border-radius: 2px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: #06042c;

                    &[disabled] {
                        opacity: 0.4;
                    }

                    &:hover {
                        color: #c00010;
                    }
                }
            }
        }

        .page-numbers {
            list-style-type: none;
            display: flex;
            gap: 5px;
            padding: 0;
            margin: 0;
            flex-wrap: wrap;
            border-radius: 50%;

            .page-numbers__link--active {
                background-color: #f9f5ff;
                color: #c00010;
                border-radius: 50%;
            }
        }
    }
`;

interface Props {
    page?: any;
    setPage?: any;
    limit?: any;
    setLimit?: any;
    total?: any;
    pageNumbers?: any;
    disablePrevPage?: any;
    disableNextPage?: any;
    gotoPrevPage?: any;
    gotoNextPage?: any;
}

export const Pagination: React.FC<Props> = ({
    page,
    setPage,
    limit,
    setLimit,
    total,
    pageNumbers,
    disablePrevPage,
    disableNextPage,
    gotoPrevPage,
    gotoNextPage,
    ...rest
}) => {
    // const limitOptions = [10, 15, 20, 25, 30, 40, 50, 100]

    return (
        <PaginationContainer {...rest}>
            {/* <div className="limit-changer">
				<span>Show</span>
				<select
					value={limit}
					onChange={(e) => {
						const { value } = e.target
						setLimit(Number(value))
					}}
				>
					{limitOptions.map((option, index) => (
						<option key={index} value={option}>
							{option}
						</option>
					))}
				</select>
				<span>
					<span>of</span>
					<span>{total}</span>
				</span>
			</div> */}

            {pageNumbers.length > 0 && (
                <div className="pagination">
                    <Flex
                        justify="space-between"
                        align="center"
                        style={{ width: '100%' }}
                    >
                        <p className="text-[16px]">
                            Page {page} of {pageNumbers.length}
                        </p>
                        {/* <ul className="page-numbers">
                            {pageNumbers.map((number: any) => (
                                <li key={number}>
                                    <button
                                        type="button"
                                        onClick={() => setPage(number)}
                                        className={clsx({
                                            'page-numbers__link--active':
                                                page === number,
                                        })}
                                    >
                                        {number}
                                    </button>
                                </li>
                            ))}
                        </ul> */}
                        <div className="flex gap-2">
                            <Button
                                style={{
                                    border: '1px solid #D0D5DD',
                                    boxShadow:
                                        '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                    borderRadius: '8px',
                                    width: 'auto',
                                }}
                                classes={[
                                    ButtonClass.OUTLINED,
                                    ButtonClass.WITH_ICON,
                                ]}
                                disabled={disablePrevPage}
                                onClick={gotoPrevPage}
                            >
                                {' '}
                                <img src={prevPageIcon} alt="<" />
                                <span>Previous</span>
                            </Button>
                            <Button
                                classes={[
                                    ButtonClass.OUTLINED,
                                    ButtonClass.WITH_ICON,
                                ]}
                                style={{
                                    border: '1px solid #D0D5DD',
                                    boxShadow:
                                        '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                    borderRadius: '8px',
                                    width: 'auto',
                                }}
                                disabled={disableNextPage}
                                onClick={gotoNextPage}
                            >
                                {' '}
                                <img src={nextPageIcon} alt=">" />
                                <span>Next</span>
                            </Button>
                        </div>
                    </Flex>
                </div>
            )}
        </PaginationContainer>
    );
};
