import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import RadioButtonsGroup from '../../../reusable/Forms/RadioButton';
import DefaultRadioGroup from '../../../reusable/Forms/DefaultRadioGroup';
import { useMutation } from 'react-query';
import patientService from '../../../services/patientService';
import { toast } from 'react-toastify';
import { hospitalRoutes } from '../../../utils/routeNames';

type propType = {
    closeModal: any;
    selectedPlanId: any;
    finalData: any;
};
const PaymentModal: React.FC<propType> = ({
    closeModal,
    selectedPlanId,
    finalData,
}) => {
    const [buttonEnabled, setButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        paymentOption: '',
    });
    const mutation = useMutation((data: any) =>
        patientService.createPatient(data)
    );
    console.log({ finalData });
    console.log();

    const handleChange = (value: string, selectedPlanId: string | null) => {
        setFormData((prevData) => ({
            ...prevData,
            paymentOption: value,
        }));
        setButton(true);
        // setButton(
        //     value !== '' &&
        //         (value === 'pay using Wallet' ? selectedPlanId !== null : true)
        // );
        // set btn active only when using wallet
        // setButton(value === 'pay using Wallet' && selectedPlanId !== null);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setButton(false);
        if (
            formData.paymentOption === 'pay using Wallet' &&
            selectedPlanId !== null
        ) {
            setIsLoading(true);
            const {
                firstName,
                lastName,
                email,
                dob,
                // phoneNumber,
                // gender,
                address,
                city,
                state,
                country,
                // bloodGroup,
                // genotype,
                allergies,
                emergencyNumber,
            } = finalData;
            const requestData = {
                firstName,
                lastName,
                email,
                dob,
                phoneNumber: finalData.phone_number,
                gender: finalData.Gender,
                address,
                city,
                state,
                country,
                bloodGroup: finalData.BloodGroup,
                genotype: finalData.Genotype,
                allergies,
                emergencyNumber,
                planId: selectedPlanId,
            };
            console.log('submitted data', requestData, selectedPlanId);
            mutation.mutate(requestData, {
                onSuccess: (data) => {
                    console.log('Patient created successfully:', data);
                    setIsLoading(false);

                    toast.success('Patient created successfully');
                    setTimeout(() => {
                        toast.dismiss();
                        navigate(hospitalRoutes.patient);
                    }, 3000);
                },
                onError: (error: any) => {
                    console.error(
                        'Error creating patient:',
                        error.response.data.error.message
                    );
                    toast.error(error.response.data.error.message);
                    setTimeout(() => {
                        toast.dismiss();
                    }, 3000);
                    setIsLoading(false);
                },
            });
        } else if (formData.paymentOption === 'pay using Voucher') {
            console.log('pay using voucher');
            navigate(hospitalRoutes.paymentVoucher);
        }
        return;
        // else {

        //           console.error('Invalid payment option or plan ID.');
        // }
    };
    const handleClose = (e: any) => {
        e.preventDefault();
        closeModal();
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <div className="bg-white p-6 rounded-[28px] w-fit m-2  sm:m-0 relative overflow-x-auto">
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center flex-col gap-2">
                        <h6 className="text-[22px] font-medium text-lightBrown">
                            Payment method
                        </h6>
                        <p className="text-[14px] font-normal text-[#534341]">
                            Please choose a payment method for the subscription
                            plan
                        </p>
                    </div>
                </div>
                <DefaultRadioGroup
                    label="paymentOption"
                    options={[
                        {
                            value: 'pay using Voucher',
                            label: 'Pay using Voucher',
                        },
                        {
                            value: 'pay using Wallet',
                            label: 'Pay using Wallet',
                        },
                    ]}
                    value={formData.paymentOption}
                    onChange={(value) => handleChange(value, selectedPlanId)}
                />
                <div className="flex items-center gap-4 justify-center">
                    <button
                        onClick={handleClose}
                        className="text-Tmred flex-1  h-[40px] font-semibold text-[16px] justify-center mt-4"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        className={`w-full h-[40px] flex-1 justify-center rounded-lg items-center text-[#9d9b9b] font-semibold mt-4  hover:cursor-not-allowed transition-all duration-500  ${
                            buttonEnabled
                                ? 'bg-Tmred text-white hover:cursor-pointer '
                                : 'bg-[#1D1B201F] '
                        } `}
                    >
                        {isLoading ? 'Creating...' : 'Proceed'}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default PaymentModal;
