import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { BsTrash } from 'react-icons/bs';
import back from '../../Images/back.png';
import Input from '../../reusable/Forms/Input';
import { useMutation } from 'react-query';
import hospitalService from '../../services/hospitalService';
import { ToastContainer, toast } from 'react-toastify';
import BillingModal from './BillingModal';
import { usePatientSearchStore } from '../../store/hospital/auth';
import { hospitalRoutes } from '../../utils/routeNames';
import TextField from '@mui/material/TextField';
interface FormData {
    Phone_Number: string;
    Patient_Name: string;
    TMR_Number: string;
    service: string;
    Qty: string;
    Amount: string;
}

interface ServiceData {
    service: string;
    Qty: string;
    Amount: string;
}

const CreateBills = () => {
    const navigate = useNavigate();
    const patient = usePatientSearchStore((state) => state.searchedPatient);
    const [tmrNumber, setTmrNumber] = useState('');
    const [buttonEnabled, setButton] = useState(false);
    const [services, setServices] = useState<string[]>([]);
    const [newServices, setNewServices] = useState<ServiceData[]>([]);
    // const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs(''));
    const [billingModal, setBillingModal] = useState(false);
    const [patientInfoUpdated, setPatientInfoUpdated] = useState(false);
    const [patientAdded, setPatientAdded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        Phone_Number: '',
        Patient_Name: '',
        TMR_Number: '',
        service: '',
        Qty: '',
        Amount: '',
    });

    const mutation = useMutation((payload: any) =>
        hospitalService.createBill(payload)
    );
    const handleOpenModal = () => {
        setBillingModal(true);
    };
    const handleCloseModal = () => {
        setBillingModal(false);
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    // const handleModalInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     handleOpenModal();
    //     const { value } = e.target;
    //     setTmrNumber(value);
    //     updateTmrNumber(value);
    // };

    const updateTmrNumber = (newTmrNumber: string) => {
        setTmrNumber(newTmrNumber);
    };
    // console.log('trmnumber', tmrNumber);
    // console.log('newtrmnumber', tmrNumber);
    useEffect(() => {
        if (patientAdded && patient) {
            setFormData({
                ...formData,
                Patient_Name: `${patient.firstName} ${patient.lastName}`,
                Phone_Number: patient.phoneNumber,
                TMR_Number: patient.tmrNumber,
            });
            setPatientInfoUpdated(true);
        }
    }, [patient, patientAdded]);
    // useEffect(() => {
    //     if (patient) {
    //         setFormData({
    //             ...formData,
    //             Patient_Name: `${patient.firstName} ${patient.lastName}`,
    //             Phone_Number: patient.phoneNumber,
    //             TMR_Number: tmrNumber,
    //         });
    //           setPatientInfoUpdated(true);
    //     }
    // }, [patient]);
    useEffect(() => {
        if (
            formData.service !== '' &&
            formData.Patient_Name !== '' &&
            formData.TMR_Number !== '' &&
            formData.Qty !== '' &&
            formData.Amount !== ''
        ) {
            setButton(true);
        } else {
            setButton(false);
        }
    }, [
        formData.service,
        formData.Patient_Name,
        formData.TMR_Number,
        formData.Qty,
        formData.Amount,
    ]);
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            const response = mutation.data;
            const message = response.data.message;
            toast.success(message);
            setFormData({
                Phone_Number: '',
                Patient_Name: '',
                TMR_Number: '',
                service: '',
                Qty: '',
                Amount: '',
            });
            setServices([]);
            setNewServices([]);
            setTimeout(() => {
                toast.dismiss();
                navigate(hospitalRoutes.billing);
            }, 2000);
        }
    }, [mutation.isSuccess, mutation.data]);
    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            toast.error(err.response?.data?.error?.message);
        }
    }, [mutation.isError, mutation.error]);
    const handlesubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setButton(false);
        setIsLoading(true);
        // const formattedDate = selectedDate
        //     ? selectedDate.format('YYYY-MM-DD')
        //     : '';

        const initialServicePayload = {
            name: formData.service,
            quantity: parseInt(formData.Qty, 10),
            amount: parseFloat(formData.Amount),
        };

        const newServicesPayload = newServices.map((newService) => ({
            name: newService.service,
            quantity: parseInt(newService.Qty, 10),
            amount: parseFloat(newService.Amount),
        }));

        const servicesPayload = [initialServicePayload, ...newServicesPayload];

        const dataToSend = {
            patientTmrNumber: formData.TMR_Number,
            services: servicesPayload,
        };

        mutation.mutate(dataToSend);
    };

    const handleNewServiceChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const { name, value } = e.target;
        setNewServices((prevServices) => {
            const updatedServices = [...prevServices];
            updatedServices[index] = {
                ...updatedServices[index],
                [name]: value,
            };
            return updatedServices;
        });
    };

    const clearAll = (e: React.FormEvent) => {
        e.preventDefault();
        setTmrNumber('');
        setFormData({
            Phone_Number: '',
            Patient_Name: '',
            TMR_Number: '',
            service: '',
            Qty: '',
            Amount: '',
        });
        setServices([]);
        setNewServices([]);
    };

    const goBack = () => {
        navigate(-1);
    };
    const addNewService = (e: any) => {
        e.preventDefault();
        setServices((prevServices: any) => [...prevServices, formData.service]);
        setNewServices((prevData: any) => [
            ...prevData,
            { service: '', Qty: '', Amount: '' },
        ]);
    };

    const removeService = (e: any, index: number) => {
        e.preventDefault();
        const updatedServices = [...services];
        updatedServices.splice(index, 1);
        setServices(updatedServices);
        const updatedNewServices = [...newServices];
        updatedNewServices.splice(index, 1);
        setNewServices(updatedNewServices);
    };

    return (
        <div className="w-full flex flex-col md:px-8 py-4 px-2 ">
            <ToastContainer />
            <div className="flex gap-2 items-center ">
                <button onClick={goBack}>
                    <img src={back} alt="" />
                </button>
                <h3 className="font-bold sm:text-[28px] text-[20px]">
                    Create Bill
                </h3>
            </div>
            <div className="max-w-[812px] w-full p-8 rounded-2xl bg-[#FFFFFF]">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div>
                        <h3 className="font-semibold text-[#000000] text-[24px] ">
                            Bill Details
                        </h3>
                    </div>
                    {/* <div className="flex ">
                        <DatePickerElement
                            label="Date"
                            value={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                    </div> */}
                </div>

                <form onSubmit={handlesubmit}>
                    <div className="mt-4">
                        {/* <Input
                            name="TMR_Number"
                            label="Patient’s TMR Number"
                            value={tmrNumber}
                            onChange={handleModalInputChange}
                        /> */}
                        <TextField
                            fullWidth
                            id="TMR_Number"
                            name="TMR_Number"
                            label="Patient’s TMR Number"
                            value={formData.TMR_Number}
                            // onChange={handleModalInputChange}
                            onFocus={handleOpenModal}
                            variant="outlined"
                        />
                    </div>
                    <div className="w-full flex items-center flex-col sm:flex-row gap-6  sm:pt-6">
                        <Input
                            name="Patient_Name"
                            label="Patient’s Name"
                            value={formData.Patient_Name}
                            onChange={handleChange}
                            disabled={patientInfoUpdated}
                        />
                        <Input
                            name="Phone_Number"
                            label="Phone Number"
                            value={formData.Phone_Number}
                            onChange={handleChange}
                            disabled={patientInfoUpdated}
                        />
                    </div>

                    <div className="w-full flex service flex-col sm:flex-row sm:gap-6  sm:py-6">
                        <div className="flex-1">
                            <Input
                                name="service"
                                label="Service"
                                value={formData.service}
                                onChange={handleChange}
                            />
                        </div>

                        <div className=" flex-1 flex flex-col sm:flex-row gap-3">
                            <div className="flex w-[40%]">
                                <Input
                                    name="Qty"
                                    label="Qty"
                                    value={formData.Qty}
                                    onChange={handleChange}
                                    type="number"
                                />
                            </div>
                            <div className="flex items-center justify-center gap-3 w-full ">
                                <Input
                                    name="Amount"
                                    label="Amount"
                                    value={formData.Amount}
                                    onChange={handleChange}
                                    type="number"
                                />

                                {/* <button
                                    disabled
                                    className="pt-5 px-3 opacity-5"
                                >
                                    <BsTrash />
                                </button> */}
                            </div>
                        </div>
                    </div>

                    {newServices.map((newService, index) => (
                        <div
                            key={index}
                            className="w-full flex service flex-col sm:flex-row sm:gap-6 my-4 sm:pb-6"
                        >
                            <div className="flex-1">
                                <Input
                                    name="service"
                                    label="Service"
                                    value={newService.service}
                                    onChange={(e) =>
                                        handleNewServiceChange(e, index)
                                    }
                                />
                            </div>

                            <div className="flex-1 flex flex-col sm:flex-row sm:gap-3">
                                <div className="w-[30%]">
                                    <Input
                                        name="Qty"
                                        label="Qty"
                                        value={newService.Qty}
                                        onChange={(e) =>
                                            handleNewServiceChange(e, index)
                                        }
                                        type="number"
                                    />
                                </div>
                                <div className="flex items-center justify-center gap-3 ">
                                    <Input
                                        name="Amount"
                                        label="Amount"
                                        value={newService.Amount}
                                        onChange={(e) =>
                                            handleNewServiceChange(e, index)
                                        }
                                        type="number"
                                    />
                                    <button
                                        onClick={(e) => removeService(e, index)}
                                        className=" px-3"
                                    >
                                        <BsTrash />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="flex justify-center">
                        <button
                            onClick={addNewService}
                            className="flex gap-2 items-center text-[#534341] font-medium  rounded-lg border border-[#534341] p-1 outline-none mt-4"
                        >
                            <FaPlus />{' '}
                            <span className="text-[14px] py-1 px-2">
                                Add new Service
                            </span>
                        </button>
                    </div>
                    <div className="flex flex-col sm:flex-row justify-end border-t-[2px] border-[#D8C2BF] my-4 items-center gap-4">
                        <button
                            disabled={!buttonEnabled}
                            onClick={clearAll}
                            className={`mt-4 py-[10px] px-6 rounded-lg  ${
                                buttonEnabled
                                    ? 'text-[#000] hover:cursor-pointer '
                                    : ' text-[#999797]'
                            } `}
                        >
                            Clear All
                        </button>
                        {/* <button
                            className={`mt-4 py-[10px] px-6 rounded-lg bg-Tmred text-white hover:cursor-pointer                                    
                            } `}
                        >
                            Create Bill
                        </button> */}
                        <button
                            type="submit"
                            disabled={!buttonEnabled}
                            className={`mt-4 py-[10px] px-6 rounded-lg  ${
                                buttonEnabled
                                    ? 'bg-Tmred text-white hover:cursor-pointer '
                                    : 'bg-[#1D1B201F] text-[#999797] cursor-not-allowed'
                            } `}
                        >
                            {isLoading ? 'Creating...' : 'Create Bill'}
                        </button>
                    </div>
                </form>
            </div>
            {billingModal && (
                <BillingModal
                    closeModal={handleCloseModal}
                    updateTmrNumber={updateTmrNumber}
                    onPatientAdd={() => setPatientAdded(true)}
                />
            )}
        </div>
    );
};

export default CreateBills;
