import React, { useEffect } from 'react';
import DashboardLayout from './DashboardLayout';
import { useNavigate } from 'react-router-dom';
import { isTokenValid } from '../../../utils/helpers';
import { Helmet } from 'react-helmet';
import {
    usePharmacyAuthStore,
    useScreenTimeOut,
} from '../../../store/pharmacy/auth';
import AdminDashboard from '../../../components/PharmacyComponent/dashboard/AdminDashboard';
import { pharmacyRoutes } from '../../../utils/pharmacyRouteNames';

const Dashboard = () => {
    const navigate = useNavigate();
    const pharmacyAuthStore = usePharmacyAuthStore((state) => state);
    const timeOutStore = useScreenTimeOut();
    useEffect(() => {
        if (
            !pharmacyAuthStore.token ||
            !isTokenValid(pharmacyAuthStore.token)
        ) {
            timeOutStore.setIsTimeOut(false);
            navigate(pharmacyRoutes.healthID);
        }
    }, []);

    return (
        <DashboardLayout>
            <Helmet>
                <title>TMR | Dashboard</title>
            </Helmet>
            <div>
                <AdminDashboard />
            </div>
        </DashboardLayout>
    );
};

export default Dashboard;
