import icon from '../../Images/infoCircle.svg';
import { useEffect } from 'react';
import Input from '../../reusable/Forms/Input';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import hospitalService from '../../services/hospitalService';
import { ToastContainer, toast } from 'react-toastify';

interface ShareAdminFormModalProps {
    closeModal: () => void;
}
type InviteUserByEmailProps = {
    email: string;
};

const ShareDoctorFormModal: React.FC<ShareAdminFormModalProps> = ({
    closeModal,
}) => {
    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset: formReset,
    } = useForm<InviteUserByEmailProps>();

    const mutation = useMutation((data: any) =>
        hospitalService.inviteUserByEmail(data)
    );
    useEffect(() => {
        if (mutation.isSuccess) {
            console.log('success', mutation.data);
            let response: any = mutation.data;
            const message = response.data.message;
            formReset();
            toast.success(message, {
                autoClose: 1000,
                onClose: () => {
                    closeModal();
                },
            });
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            let err: any = mutation.error;
            console.log(err.response.data.error.message);
        }
    }, [mutation.isError, mutation.error]);

    const submitInviteUserByEmail: SubmitHandler<
        InviteUserByEmailProps
    > = async (data) => {
        const userData = new FormData();
        userData.append('email', data.email);
        mutation.mutate(userData);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            <ToastContainer />
            <div className="bg-white p-2 mx-1 sm:mx-0 sm:p-6 rounded-[28px]">
                <h2 className="text-2xl font-semibold text-lightBrown">
                    Share Doctors Form
                </h2>
                <p className="font-normal text-sm text-dark100 mt-3">
                    Please enter the admin's email to share the form securely.{' '}
                </p>
                <form
                    onSubmit={handleSubmit(submitInviteUserByEmail)}
                    noValidate
                    className="mt-6"
                >
                    <Controller
                        name="email"
                        rules={{
                            required: 'Email address is required',
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: 'Invalid email address',
                            },
                        }}
                        control={control}
                        render={({ field }) => (
                            <Input
                                label="Email Address"
                                type="email"
                                {...field}
                            />
                        )}
                    />

                    <div className="flex space-x-4 bg-lightPink py-2 px-4 items-center my-6">
                        <img src={icon} alt="" />
                        <p className="text-xs font-medium">
                            Form link expires after 24 hours
                        </p>
                    </div>
                    <div className="flex justify-end space-x-6  mt-6">
                        <button
                            onClick={closeModal}
                            className="py-[10px] px-6 border-[1px] rounded-lg text-Tmred text-sm font-medium border-lightBrown100"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={!isDirty || !isValid}
                            className={` py-[10px] px-6  text-lightBrown text-sm font-medium rounded-lg  ${
                                !isDirty || !isValid || mutation.isLoading
                                    ? ' bg-lightGray cursor-not-allowed'
                                    : 'bg-Tmred text-white hover:cursor-pointer'
                            }`}
                        >
                            {mutation.isLoading ? 'sending' : ' Send'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default ShareDoctorFormModal;
