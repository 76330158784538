import React, { useState } from 'react'
import BlockButton from '../../../reusable/BlockButton'
import hospitalService from '../../../services/hospitalService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logger from '../../../utils/logger';

const PrivateMode = ({closeModal, privateModeChange, isActive }) => {
	const prevState = isActive
	const [isLoading, setIsLoading] = useState(false)
	const [privateMode, setPrivateMode] = useState(!prevState)
	const handlePrivateMode = () => {
		setIsLoading(true)
		hospitalService.hospitalPrivateMode({privateMode})
		.then((res) =>{
			logger(res?.data?.message)
            toast.success(res?.data?.message)
            setIsLoading(false)
			// setActiveEnabled(true)
			privateModeChange(!prevState)
			closeModal()
		})
		.catch((err) => {
			logger(err.response);
			toast.error(err?.response?.data?.error?.message);
			setIsLoading(false)
        })
	}
	return (
		<div>
			<ToastContainer />
			<div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
				<div className="bg-white p-6 rounded-[28px] w-full m-2 max-w-[480px] sm:m-0 relative">
					<h1 className="text-[24px] font-semibold mb-3">Private Mode Visibility</h1>
					<p className="text-[12px]">
						Please be informed that your hospital will no longer be visible to patients when you enable private mode. This means that your hospital name will not come up when patients search for hospitals nearby.
					</p>

					<h2 className=" text-[16px] my-5 tracking-wider ">
						Implications of Private Mode:
					</h2>
					<ul className="ml-4">
						<li className="text-[14px] list-disc my-1">
							The hospital's location, contact details, and services will not be displayed on public directories or search engines.
						</li>
						<li className="text-[14px] list-disc my-1">
							The hospital will not appear in patient searches for medical services in the area. 
						</li>
						<li className="text-[14px] list-disc my-1">
							This setting may limit the hospital's visibility and accessibility to potential patients.
						</li>
					</ul>

					<div className="flex gap-5 mt-10 justify-end">
						<button
							className="text-Tmred border-[1px] border-lightBrown100 px-6 py-2 text-base font-normal rounded-lg"
							onClick={closeModal}
						>
							Cancel
						</button>

						<BlockButton 
							text={ !isActive? 'Enable Private Mode': 'Disable Private Mode'}    
							onclick={handlePrivateMode}
                            type="submit"
                            isLoading = {isLoading}
                            className="!w-auto px-6 py-2"
						/>
					</div>
				</div>
			</div>

		</div>
	)
}

export default PrivateMode