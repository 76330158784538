
import DashboardLayout from '../Dashboard/DashboardLayout';
import ActiveState from '../../../components/Patient/ActiveState';

const PatientAppointments = () => {
   
    return (
        <DashboardLayout>
            <div>
                <ActiveState />
            </div>
        </DashboardLayout>
    );
};

export default PatientAppointments;
