// import ImageUpload from '../../../components/UserAdmin/ImageUpload';
import ImageUpload from '../Hospital/ImageUpload'
import { Fragment, useEffect, useState } from 'react';
import profile from '../../../Images/profile.png';
import Input from '../../../reusable/Forms/Input';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import SelectElement from '../../../reusable/Forms/Select';
import { useHospitalAuthStore } from '../../../store/hospital/auth';
import hospitalService from '../../../services/hospitalService';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { hospitalRoutes } from '../../../utils/routeNames';
import logger from '../../../utils/logger';
import ErrorState from '../../../reusable/ErrorState';
import { IoIosArrowBack, IoIosClose } from 'react-icons/io';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resolveCountryCode } from '../../../utils/countryState';
interface iFormData {
    First_Name: string;
    Last_Name: string;
    Phone_Number: string;
    State: string;
    City: string;
    Address: string;
    Gender: string;
    Title: string;
    Mcdn_Number: string;
    Email: string;
    Specialization: any;
    countryCode:any;
    countryStates:any;
    country:any;
}
const ProfileDetailsForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [buttonEnabled, setButton] = useState(false);
    const [countryCode, setCountryCode] = useState<string>('NG');
    const defaultCountry = resolveCountryCode('NG');
    const navigate = useNavigate();
    const hospitalAuthStore = useHospitalAuthStore((state) => state);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const mutation = useMutation((data:any) =>hospitalService.editUserProfile(data));
    const [uploadedImageFile, setUploadedImageFile] = useState<File | null>(null);
      
    const hospitalAccountStore = useHospitalAuthStore((state) => state);
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            console.log('success', mutation.data);
            let payload: any = mutation.data;
            hospitalAccountStore.updateUser(payload.data.payload.data.user);
            const data = payload.data;
            logger(data.message);
            // Show success message using Toastify
            toast.success('Profile updated successfully');
            setTimeout(() => {
                toast.dismiss(); // Dismiss the toast after 3000 milliseconds
               navigate(hospitalRoutes.profile)
            }, 2000);
        }
    }, [mutation.isSuccess, mutation.data,navigate]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            setErrorMessage(err.response.data.error.message);
            toast.error(err.response.data.error.message)
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);
const [selectedImage, setSelectedImage] = useState<any>(hospitalAuthStore.user.image || profile);
    
    interface Specialty {
        _id: string;
        name: string;
    }
    const [specialties, setSpecialties] = useState<Specialty[]>([]);
    const [specialtiesID, setSpecialtiesID] = useState<any>([]);

    useEffect(() => {
        // Fetch specialties from the backend when the component mounts
        hospitalService.getSpecialties()
        .then((specialtiesData) => {
            if (Array.isArray(specialtiesData.specialty)) {
                setSpecialties(specialtiesData.specialty)
                console.log(specialtiesData);
                setSpecialtiesID(formatedSpecialtyId)
            } 
            else {
                console.error('Specialties data is not an array:', specialtiesData.specialty);
            }
        })
            .catch((error) => {
                console.error('Error fetching specialties:', error);
            });
    }, []);
    const [formData, setFormData] = useState<iFormData>({
        First_Name: hospitalAuthStore.user.firstName,
        Last_Name: hospitalAuthStore.user.lastName,
        Phone_Number: hospitalAuthStore.user.phoneNumber,
        State:  hospitalAuthStore.user.state.trim(),
        City: hospitalAuthStore.user.city,
        Address: hospitalAuthStore.user.address,
        Gender:  hospitalAuthStore.user.gender || '',
        Title: hospitalAuthStore.user.title,
        Mcdn_Number: hospitalAuthStore.user.licenseNumber ||'',
        Email:  hospitalAuthStore.user.email,
        Specialization: hospitalAuthStore.user.specialties.length > 0 
        ? hospitalAuthStore.user.specialties : [],
        countryCode,
        countryStates: defaultCountry?.states || [],
        country: defaultCountry?.name || '',
    });
    const formatedSpecialty = formData.Specialization.map((data)=>{
        return(
            data.name
        )
    })
    const formatedSpecialtyId = formData.Specialization.map((data)=>{
        return(
            data._id
        )
    })
    console.log(formatedSpecialtyId); 
    
//    const formatedSpecialty = Array.isArray(formData.Specialization)
//     ? formData.Specialization.map((data) => data.name)
//     : [];

    const [selectedSpecializations, setSelectedSpecializations] = useState<any>(formatedSpecialty );
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setButton(true);
    };
    console.log(formatedSpecialty )
    const handleImageChange = (image: File) => {
        const imageUrl = URL.createObjectURL(image);

        setSelectedImage(imageUrl);
        setUploadedImageFile(image); // Set the file to be sent to the backend
        setButton(true);
    };
      
    const clearAll = (e: React.FormEvent) => {
        e.preventDefault();
        setFormData({
            First_Name: hospitalAuthStore.user.firstName,
            Last_Name: hospitalAuthStore.user.lastName,
            Phone_Number: '',
            State: '',
            City: '',
            Address: '',
            Gender: '',
            Title: hospitalAuthStore.user.title,
            Mcdn_Number: hospitalAuthStore.user.licenseNumber || '',
            Email: hospitalAuthStore.user.email,

            Specialization: [],
            countryCode,
        countryStates: defaultCountry?.states || [],
        country: defaultCountry?.name || '',
        });
        setSelectedImage(profile); // Reset the image to the default profile picture
        setSelectedSpecializations([]);
        setButton(false);
    };
   
    const handleSelectChange = (e: SelectChangeEvent) => {
        const { name, value } = e.target;
      
        // Check if the selected field is Gender or State and update accordingly
        if (name === "Gender" || name === "State") {
          setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
          }));
        } else {
          // For other fields like Specialization, update the selectedSpecializations array
          setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
          }));
          setSelectedSpecializations((prevSelected) => [...prevSelected, value]);

        }
        setButton(true);
      };
    //   const handleCountryChange = (code: string) => {
    //     const newCountry = resolveCountryCode(code);
    //     setCountryCode(code);
    //     setFormData((prevData: any) => ({
    //         ...prevData,
    //         country: newCountry?.name || '',
    //         countryStates: newCountry?.states || [],
          
    //     }));
    // };
     const handleSelectSpecialtyChange = (e: SelectChangeEvent) => {
        const {  value } = e.target;
          setSelectedSpecializations(prevSelected => {
            if (!prevSelected.some(item => item === value)) {
                return [...prevSelected, value];
            }
            return prevSelected;
        });
          console.log({value})
          const specialtyValue=specialties.find((specialty)=>specialty.name === value)
          console.log(specialtyValue)
          setSpecialtiesID((prevSelected) => [...prevSelected, specialtyValue?._id])
          setButton(true);
       }
       
    const removeSpecialization = (index: number) => {
        const updatedSpecializations = [...selectedSpecializations];
        updatedSpecializations.splice(index, 1);
        setSelectedSpecializations(updatedSpecializations);
    };
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // const file = new File([selectedImage], "profile-image.jpg", { type: "image/jpeg" });
        const userFormData = new FormData();
        userFormData.append('address', formData.Address);
        userFormData.append('city', formData.City);
        userFormData.append('phoneNumber', formData.Phone_Number);
        userFormData.append('state', formData.State);
        userFormData.append('gender', formData.Gender);
        userFormData.append('email', formData.Email);
        userFormData.append('image',  uploadedImageFile || '');
        userFormData.append('specialties', specialtiesID.join(','));
        // to display the content of userFormData as a regular Javascript object
        const FormatedUserData=Object.fromEntries(userFormData.entries())
        console.log('Submitted Form Data entry:', FormatedUserData);
        console.log('Submitted Form Data:', userFormData);

        setIsLoading(true);
        mutation.mutate(userFormData);
       setButton(false)
       
    };
    const handleTextareaChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setButton(true);
    };
    const resetToPrevious = () => {
        const specialtyNames = hospitalAuthStore.user.specialties.map((specialty) => specialty.name);
       console.log({specialtyNames})
        setFormData({
            First_Name: hospitalAuthStore.user.firstName,
            Last_Name: hospitalAuthStore.user.lastName,
            Phone_Number: hospitalAuthStore.user.phoneNumber,
            State: hospitalAuthStore.user.state.trim(),
            City: hospitalAuthStore.user.city,
            Address: hospitalAuthStore.user.address,
            Gender: hospitalAuthStore.user.gender || '',
            Title: hospitalAuthStore.user.title,
            Mcdn_Number: '',
            Email: hospitalAuthStore.user.email,
            Specialization: hospitalAuthStore.user.specialties.length > 0 
            ? hospitalAuthStore.user.specialties : [],
            countryCode,
            countryStates: defaultCountry?.states || [],
            country: defaultCountry?.name || '',
        });
    
        setSelectedImage(hospitalAuthStore.user.image || profile);
        setSelectedSpecializations(formatedSpecialty);
    
        setButton(false);
    };
    
    const goBack = () => {
        navigate(-1);
    };
    
    return (
        <Fragment>
            <ToastContainer/>
        <div className="w-full flex flex-col md:px-8 py-4 px-2 ">
                <div className="flex gap-2 items-center ">
                    <button onClick={goBack}>
                    <IoIosArrowBack color='#2D2D2E' size={'24px'}/>
                    </button>
                    <h3 className="font-bold sm:text-[28px] text-[20px]">
                    Editing Profile
                    </h3>
                </div>
        <div className="flex flex-col md:flex-row justify-between md:space-x-6 mt-10 md:mt-4 px-8 py-4">
            <ImageUpload
            className="rounded-full border items-center"
                onImageChange={handleImageChange}
                selectedImageProp={
                selectedImage? selectedImage : hospitalAuthStore.user.image
                        
                }
            />{' '}
            <div className="flex-1 bg-white p-4 rounded-2xl mt-10 md:mt-0">
                <form action="" onSubmit={handleSubmit} className="w-full p-4">
                    <div className="flex items-center justify-between w-full py-4">
                        <h2 className="font-medium text-base sm:text-[22px] text-lightBrown w-full">
                            Personal information
                        </h2>
                        <span className="border-t h-[1px] max-w-[501px] w-full border-brown100"></span>
                    </div>
                    <div className="flex sm:items-center flex-col gap-3 sm:gap-5 sm:flex-row my-6  w-full">
                        <Input
                            value={formData.First_Name}
                            name="firstName"
                            label="First Name"
                            disabled={true}
                        />

                        <Input
                            value={formData.Last_Name}
                            name="lastName"
                            label="Last Name"
                            disabled={true}
                        />
                    </div>
                    <Input
                        value={formData.Email}
                        name="Email"
                        label="Email"
                        onChange={handleChange}
                        disabled={true}
                    />
                    {/* <div className='w-full '>
                            
                            <ReactFlagsSelect
                                        selected={countryCode}
                                        searchable
                                        fullWidth={false}
                                        placeholder="Country"
                                        onSelect={handleCountryChange}
                                        disabled={true}
                                    />
                              </div> */}
                             
                            
                                 
                    <div className="flex sm:items-center my-6 flex-col sm:flex-row gap-3 sm:gap-5  w-full">
                        <div className="sm:flex-1">
                            <SelectElement
                                onChange={handleSelectChange}
                                value={formData.Gender}
                                name="Gender"
                                // defaultValue={formData.Gender}
                                options={['Female', 'Male']}
                            />
                        </div>
                        <div className="sm:flex-1">
                            <Input
                                value={ formData.Phone_Number}
                                name="Phone_Number"
                                label="Phone Number (optional)"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="flex sm:items-center  my-6 flex-col sm:flex-row gap-3 sm:gap-5  w-full">
                        {/* <div className="sm:flex-1">
                            <SelectElement
                                onChange={handleSelectChange}
                                value={formData.State}
                                name="State"
                                options={['Ogun', 'lagos']}
                            />
                        </div> */}
                           <div className="sm:flex-1">
                                    <FormControl
                                        fullWidth
                                        sx={{ minWidth: 120 }}
                                        size="small"
                                    >
                                        <InputLabel
                                            id="demo-select-small-label"
                                            htmlFor={'state'}
                                        >
                                            {'State'}
                                        </InputLabel>

                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            label="State"
                                            onChange={handleSelectChange}
                                            name="State"
                                            value={formData.State}
                                            className="w-full  p-2 "
                                        >
                                            {formData.countryStates.map(
                                                (state: any) => (
                                                    <MenuItem
                                                        key={state.code}
                                                        value={state.name}
                                                    >
                                                        {state.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                        <div className="sm:flex-1">
                            <Input
                                value={formData.City}
                                name="City"
                                label="City (optional)"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <textarea
                        value={formData.Address }
                        name="Address"
                        placeholder="Address (optional)"
                        onChange={handleTextareaChange}
                        className="w-full h-[100px] my-6  border-[1px] border-dark100 p-4 rounded-[8px] text-[16px] tracking-wider focus:border-[1px] focus:outline-none focus:border-dark100"
                    ></textarea>

                    <div>
                        <div className="flex items-center justify-between w-full ">
                            <h2 className="font-medium text-base sm:text-[22px] text-lightBrown">
                                Professional information
                            </h2>
                            <span className="border-t h-[1px] max-w-[357px] w-full border-brown100"></span>
                        </div>
                        <div className="flex sm:items-center my-6 flex-col sm:flex-row gap-3 sm:gap-5  w-full">
                            <div className="sm:flex-1">
                                <Input
                            value={formData.Title}
                            name="Title"
                            label="Title"
                            disabled={true}
                        />
                            </div>
                            <div className="sm:flex-1">
                                <Input
                                    value={formData.Mcdn_Number}
                                    name="Mcdn_Number"
                                    label="MDCN License number "
                                    onChange={handleChange}
                        disabled={true}

                                />
                            </div>
                        </div>
                    </div>
                    
                    <SelectElement
                        onChange={handleSelectSpecialtyChange}
                        value={ formData.Specialization}
                        name="Specialization"
                        options={specialties.map((specialty) => specialty.name)}
                    />
                    
                    {selectedSpecializations.length > 0 && (
                        <ul className='pt-4 flex items-center gap-2 flex-wrap'>
                            {selectedSpecializations.map((specialization, index) => {
                                return (
                                <li key={index} className={`flex items-center border gap-3 rounded-lg py-[6px] pr-[8px] pl-[12px]  border-[#4D4544] w-fit`}>
                                    <span className="font-normal text-[16px] text-[#201A19]"> {specialization}</span> 
                                    <button onClick={() => removeSpecialization(index)}><IoIosClose size={'18'} /></button>
                                </li>
                                );
                            
                            })}
                        </ul>
                        )}

                     {errorMessage && <ErrorState message={errorMessage} />}
                    <div className="flex justify-end items-center space-x-6 py-3 border-t  mt-7">
                                <button
                                type='reset'
                                disabled={!buttonEnabled}
                                    onClick={resetToPrevious}
                                    className={`border-0 text-[16px] mt-4 py-[10px] ${
                                        buttonEnabled
                                        ? 'text-[#201A19] hover:cursor-pointer  '
                                        : 'text-[lightGray]  hover:cursor-not-allowed'
                                    }`}
                                >
                                    Restore to Previous
                                </button>
                        <button
                        
                            disabled={!buttonEnabled}
                            onClick={clearAll}
                            className={`mt-4 py-[10px] px-6 rounded-lg border  ${
                                buttonEnabled
                                    ? 'text-Tmred hover:cursor-pointer border-[#999797] '
                                    : ' text-[#999797] hover:cursor-not-allowed'
                            } `}
                        >
                            Clear All
                        </button>
                        <button
                        type='submit'
                            disabled={!buttonEnabled}
                            className={`mt-4 py-[10px] px-6 rounded-lg  ${
                                buttonEnabled
                                    ? 'bg-Tmred text-white hover:cursor-pointer '
                                    : 'bg-[#1D1B201F] text-[#999797] hover:cursor-not-allowed'
                            } `}
                        >
                            {isLoading ? 'Updating...' : 'Update'}
                        </button>
                        <div>
                       
                        </div>
                       
                    </div>
                </form>
            </div>
        </div>
        </div>
        </Fragment>
    );
};
export default ProfileDetailsForm;
