import React from 'react';
import back from '../../Images/back.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import flutterwave from '../../Images/Flutterwave.png';
import copy from '../../Images/copy.svg';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Input from '../../reusable/Forms/Input';
import hospitalService from '../../services/hospitalService';
import { standardAmountFormat } from '../../utils/helpers';
import { useHospitalAuthStore } from '../../store/hospital/auth';

type InputProps = {
    amount: string;
};

const TopUp = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const walletType = 'personal';
    const currency = useHospitalAuthStore((state) => state.hospital.currency);

    // useEffect(() => {
    //     if (hospitalAuthStore.userType === 'superadmin') {
    //         setWalletType('hospital');
    //     } else {
    //         setWalletType('personal');
    //     }
    // }, []);

    const { data, isLoading: walletLoading } = useQuery(
        ['balance', walletType],
        () => hospitalService.getWalletBalance(walletType)
    );

    // const {data,isLoading:walletLoading } = useQuery('balance', hospitalService.getWalletBalance)

    const walletBalance = data;
    const walletBalanceValue = data?.balance;

    useEffect(() => {
        // console.log(walletBalance);
    }, [walletBalance]);

    // const [isChecked, setIsChecked] = useState(false)
    // const toggleChecked = ()=>{
    //       setIsChecked(!isChecked)
    // }
    const [formData, setFormData] = useState({
        Card_Holder: '',
        Card_Number: '',
        Amount: '',
        Cvv: '',
        Expiry_Date: '',
        Payment_Method: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // const handlesubmit = (e: React.FormEvent) => {
    //     e.preventDefault();
    //     console.log(formData);
    // };

    // useEffect(() => {
    //     if (
    //         formData.Amount !== '' &&
    //         formData.Card_Holder !== '' &&
    //         formData.Card_Number !== '' &&
    //         formData.Cvv !== '' &&
    //         formData.Expiry_Date !== '' &&
    //         formData.Payment_Method !== ''
    //     ) {
    //         setButtonEnabled(true);
    //     }
    // }, [
    //     formData.Amount,
    //     formData.Card_Holder,
    //     formData.Card_Number,
    //     formData.Cvv,
    //     formData.Expiry_Date,
    //     formData.Payment_Method,
    // ]);

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
    } = useForm<InputProps>();

    const mutation = useMutation((data: InputProps) =>
        hospitalService.fundWallet(data, walletType)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            const response = mutation.data;
            const message = response.data.message;
            toast.success(message);
            const link = response.data.payload.data.paymentLink;
            const openWindowWithDelay = () => {
                window.open(link, '_blank');
            };

            // Set a 3-second delay
            setTimeout(openWindowWithDelay, 2000);
            reset({});
        }
    }, [mutation.isSuccess, mutation.data, reset]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;

            toast.error(err.response?.data?.error?.message);
            reset({});
        }
    }, [mutation.isError, mutation.error]);

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        setIsLoading(true);
        mutation.mutate(data);
    };

    const goBack = () => {
        navigate(-1); // This will take you back one step in the history
    };

    return (
        <div className="w-full flex flex-col md:px-8 py-4 px-2 ">
            <ToastContainer />
            <div className="flex gap-2 items-center">
                <button onClick={goBack}>
                    <img src={back} alt="" />
                </button>
                <h3 className="font-bold sm:text-[28px] text-[20px]">
                    Add Money
                </h3>
            </div>

            <div className="max-w-[550px] w-full p-8 rounded-2xl bg-[#FFFFFF]">
                <div>
                    <p className=" font-normal text-deep_black text-sm ">
                        Available Balance
                    </p>
                    <p className="text-[#000] text-[20px] font-semibold">
                        {walletLoading
                            ? '0'
                            : standardAmountFormat(
                                  walletBalanceValue,
                                  currency
                              )}
                    </p>
                </div>
                <form action="" onSubmit={handleSubmit(onSubmitForm)}>
                    <div className=" mt-10 flex flex-col">
                        <div className=" mb-2">
                            <p className="font-normal text-deep_black text-sm">
                                Pay With
                            </p>
                        </div>

                        <div className="flex flex-col gap-6">
                            <div className="flex py-2 gap-6 border-b-[1px] border-[#D8C2BF]">
                                <div className="flex gap-4">
                                    <input
                                        type="radio"
                                        id="card"
                                        value="card"
                                        name="Payment_Method"
                                        onChange={handleChange}
                                        // onClick={toggleChecked}
                                        // checked
                                        className="accent-[#c00010]"
                                    />
                                    <p className="text-deep_black text-sm font-normal">
                                        Card
                                    </p>
                                </div>
                                <div className="flex gap-4">
                                    <input
                                        type="radio"
                                        value="transfer"
                                        name="Payment_Method"
                                        id="transfer"
                                        onChange={handleChange}
                                        // onClick={toggleChecked}
                                        className="accent-[#c00010]"
                                    />
                                    <p className="text-deep_black text-sm font-normal">
                                        Transfer
                                    </p>
                                </div>
                            </div>
                            {formData.Payment_Method == 'card' ? (
                                <div className="flex flex-col gap-6">
                                    <div>
                                        <Controller
                                            control={control}
                                            name="amount"
                                            defaultValue=""
                                            rules={{
                                                required: 'Amount is required',
                                            }}
                                            render={({ field }) => (
                                                <Input
                                                    label="Amount"
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {/* <input
                                            type="text"
                                            value={formData.Amount}
                                            placeholder="Enter Amount"
                                            name="Amount"
                                            onChange={handleChange}
                                            required={true}
                                            className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider"
                                        /> */}
                                    </div>

                                    <div>
                                        <div className="flex justify-between items-center">
                                            <p className="text-[#534341] text-[16px]">
                                                Saved Cards
                                            </p>
                                            <button className="text-[14px] text-[#534341] opacity-30">
                                                View all
                                            </button>
                                        </div>
                                        <div className="text-center mt-2">
                                            <p>No cards saved</p>
                                        </div>
                                    </div>

                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Card Holder Name"
                                            name="Card_Holder"
                                            value={formData.Card_Holder}
                                            onChange={handleChange}
                                            className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider"
                                        />
                                    </div>

                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Card Number"
                                            name="Card_Number"
                                            value={formData.Card_Number}
                                            onChange={handleChange}
                                            maxLength={16}
                                            minLength={16}
                                            className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider"
                                        />
                                    </div>

                                    <div className="md:flex space-y-6 md:space-y-0 gap-6 items-center">
                                        <div className="w-full">
                                            <input
                                                type="text"
                                                placeholder="Cvv"
                                                name="Cvv"
                                                maxLength={3}
                                                minLength={3}
                                                value={formData.Cvv}
                                                onChange={handleChange}
                                                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider"
                                            />
                                        </div>
                                        <div className="w-full">
                                            <input
                                                type="text"
                                                placeholder="Expiry Date"
                                                name="Expiry_Date"
                                                onChange={handleChange}
                                                value={formData.Expiry_Date}
                                                className="w-full border border-[#857371] p-4 rounded-[8px] text-[16px] tracking-wider"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-4">
                                        <input
                                            type="checkbox"
                                            value="agree"
                                            className="accent-[#c00010]"
                                        />
                                        <p className="text-deep_black font-normal text-[14px]">
                                            Save this card for future use{' '}
                                        </p>
                                    </div>

                                    <div>
                                        <button
                                            disabled={
                                                !isDirty ||
                                                !isValid ||
                                                isLoading
                                            }
                                            type="submit"
                                            className={`w-full h-[40px] flex justify-center rounded-lg items-center text-[#9d9b9b] font-semibold  transition-all duration-500  ${
                                                !isValid ||
                                                !isDirty ||
                                                isLoading
                                                    ? 'hover:cursor-not-allowed bg-[#1D1B201F] '
                                                    : 'bg-Tmred text-white hover:cursor-pointer] '
                                            } `}
                                        >
                                            Add Money
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex text-center flex-col md:py-12 md:px-8 mt-6">
                                    <div className=" flex-col justify-center py-5">
                                        <div className="flex justify-center mb-3">
                                            <img src={flutterwave} alt="" />
                                        </div>
                                        <div className="flex justify-center gap-4">
                                            <p className="text-[20px] text-deep_black font-semibold">
                                                0198 457 890
                                            </p>
                                            <img src={copy} alt="" />
                                        </div>
                                    </div>
                                    <div className=" mt-8">
                                        <button
                                            type="submit"
                                            className="w-full h-[40px] flex justify-center rounded-lg items-center font-semibold transition-all duration-500 bg-Tmred text-white hover:cursor-pointer "
                                        >
                                            I have sent the money
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TopUp;
