import { useState, useEffect } from 'react';
import profile from '../../Images/profile.png';
import { useNavigate } from 'react-router-dom';
import Input from '../../reusable/Forms/Input';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import {
    Select,
    MenuItem,
    SelectChangeEvent,
    InputLabel,
    FormControl,
} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import hospitalService from '../../services/hospitalService';
import { ToastContainer, toast } from 'react-toastify';
import { IoIosClose } from 'react-icons/io';
import ImageUploadDoc from '../UserNurse/ImageUploadNurs';
import { hospitalRoutes } from '../../utils/routeNames';
import { resolveCountryCode } from '../../utils/countryState';
import medicalTitles from '../../services/constants/medicalTitles';
import { permission } from '../../utils/PermisionList'
type InputProps = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    licenseNumber: string;
    title: string;
    userType: string;
    specialties: string[];
    image: any;
    password: string;
    gender: string;
    address: string;
    city: string;
    state: string;
    permissions: string[];
    salutation: string;
};

const DoctorDetailsForm = () => {
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState<
        string | ArrayBuffer | null
    >(profile);
    const [uploadedImageFile, setUploadedImageFile] = useState<any>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedSpecialties, setSelectedSpecialties] = useState<string[]>(
        []
    );
    const [specialtiesID, setSpecialtiesID] = useState<any>([]);
    const defaultCountry = resolveCountryCode('NG');
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>(
        []
    );
    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
    } = useForm<InputProps>();
   
    const { data} = useQuery(
        'specialty',
        hospitalService.getSpecialties
    );

    const filteredOptions = data?.specialty?.filter(({ name }) =>
        name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const mutation = useMutation((data: any) =>
        hospitalService.createUser(data)
    );
    useEffect(() => {
        if (mutation.isSuccess) {
            console.log('success', mutation.data);
            const response = mutation.data;
            const message = response.data.message;
            console.log('Success:', message);
            reset({});
            setSelectedImage('');
            toast.success(message, {
                autoClose: 1000,
                onClose: () => {
                    setTimeout(() => {
                        navigate(hospitalRoutes.doctor);
                    }, 0);
                },
            });
        }
    }, [mutation.isSuccess, mutation.data, reset, setSelectedImage]);

    useEffect(() => {
        if (mutation.isError) {
            let err: any = mutation.error;
            console.log('is error', err);
           
            toast.error(err.response?.data?.error?.message);
        } 
    }, [mutation.isError, mutation.error]);

    const handleImageChange = (image: any) => {
        setSelectedImage(image ? URL.createObjectURL(image) : '');
        setUploadedImageFile(image);
    };
    const handleSpecialtyChange = (e: SelectChangeEvent<string[]>) => {
        const selectedValue = e.target.value as string;
        setSelectedSpecialties((prevSelected) => {
            if (!prevSelected.some((item) => item === selectedValue)) {
                return [...prevSelected, selectedValue];
            }
            return prevSelected;
        });
        const specialtyValue = filteredOptions.find(
            (specialty) => specialty.name === selectedValue
        );
        console.log(specialtyValue);
        setSpecialtiesID((prevSelected) => [
            ...prevSelected,
            specialtyValue?._id,
        ]);
        console.log('Selected Specialities:', selectedSpecialties);
    };

    const handleSpecialtyRemove = (indexToRemove: number) => {
        setSelectedSpecialties((prev) =>
            prev.filter((_, index) => index !== indexToRemove)
        );
    };
    const handlePermissionChange = (e: SelectChangeEvent<string[]>) => {
        const selectedValue = e.target.value as string;
        setSelectedPermissions((prevSelected) => {
            if (!prevSelected.some((item) => item === selectedValue)) {
                return [...prevSelected, selectedValue];
            }
            return prevSelected;
        });
        const permissionValue = permission.find(
            (pms) => pms.perm === selectedValue
        );
        console.log(permissionValue);
    };

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        const userData = new FormData();
        userData.append('userType', 'doctor');
        userData.append('image', uploadedImageFile);
        userData.append('firstName', data.firstName);
        userData.append('lastName', data.lastName);
        userData.append('salutation', data.salutation);
        userData.append('email', data.email);
        userData.append('phoneNumber', data.phoneNumber);
        userData.append('licenseNumber', data.licenseNumber);
        userData.append('title', data.title);
        userData.append('gender', data.gender);
        userData.append('address', data.address);
        userData.append('city', data.city);
        userData.append('state', data.state);
        userData.append('specialties', specialtiesID.join(','));
        const permissionsString = `['${selectedPermissions.join("', '")}']`;
        userData.append('permissions', permissionsString);
        mutation.mutate(userData);
    };
    const handlePermissionRemove = (indexToRemove: number) => {
        setSelectedPermissions((prev) =>
            prev.filter((_, index) => index !== indexToRemove)
        );
    };
    const handleClearAll = () => {
        reset({
            firstName: '',
            lastName: '',
            salutation: '',
            email: '',
            gender: '',
            phoneNumber: '',
            state: '',
            city: '',
            address: '',
            licenseNumber: '',
            title: '',
            specialties: [],
            permissions: [],
        });
    };

    return (
        <div className="flex flex-col md:flex-row justify-between md:space-x-6 mt-10 md:mt-4">
            <ToastContainer />
            <ImageUploadDoc
                onImageChange={handleImageChange}
                selectedImage={selectedImage}
            />
            <div className="flex-1 bg-white p-4 rounded-2xl mt-10 md:mt-0">
                <h2 className="text-[22px] font-semibold text-lightBrown text-center p-4 bg-lightPink">
                    Doctor’s Details{' '}
                </h2>
                <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
                    <div className="flex items-center justify-between mt-10  w-full ">
                        <h2 className="font-semibold text-base text-lightBrown">
                            Personal information
                        </h2>
                        <span className="border-t h-[1px] max-w-[501px] w-full border-brown100"></span>
                    </div>
                    <div className="flex flex-row">
                        <div className="w-1/2 py-2">
                            <Controller
                                name="salutation"
                                control={control}
                                render={({ field }) => (
                                    <FormControl
                                        fullWidth
                                        sx={{ minWidth: 120 }}
                                        size="small"
                                    >
                                        <InputLabel
                                            id="demo-select-small-label"
                                            htmlFor={'salutation'}
                                        >
                                            {'Salutation'}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            label="Salutation"
                                            {...field}
                                            className="w-full p-2"
                                        >
                                            <MenuItem value="Mr.">Mr.</MenuItem>
                                            <MenuItem value="Mrs.">
                                                Mrs.
                                            </MenuItem>
                                            <MenuItem value="Miss">
                                                Miss
                                            </MenuItem>
                                            <MenuItem value={'Dr.'}>
                                                Dr.
                                            </MenuItem>
                                        </Select>
                                        {/* <SelectElement
                                            label="Salutation"
                                            {...field}
                                            value={field.value}
                                            options={[
                                                'Mr.',
                                                'Mrs.',
                                                'Miss',
                                                'Dr.',
                                            ]}
                                        /> */}
                                    </FormControl>
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex items-center my-6 space-x-6">
                        <Controller
                            name="firstName"
                            defaultValue=""
                            rules={{
                                required: 'First Name is required',
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input label="First Name" {...field} />
                            )}
                        />

                        <Controller
                            name="lastName"
                            defaultValue=""
                            rules={{
                                required: 'Last Name is required',
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input label="Last Name" {...field} />
                            )}
                        />
                    </div>
                    <Controller
                        name="email"
                        defaultValue=""
                        rules={{
                            required: 'Email Address is required',
                        }}
                        control={control}
                        render={({ field }) => (
                            <Input label="Email Address" {...field} />
                        )}
                    />

                    <div className="flex items-center my-6 space-x-6">
                        <Controller
                            name="gender"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <FormControl
                                    fullWidth
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                >
                                    <InputLabel
                                        id="demo-select-small-label"
                                        htmlFor={'gender'}
                                    >
                                        {'Gender'}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        label="Gender"
                                        {...field}
                                        className="w-full p-2"
                                    >
                                        <MenuItem value="female">
                                            female
                                        </MenuItem>
                                        <MenuItem value="male">male</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="phoneNumber"
                            defaultValue=""
                            rules={{
                                required: 'Phone Number is required',
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input label="Phone Number" {...field} />
                            )}
                        />
                    </div>
                    <div className="flex items-center  space-x-6">
                        <Controller
                            name="state"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <FormControl
                                    fullWidth
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                >
                                    <InputLabel
                                        id="demo-select-small-label"
                                        htmlFor={'state'}
                                    >
                                        {'State'}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        label="State"
                                        {...field}
                                        className="w-full p-2"
                                    >
                                        {defaultCountry?.states.map(
                                            (state: any) => (
                                                <MenuItem
                                                    key={state.code}
                                                    value={state.name}
                                                >
                                                    {state.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            )}
                        />

                        <Controller
                            name="city"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <Input label="City (optional)" {...field} />
                            )}
                        />
                    </div>
                    <Controller
                        name="address"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <textarea
                                placeholder="Address (optional)"
                                required={true}
                                className="w-full h-[100px] my-6 border-[1px] border-dark100 p-4 rounded-[8px] text-[16px] tracking-wider focus:border-[1px] focus:outline-none focus:border-dark100"
                                {...field}
                            ></textarea>
                        )}
                    />
                    <div>
                        <div className="flex items-center justify-between w-full ">
                            <h2 className="font-semibold text-base text-lightBrown">
                                Professional information
                            </h2>
                            <span className="border-t h-[1px] max-w-[470px] w-full border-brown100"></span>
                        </div>
                        <div className="flex items-center space-x-6 my-6">
                            <Controller
                                name="title"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <FormControl
                                        fullWidth
                                        sx={{ minWidth: 120 }}
                                        size="small"
                                    >
                                        <InputLabel
                                            id="demo-select-small-label"
                                            htmlFor={'title'}
                                        >
                                            {'Title'}
                                        </InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            label="Title"
                                            {...field}
                                            className="w-full p-2"
                                        >
                                            {medicalTitles.map(
                                                (option, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={option}
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                )
                                            )}
                                            {/* <MenuItem value="Dr">
                                                Doctor
                                            </MenuItem>
                                            <MenuItem  value="Nurse">
                                                Nurse
                                            </MenuItem> */}
                                        </Select>
                                    </FormControl>
                                )}
                            />

                            <Controller
                                name="licenseNumber"
                                defaultValue=""
                                rules={{
                                    required: 'MDCN License number is required',
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        label="MDCN License number"
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <Controller
                        name="specialties"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <FormControl
                                fullWidth
                                sx={{ minWidth: 120 }}
                                size="small"
                            >
                                <InputLabel
                                    id="demo-select-small-label"
                                    htmlFor={'specialties'}
                                >
                                    {'Speciality (Optional)'}
                                </InputLabel>
                                <Select
                                    label="Speciality (Optional)"
                                    {...field}
                                    className="w-full p-2"
                                    onChange={handleSpecialtyChange}
                                >
                                    {filteredOptions?.map((specialty) => (
                                        <MenuItem
                                            key={specialty.name}
                                            value={specialty.name}
                                        >
                                            {specialty.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <ul className="pt-4 flex items-center gap-2 flex-wrap">
                                    {selectedSpecialties.map(
                                        (specialty, index) => (
                                            <li
                                                key={index}
                                                className={`flex items-center border gap-3 rounded-lg py-[6px] pr-[8px] pl-[12px]  border-[#4D4544] w-fit`}
                                            >
                                                <span className="font-normal text-[16px] text-[#201A19]">
                                                    {specialty}
                                                </span>
                                                <button
                                                    onClick={() =>
                                                        handleSpecialtyRemove(
                                                            index
                                                        )
                                                    }
                                                >
                                                    <IoIosClose size={'18'} />
                                                </button>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </FormControl>
                        )}
                    />
                    <div className="mt-3">
                        <Controller
                            name={'permissions'}
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                                <FormControl
                                    fullWidth
                                    sx={{ minWidth: 120 }}
                                    size="small"
                                >
                                    <InputLabel
                                        id="demo-select-small-label"
                                        htmlFor={'permissions'}
                                    >
                                        {'User Group'}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        label="User Group"
                                        {...field}
                                        className="w-full p-2"
                                        onChange={handlePermissionChange}
                                    >
                                        {permission?.map((pms) => (
                                            <MenuItem
                                                key={pms.id}
                                                value={pms.perm}
                                            >
                                                {pms.perm}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <ul className="pt-4 flex items-center gap-2 flex-wrap">
                                        {selectedPermissions.map(
                                            (pms, index) => (
                                                <li
                                                    key={index}
                                                    className={`flex items-center border gap-3 rounded-lg py-[6px] pr-[8px] pl-[12px]  border-[#4D4544] w-fit`}
                                                >
                                                    <span className="font-normal text-[16px] text-[#201A19]">
                                                        {pms}
                                                    </span>
                                                    <button
                                                        onClick={() =>
                                                            handlePermissionRemove(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <IoIosClose
                                                            size={'18'}
                                                        />
                                                    </button>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </FormControl>
                            )}
                        />
                    </div>

                    <div className="flex justify-end space-x-6 py-3 border-t mt-10">
                        <button
                            type="reset"
                            className="py-2 px-3 text-Tmred font-normal text-base"
                            onClick={handleClearAll}
                        >
                            Clear All
                        </button>
                        <button
                            disabled={!isDirty || !isValid}
                            type="submit"
                            className={` px-6 py-2 text-lightBrown text-base font-normal rounded-lg 
                             ${
                                 !isDirty || !isValid || mutation.isLoading
                                     ? 'bg-lightGray cursor-not-allowed'
                                     : ' bg-Tmred text-white hover:cursor-pointer'
                             }
                            `}
                        >
                            {mutation.isLoading ? 'Registering...' : 'Register'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default DoctorDetailsForm;
