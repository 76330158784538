import React, { useEffect, useState } from 'react';
import { useHospitalAuthStore } from '../store/hospital/auth';
import { standardAmountFormat } from './helpers';

const CurrencyFormat = ({ amount }: { amount: string | number }) => {
    const currency = useHospitalAuthStore((state) => state.hospital.currency);

    // Split the formatted amount to get currency and amount separately
    const formattedAmount = standardAmountFormat(amount || 0, currency);
    const [formattedCurrency, formattedValue] = formattedAmount.split(' ');
    // const [currency, setCurrency] = useState('');
    const [price, setPrice] = useState(amount);
    // const { data, isError, isLoading } = useQuery(
    //     'bills',
    //     hospitalService.getBills
    // );
    // const country = data?.billing[0].currency;
    console.log(formattedValue);
    useEffect(() => {
        // setCurrency(data?.billing[0].currency);
        if (typeof amount === 'string') {
            setPrice(parseFloat(amount));
        } else {
            setPrice(amount);
        }
    }, [amount]);

    return (
        <div>
            {formattedCurrency +
                ' ' +
                price?.toLocaleString('en-NG', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}
        </div>
    );
};

export default CurrencyFormat;
