import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Layout from '../Layout';
import { useNavigate } from 'react-router-dom';
import { useHospitalAuthStore, useResetPasswordStore } from '../../../store/hospital/auth';
import ErrorState from '../../../reusable/ErrorState';
import { hospitalRoutes } from '../../../utils/routeNames';
import BlockButton from '../../../reusable/BlockButton';
import HospitalAuthHeader from '../../../reusable/HospitalAuthHeader';
import { isTokenValid } from '../../../utils/helpers';
import PasswordInput from '../../../reusable/Forms/PasswordInput';
import { useMutation } from 'react-query';
import hospitalService from '../../../services/hospitalService';
import {Helmet} from 'react-helmet'


type ResetPasswordProps = {
    newPassword: string;
    confirmNewPassword?: string;
    token: string;
};

const ResetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string>('');

    const hospitalAuthStore = useHospitalAuthStore((state) => state);
    const resetPasswordStore = useResetPasswordStore((state) => state);

    useEffect(() => {
        if (!isTokenValid(resetPasswordStore.token)) {
            navigate(hospitalRoutes.forgotPassword);
        }
    }, []);

    const { formState: { isDirty, isValid }, handleSubmit, control } = useForm<ResetPasswordProps>();

    const mutation = useMutation((data: ResetPasswordProps) => hospitalService.resetPassword(data));

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            // let response: any = mutation.data;
            // const data = response.data.payload;
            resetPasswordStore.setToken('');
            navigate(hospitalRoutes.landingLogin);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            console.log('is error', err);
            setErrorMessage(err.response.data.error.message);
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);

    const submitResetPasswordForm: SubmitHandler<ResetPasswordProps> = (data) => {
        const { newPassword, confirmNewPassword } = data;
        if(newPassword !== confirmNewPassword) {
            setErrorMessage('Password and Confirm Password do not match');
            return;
        }
        setIsLoading(true);
        const resetPasswordData = {
            newPassword,
            token: resetPasswordStore.token,
        };
        mutation.mutate(resetPasswordData);
    };
    return (
        <Layout>
            <Helmet>
                <title>
                    TMR | Reset Password
                </title>
            </Helmet>
            <HospitalAuthHeader
                logo={hospitalAuthStore.hospital.logo}
                text={hospitalAuthStore.hospital.tagline}
                title={hospitalAuthStore.hospital.name}
            />

            <div className="flex flex-col justify-center items-center  mb-10">
                <div className="flex flex-col justify-center border border-[#00000033] bg-white rounded-[25px] px-[40px] py-[40px] max-w-[592px] w-[fill]">
                    <div className="flex items-center justify-center">
                        <h1 className=" font-semibold text-center text-[32px]  ">
                            {' '}
                            Reset Password{' '}
                        </h1>
                    </div>
                    <div className="flex flex-col items-center">
                        <p className="text-black text-center">
                            Enter your new password
                        </p>
                        {errorMessage && <ErrorState message={errorMessage} />}
                        <form onSubmit={handleSubmit(submitResetPasswordForm)} className="w-[fill]">
                            <div className="flex flex-col items-start relative">
                                <Controller
                                    name="newPassword"
                                    rules={{ required: 'Password is required' }}
                                    control={control}
                                    render={({ field }) => (
                                        <PasswordInput placeholder='Password' {...field} />
                                    )}
                                />
                            </div>

                            <div className="flex flex-col items-start relative">
                                <Controller
                                    name="confirmNewPassword"
                                    rules={{ required: 'Password is required' }}
                                    control={control}
                                    render={({ field }) => (
                                        <PasswordInput placeholder='Repeat password' {...field} />
                                    )}
                                />
                            </div>
                            <BlockButton
                                text="Submit"
                                disabled={!isDirty || !isValid}
                                type="submit"
                                isLoading={isLoading}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ResetPassword;
