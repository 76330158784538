import { useMutation } from 'react-query';
import Input from '../../reusable/Forms/Input';
import { useEffect, useState } from 'react';
import hospitalService from '../../services/hospitalService';
import logger from '../../utils/logger';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import SearchedPatientModal from './SearchedPatientModal';
import { usePatientSearchStore } from '../../store/hospital/auth';
import { toast } from 'react-toastify';

type SearchProps = {
    tmrNumber: string;
};

interface ModalProps {
    closeModal: () => void;
    updateTmrNumber: (newTmrNumber: string) => void;
    onPatientAdd: () => void;
}

const BillingModal: React.FC<ModalProps> = ({
    closeModal,
    updateTmrNumber,
    onPatientAdd,
}) => {
    const { setSearchedPatient } = usePatientSearchStore();

    const [searchedModal, setSearchedModal] = useState(false);
    const [toastShown, setToastShown] = useState(false);
    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
    } = useForm<SearchProps>();
    const mutation = useMutation((data: SearchProps) =>
        hospitalService.checkPatientTmrNum(data)
    );

    useEffect(() => {
        if (mutation.isSuccess) {
            let response: any = mutation.data;
            const data = response.data;
            logger(data.message);
            const patientSearched = data?.payload?.data?.patients;
            logger(patientSearched);

            setSearchedPatient(patientSearched);

            if (!patientSearched) {
                if (!toastShown) {
                    toast.error("Patient doesn't exist");
                    setToastShown(true);
                }
                updateTmrNumber('');
                reset({});
                return;
            }
            setToastShown(false);
            reset();
            setSearchedModal(true);
        }
    }, [mutation.isSuccess, mutation.data, closeModal]);

    useEffect(() => {
        if (mutation.isError) {
            let err: any = mutation.error;
            toast.error(err.response.data.error.message);
        } 
    }, [mutation.isError, mutation.error]);
    const submitSearch: SubmitHandler<SearchProps> = (data) => {
        updateTmrNumber(data.tmrNumber);
        mutation.mutate(data);
        // closeModal();
    };

    const handleCloseModal = () => {
        closeModal();
        setSearchedModal(false);
    };
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#2826265E] bg-opacity-75 z-50">
            {/* <ToastContainer/> */}
            <div className="bg-white p-6 rounded-[28px] w-full m-2 max-w-[492px] sm:m-0 ">
                <h2 className="font-semibold text-2xl text-Tmred">
                TMR Patient Search
                </h2>
                <p className="text-lightBrown text-sm font-medium mt-3">
                    Kindly fill the patient TMR number or Name
                </p>
                <form onSubmit={handleSubmit(submitSearch)} className="mt-6">
                    <Controller
                        name="tmrNumber"
                        rules={{ required: 'Tmr number is required' }}
                        control={control}
                        render={({ field }) => (
                            <Input label="Tmr Number or Patient Name" {...field} />
                        )}
                    />

                    <div className="flex justify-end space-x-6 py-3 mt-6">
                        <button
                            type="reset"
                            onClick={closeModal}
                            className="py-[10px] px-4 text-lightBrown text-sm font-medium"
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            disabled={!isDirty || !isValid}
                            className={` text-sm font-medium py-[10px] px-4 text-lightBrown rounded-lg  ${
                                !isDirty || !isValid || mutation.isLoading
                                    ? ' bg-lightGray cursor-not-allowed'
                                    : ' bg-Tmred text-white hover:cursor-pointer'
                            }`}
                        >
                            Search Patient
                        </button>
                    </div>
                </form>
            </div>
            {searchedModal && (
                <SearchedPatientModal
                    closeModal={handleCloseModal}
                    onPatientAdd={onPatientAdd}
                    onResetTmrNumber={() => updateTmrNumber('')}
                />
            )}
        </div>
    );
};
export default BillingModal;
