import { useEffect, useState } from 'react';
import Tab from './Tab';
import Tabs from './Tabs';
import { useQuery } from 'react-query';
import hospitalService from '../../services/hospitalService';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import { useHospitalAuthStore} from '../../store/hospital/auth';
import AdsData from './AdsData';



const AddHistories = () => {
    const userStore = useHospitalAuthStore((state) => state.user);
    const [ADS, setADS] = useState<any[]>([]);
    const [approvedADS, setapprovedADS] = useState<any[]>([]);
    const [pendingADS, setpendingADS] = useState<any[]>([]);
    const [expiredADS, setexpiredADS] = useState<any[]>([]);
    const [rejectedADS, setrejectedADS] = useState<any[]>([]);
    const [ADSData, setADSData] = useState<any>([]);
    const [filteredADSData, setFilteredADSData] = useState('');
  
    

    const { data, isError, isLoading } = useQuery(
        'ADS',
        hospitalService.getAllAds
    );
    if (isError){
        console.log("error", isError)
    }

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (searchTerm: string) => {
        setSearchQuery(searchTerm);
    };

    const handleFilterQueryParam = (queryParams: string) => {
        hospitalService
            .filterAds(queryParams)
            .then((res) => {
                setFilteredADSData(res);
            })
            .catch((err) => {
                // toast.error()
                console.log(err);
            });
    };

    useEffect(() => {
        setADSData(data);
        console.log(ADSData)
        if (filteredADSData) {
            setADSData(filteredADSData);
        }
    }, [ADSData, data, filteredADSData]);
console.log(ADSData?.ads)
    useEffect(() => {
         if (ADSData?.ads) {
           
                setADS(ADSData?.ads);
                const approved = ADSData?.ads?.filter(
                    (row: any) => row.status === 'approved'
                );
                setapprovedADS(approved);
                const pending = ADSData?.ads?.filter(
                    (row: any) => row.status === 'pending'
                );
                setpendingADS(pending);
                const expired = ADSData?.ads?.filter(
                    (row: any) => row.status === 'expired'
                );
                setexpiredADS(expired);
                const rejected = ADSData?.ads?.filter(
                    (row: any) => row.status === 'rejected'
                );
                setrejectedADS(rejected);
            }
        
    }, [ADSData, userStore]);


    

    const tabs = [
        {
            title: 'All',
            content: (
                <Tab>
                    <AdsData
                        ads={ADS}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: ADS?.length || 0,
        },
        {
            title: 'Approved',
            content: (
                <Tab>
                    <AdsData
                         ads={approvedADS}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: approvedADS?.length || 0,
        },
        {
            title: 'Pending',
            content: (
                <Tab>
                    <AdsData
                         ads={pendingADS}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: pendingADS?.length || 0,
        },
        {
            title: 'Expired',
            content: (
                <Tab>
                    <AdsData
                      ads={expiredADS}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: expiredADS?.length || 0,
        },
        {
            title: 'Rejected',
            content: (
                <Tab>
                    <AdsData
                      ads={rejectedADS}
                        searchQuery={searchQuery}
                    />
                </Tab>
            ),
            count: rejectedADS?.length || 0,
        },
    ];
  

    
    return (
        <div className="mt-7 sm:mt-0 w-full bg-[#Ffff] md:px-8 px-4 py-4 font-700 text-xl rounded-2xl overflow-x-auto sm:overflow-hidden text-black border-transparent">
            
       
                {isLoading ? (
                    <div className="flex justify-center items-center py-64">
                        <Lottie
                            animationData={loadingState}
                            loop={true}
                            className="lottie"
                        />
                    </div>
                ) : (
                    <Tabs
                        tabs={tabs}
                        onSearchChange={handleSearchChange}
                        onFilter={handleFilterQueryParam}
                    />
                )}
            
            
        </div>
    );
};

export default AddHistories;
