import * as React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface DatePickerWithMinAgeProps {
  label: string;
  value: any;
  onChange: (date: any) => void;
  className?: string;
}

const DatePickerWithMinAge: React.FC<DatePickerWithMinAgeProps> = ({
  label,
  value,
  onChange,
  className
}) => {
  // Calculate the minimum allowed date (16 years ago from today)
  const minDate = dayjs().subtract(16, 'years');

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        maxDate={minDate} // Pass the Dayjs object instead of a JavaScript Date
        className={className}
      />
    </LocalizationProvider>
  );
};

export default DatePickerWithMinAge;
