import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import back from '../../Images/back.png';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Input from '../../reusable/Forms/Input';
import Modal from '../Billing/BillingModal';
import { usePatStore, usePatientSearchStore } from '../../store/hospital/auth';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import hospitalService from '../../services/hospitalService';
import { useHospitalAuthStore } from '../../store/hospital/auth';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { hospitalRoutes } from '../../utils/routeNames';
import { standardAmountFormat } from '../../utils/helpers';
import DocModal from './DocModal';
import SpecialtyModal from './SpecialtyModal';

type InputProps = {
    patientTmrNumber: string;
    firstName: string;
    lastName: string;
    reason: string;
    doctorId: string;
    mode: string;
    appointmentDate: string;
    appointmentTime: string;
};

const BookAppointment = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const tmrNom = usePatStore((state) => state.tmrNumber);
    const bookClicked = usePatStore((state) => state.bookClicked);
    const [formValid, setFormValid] = useState(false);
    const [searchModal, setSearchModal] = useState(false);
    const [patientAdded, setPatientAdded] = useState(false);
    const [amTimeSlots, setAmTimeSlots] = useState<string[]>([]);
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [pmTimeSlots, setPmTimeSlots] = useState<string[]>([]);
    const [selectedTab, setSelectedTab] = useState('AM');
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    const availableDays = useHospitalAuthStore(
        (state) => state.hospital.scheduling.availableDays
    );
    const hospitalId = useHospitalAuthStore((state) => state.hospital._id);
    const specialties = useHospitalAuthStore(
        (state) => state.hospital.metadata.specialties
    );
    const [isLoading, setIsLoading] = useState(false);
    const [doctors, setDoctors] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [tmrNumber, setTmrNumber] = useState('');
    const [patientInfoUpdated, setPatientInfoUpdated] = useState(false);
    const [convertedNumbers, setConvertedNumbers] = useState<number[]>([]);
    const [selectedMode, setSelectedMode] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [serviceList, setServiceList] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSpecialtyModalOpen, setIsSpecialtyModalOpen] = useState(false);
    const [docBtnEnabled, setDocBtnEnabled] = useState(false);
    const [specBtnEnabled, setSpecBtnEnabled] = useState(false);
    const [docBtn, setDocBtn] = useState(false);
    const [specBtn, setSpecBtn] = useState(false);
    const [patientTmrDisable, setPatientTmrDisable] = useState(false);

    const [selectedDoctorId, setSelectedDoctorId] = useState(null);
    const [selectedSpecId, setSelectedSpecId] = useState(null);

    const params = state;
    const {
        data: userData,
        isLoading: userDataloading,
        isError: userDataError,
    } = useQuery(['userData', tmrNom], () =>
        hospitalService.checkPatientTmrNum({ tmrNumber: tmrNom })
    );
    const fetchedFirstName = userData?.data?.payload?.data?.patient?.firstName;
    const fetchedLastName = userData?.data?.payload?.data?.patient?.lastName;

    const handleSelectClick = (e: any) => {
        e.preventDefault();
        setIsModalOpen(true);
    };
    const handleSelectSpecClick = (e: any) => {
        e.preventDefault();
        setIsSpecialtyModalOpen(true);
    };
    const handleCloseDocModal = () => {
        // Function to handle modal close event
        setDocBtn(false);
        setDocBtnEnabled(false);
        setIsModalOpen(false); // Close the modal
    };

    const handleCloseSpecModal = () => {
        // Function to handle modal close event
        setSpecBtn(false);
        setSpecBtnEnabled(false);
        setIsSpecialtyModalOpen(false); // Close the modal
    };
    const [formData3, setFormData3] = useState<any>({
        searchQuery: '',
        doctorSearch: '',
        selectedDoctor: null,
    });
    const [formData2, setFormData2] = useState<any>({
        searchQuery: '',
        specialtySearch: '',
        selectedSpecialty: null,
    });
    const handleDoctorSearchChange = (e: any) => {
        setFormData3((prevData: any) => ({
            ...prevData,
            doctorSearch: e.target.value,
        }));
        setDocBtnEnabled(true);
    };
    const handleSpecialtySearchChange = (e: any) => {
        setFormData2((prevData: any) => ({
            ...prevData,
            specialtySearch: e.target.value,
        }));
        // setSpecBtnEnabled(true);
    };
    const filteredDoctors = doctors.filter((doctor) => {
        const searchQueryLower = formData3.doctorSearch.toLowerCase();
        const fullName = `${doctor.firstName.toLowerCase()} ${doctor.lastName.toLowerCase()}`;
        return fullName.includes(searchQueryLower);
    });
    const filteredSpecialties = specialties?.filter((spec) => {
        const searchQueryLower = formData2.specialtySearch.toLowerCase();
        const fullName = spec.name.toLowerCase();
        return fullName.includes(searchQueryLower);
    });

    const fetchAvailableTimeSlots = async (
        selectedDate,
        hospitalId,
        selectedMode
    ) => {
        const response = await hospitalService.getAvailableTimeSlot(
            selectedDate,
            hospitalId,
            selectedMode
        );
        return response;
    };
    // const handleServiceSelect = (serviceId) => {
    //     setSelectedServiceId(serviceId);
    // };

    const handleServiceSelect = (serviceId: string) => {
        setSelectedServices((prevSelectedServices) => {
            if (prevSelectedServices.includes(serviceId)) {
                return prevSelectedServices.filter((id) => id !== serviceId);
            } else {
                return [...prevSelectedServices, serviceId];
            }
        });
    };
    const {
        data: availableTimeData,
        isLoading: timeSlotLoading,
        isError: timeSlotError,
    } = useQuery(
        ['availableTime', selectedDate, hospitalId, selectedMode],
        () => fetchAvailableTimeSlots(selectedDate, hospitalId, selectedMode),
        {
            enabled: !!selectedDate && !!hospitalId && !!selectedMode, // Fetch only when all parameters are present
        }
    );

    // Handle available time data
    useEffect(() => {
        if (availableTimeData) {
            const timeSlots: string[] = availableTimeData.availableTime;
            const amSlots: string[] = [];
            const pmSlots: string[] = [];
            timeSlots.forEach((slot: string) => {
                const time = slot.split(' ')[0]; // Extract time without AM/PM
                if (slot.includes('AM')) {
                    amSlots.push(time);
                } else {
                    pmSlots.push(time);
                }
            });
            setAmTimeSlots(amSlots);
            setPmTimeSlots(pmSlots);
        } else {
            setAmTimeSlots([]);
            setPmTimeSlots([]);
        }
    }, [availableTimeData]);

    const convertDaysToNumbers = (daysArray: string[]) => {
        const daysMapping = {
            Sunday: 0,
            Monday: 1,
            Tuesday: 2,
            Wednesday: 3,
            Thursday: 4,
            Friday: 5,
            Saturday: 6,
        };
        const convertedArray = daysArray.map((day) => daysMapping[day]);
        return convertedArray;
    };

    useEffect(() => {
        setConvertedNumbers(convertDaysToNumbers(availableDays));
    }, [availableDays]);

    useEffect(() => {}, [selectedTab]);
    // Function to handle tab selection
    const handleTabChange = (tab) => {
        // console.log(selectedTab);
        setSelectedTab(tab);
    };

    const [details, setDetails] = useState<InputProps>({
        patientTmrNumber: '',
        firstName: '',
        lastName: '',
        reason: '',
        doctorId: '',
        mode: '',
        appointmentDate: '',
        appointmentTime: '',
    });
    const patient = usePatientSearchStore((state) => state.searchedPatient);

    const { data } = useQuery(['doctors'], () =>
        hospitalService.getUsers('doctor')
    );

    // Function to handle appointment mode change
    const handleModeChange = (event) => {
        event.preventDefault();
        setSelectedMode(event.target.value);
    };

    // Function to handle appointment date change
    const handleDateChange = (selectedDate) => {
        setSelectedDate(selectedDate);
    };

    useEffect(() => {
        if (data && data?.users) {
            setDoctors(data.users);
        }
    }, [data]);

    const today = dayjs();
    const appointmentMode = ['walk-in', 'home-visit', 'telemedicine'];

    const {
        formState: { isDirty, isValid },
        handleSubmit,
        control,
        reset,
    } = useForm<InputProps>();

    const present = dayjs();
    const oneMonth = today.add(1, 'month');

    const [formData, setFormData] = useState({
        tmrNum: '',
        firstName: '',
        lastName: '',
        appointMode: '',
        doctorAssign: '',
        reason: '',
        selectedDate: '',
        selectedFromTime: '',
        selectedToTime: '',
    });

    const mutation = useMutation((data: InputProps) =>
        hospitalService.createAppointment(data)
    );

    const handleCloseModal = () => {
        setSearchModal(false);
    };

    const handleModalInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchModal(true);
        const { value } = e.target;
        setTmrNumber(value);
    };

    const updateTmrNumber = (newTmrNumber: string) => {
        setTmrNumber(newTmrNumber);
    };

    useEffect(() => {
        if (params && params !== null) {
            setDetails((prevDetails) => ({
                ...prevDetails,
                firstName: `${params.firstName} `,
                lastName: `${params.lastName}`,
                patientTmrNumber: params.patientTmrNumber,
            }));
        }
        if (patientAdded && patient) {
            // Normal logic When patientAdded and patient are true
            setDetails((prevDetails) => ({
                ...prevDetails,
                firstName: `${patient.firstName} `,
                lastName: `${patient.lastName}`,
                patientTmrNumber: patient.tmrNumber,
            }));
        }

        if (bookClicked && fetchedFirstName && fetchedLastName) {
            // When bookClicked is true
            setDetails((prevDetails) => ({
                ...prevDetails,
                firstName: fetchedFirstName,
                lastName: fetchedLastName,
                patientTmrNumber: tmrNom,
            }));
            setPatientTmrDisable(true);
        }
        setPatientInfoUpdated(true);
        setFormValid(true);
    }, [
        params,
        patient,
        patientAdded,
        bookClicked,
        fetchedFirstName,
        fetchedLastName,
        tmrNom,
    ]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            const response = mutation.data;
            const message = response.data.message;
            toast.success(message);

            setTimeout(() => {
                navigate(hospitalRoutes.appointment);
            }, 2000);
            reset({});
        }
    }, [mutation.isSuccess, mutation.data, reset]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            setErrorMessage(
                err.response?.data?.error?.message || 'Something went wrong'
            );
            toast.error(err.response?.data?.error?.message);
            reset({});
        } else {
            setErrorMessage('');
        }
    }, [mutation.isError, mutation.error]);

    const handleTimeSlotSelect = (slot, tab, e) => {
        e.preventDefault();
        // console.log('Selected Time Slot:', `${slot}${tab}`);
        setSelectedTab(tab);
        setDetails((prevDetails) => ({
            ...prevDetails,
            appointmentTime: slot, // Update the appointmentTime
        }));
    };

    const handleSave = (doctorId) => {
        // Handle saving the doctor ID here
        // console.log('Selected Doctor ID:', doctorId);
        setSelectedDoctorId(doctorId);
        setDocBtn(true); // Enable the button
        setIsModalOpen(false);
    };
    const handleSpecSave = (specId) => {
        // Handle saving the doctor ID here
        // console.log('Selected specialty ID:', specId);
        setSelectedSpecId(specId);
        setSpecBtn(true); // Enable the button
        setIsSpecialtyModalOpen(false);
    };

    const onSubmitForm: SubmitHandler<InputProps> = (data) => {
        setIsLoading(true);

        const selectedTabTime = selectedTab === 'AM' ? 'AM' : 'PM';

        // Initialize formattedData object with mandatory fields
        const formattedData: any = {
            firstName: details.firstName,
            lastName: details.lastName,
            patientTmrNumber: details.patientTmrNumber,
            reason: data.reason,
            mode: data.mode,
            appointmentDate: data.appointmentDate,
            appointmentTime: `${details.appointmentTime.toLowerCase()} ${selectedTabTime}`,
        };

        // Include additional services if they are selected
        if (selectedServices.length > 0) {
            formattedData.additionalServices = selectedServices;
        }

        // Include doctorId if it is provided

        if (selectedDoctorId) {
            formattedData.doctorId = selectedDoctorId;
        }
        if (selectedSpecId) {
            formattedData.specialization = selectedSpecId;
        }

        // Submit the formatted data
        mutation.mutate(formattedData);
    };
    const fetchAllServicesQuery = useQuery(
        'fetchAllServices',
        hospitalService.getAdditionalServices,
        {
            onSuccess: (data: any) => {
                const services = data.data.payload.data.additionalService;
                // hospitalAccountStore.updateAdditionalServices(services);
                setServiceList(services);
                return services;
            },
        }
    );

    useEffect(() => {
        // Trigger the fetch when the component mounts
        fetchAllServicesQuery.refetch();
    }, []);

    if (bookClicked && userDataloading) {
        return <div>Loading...</div>;
    }

    if (bookClicked && userDataError) {
        return <div>Error fetching data</div>;
    }

    return (
        <div className="w-full bg-[#F5F5F5] h-full  px-8 py-6">
            <ToastContainer />
            <div className="flex flex-col">
                <div className="flex gap-2 items-center ">
                    <button
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <img src={back} alt="" />
                    </button>
                    <p className="font-bold sm:text-[28px] text-[20px]">
                        Book Appointment
                    </p>
                </div>
                <div className="w-full flex flex-col sm:flex-row gap-4 overflow-x-auto">
                    <div className="bg-white  md:p-6 p-2 rounded-[28px] mx-2 w-full  lg:w-fit max-w-full">
                        <p className="font-medium text-[22px] mb-5 text-[#511e15] text-center py-4 lg:h-[65px] bg-lightTmrRed">
                            Patient Appointment Details
                        </p>

                        <form onSubmit={handleSubmit(onSubmitForm)}>
                            <div className="flex flex-col sm:flex-row gap-6 items-end">
                                <div className="flex flex-col sm:max-w-[343px] lg:min-h-[509px] flex-1">
                                    <div>
                                        <div className="mb-[20px]">
                                            <Controller
                                                name="patientTmrNumber"
                                                defaultValue={
                                                    details.patientTmrNumber
                                                }
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="TMR_Number"
                                                        name="TMR_Number"
                                                        label="TMR Number"
                                                        disabled={
                                                            patientTmrDisable
                                                        }
                                                        value={
                                                            details.patientTmrNumber
                                                        }
                                                        onFocus={(e: any) => {
                                                            handleModalInputChange(
                                                                e
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mb-[20px]">
                                            <Controller
                                                name="firstName"
                                                defaultValue=""
                                                control={control}
                                                render={({ field }) => (
                                                    <Input
                                                        label="First Name"
                                                        {...field}
                                                        disabled={
                                                            patientInfoUpdated
                                                        }
                                                        value={
                                                            details.firstName
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mb-[20px]">
                                            <Controller
                                                name="lastName"
                                                defaultValue=""
                                                control={control}
                                                // rules={{
                                                //     required:
                                                //         'Last Name is required',
                                                // }}
                                                render={({ field }) => (
                                                    <Input
                                                        label="Last Name"
                                                        {...field}
                                                        value={details.lastName}
                                                        disabled={
                                                            patientInfoUpdated
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mb-[20px]">
                                            <Controller
                                                name="mode"
                                                defaultValue=""
                                                control={control}
                                                rules={{
                                                    required:
                                                        'mode is required',
                                                }}
                                                render={({ field }) => (
                                                    <FormControl
                                                        fullWidth
                                                        sx={{ minWidth: 120 }}
                                                        size="small"
                                                    >
                                                        <InputLabel
                                                            id="demo-select-small-label"
                                                            htmlFor={
                                                                'Mode of Appointment'
                                                            }
                                                        >
                                                            {
                                                                'Mode of Appointment'
                                                            }
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-select-small-label"
                                                            id="demo-select-small"
                                                            label="Mode of Appointment"
                                                            {...field}
                                                            className="w-full p-2"
                                                            onChange={(e) => {
                                                                handleModeChange(
                                                                    e
                                                                );
                                                                field.onChange(
                                                                    e
                                                                );
                                                            }}
                                                        >
                                                            {appointmentMode.map(
                                                                (
                                                                    mode: string,
                                                                    index: number
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            mode
                                                                        }
                                                                    >
                                                                        {mode}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            />
                                        </div>
                                        <div className="mb-[20px]">
                                            <button
                                                onClick={handleSelectSpecClick}
                                                className="border rounded-lg w-full p-3 border-[#857371] text-left"
                                            >
                                                {/* {formData3.selectedDoctor ? `${formData3.selectedDoctor.firstName} ${formData3.selectedDoctor.lastName}` : 'Assign Doctor (optional)'} */}
                                                <p className="w-[196px] truncate">
                                                    {specBtn
                                                        ? formData2.selectedSpecialty
                                                            ? `${formData2.selectedSpecialty.name} `
                                                            : 'Select speciality (optional)'
                                                        : 'Select speciality (optional)'}
                                                </p>
                                            </button>
                                        </div>
                                        <div className="mb-[20px]">
                                            <button
                                                onClick={handleSelectClick}
                                                className="border rounded-lg w-full p-3 border-[#857371] text-left "
                                            >
                                                {/* {formData3.selectedDoctor ? `${formData3.selectedDoctor.firstName} ${formData3.selectedDoctor.lastName}` : 'Assign Doctor (optional)'} */}
                                                <p className="w-[196px] truncate">
                                                    {docBtn
                                                        ? formData3.selectedDoctor
                                                            ? `${formData3.selectedDoctor.firstName} ${formData3.selectedDoctor.lastName}`
                                                            : 'Assign Doctor (optional)'
                                                        : 'Assign Doctor (optional)'}
                                                </p>
                                            </button>
                                        </div>

                                        <div>
                                            <Controller
                                                name="reason"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: 'Enter a reason',
                                                }}
                                                render={({ field }) => (
                                                    <textarea
                                                        placeholder="Reason"
                                                        className="w-full border-[1px] h-[150px] border-[#4d4544] p-4 rounded-[8px] text-[16px] tracking-wider my-4"
                                                        {...field}
                                                    ></textarea>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="py-4 px-2 flex-1 lg:min-h-[509px] lg:max-w-fit bg-[#fff] rounded-2xl flex items-center flex-col">
                                    <div className="w-full">
                                        <Controller
                                            control={control}
                                            name="appointmentDate"
                                            defaultValue=""
                                            rules={{
                                                required: 'Pick a date',
                                            }}
                                            render={({ field }) => (
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <StaticDatePicker
                                                        displayStaticWrapperAs="desktop"
                                                        defaultValue={today}
                                                        value={
                                                            formData.selectedDate
                                                                ? dayjs(
                                                                      formData.selectedDate
                                                                  )
                                                                : null
                                                        }
                                                        onChange={(date) => {
                                                            handleDateChange(
                                                                date
                                                            );
                                                            field.onChange(
                                                                date?.format(
                                                                    'YYYY-MM-DD'
                                                                )
                                                            );
                                                        }}
                                                        minDate={present}
                                                        maxDate={oneMonth}
                                                        slotProps={{
                                                            toolbar: {
                                                                toolbarFormat:
                                                                    'ddd DD MMMM',
                                                                hidden: false,
                                                            },
                                                        }}
                                                        shouldDisableDate={(
                                                            date
                                                        ) => {
                                                            const today =
                                                                dayjs().startOf(
                                                                    'day'
                                                                );

                                                            // Disable weekends (Saturday and Sunday)
                                                            const isDisabled =
                                                                !convertedNumbers.includes(
                                                                    date.day()
                                                                );

                                                            // Disable previous days (before the current date)
                                                            const isPastDate =
                                                                date.isBefore(
                                                                    today
                                                                );

                                                            return (
                                                                isDisabled ||
                                                                isPastDate
                                                            );
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-5 bg-[#F5F5F5] px-4 w-[342px] rounded-[8px] flex flex-col py-[16px]">
                                        <h2 className="font-normal  text-base  text-[#000] mb-3 ">
                                            Available time
                                        </h2>
                                        <div className="border-[1px] rounded-md border-[#857371] flex w-full">
                                            <input
                                                type="button"
                                                className={`flex-1 text-center cursor-pointer ${
                                                    selectedTab === 'AM'
                                                        ? 'bg-[#857371] text-white'
                                                        : 'text-gray-400'
                                                }`}
                                                onClick={() =>
                                                    handleTabChange('AM')
                                                }
                                                value="AM"
                                            />
                                            <input
                                                type="button"
                                                className={`flex-1 cursor-pointer text-center ${
                                                    selectedTab === 'PM'
                                                        ? 'bg-[#857371] text-white'
                                                        : 'text-gray-400'
                                                }`}
                                                onClick={() =>
                                                    handleTabChange('PM')
                                                }
                                                value="PM"
                                            />
                                        </div>
                                        {/* Time slots */}
                                        {timeSlotLoading ? (
                                            <div className="flex justify-center items-center ">
                                                <Lottie
                                                    animationData={loadingState}
                                                    loop={true}
                                                    className="lottie"
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {amTimeSlots.length > 0 &&
                                                    selectedTab === 'AM' && (
                                                        <div className="flex overflow-x-auto w-full">
                                                            {amTimeSlots.map(
                                                                (
                                                                    slot,
                                                                    index
                                                                ) => (
                                                                    <button
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            handleTimeSlotSelect(
                                                                                slot,
                                                                                'AM',
                                                                                e
                                                                            )
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={`p-2 mx-2 my-3 text-[#201A19] rounded-lg border-[#857371] border text-[14px] hover:border-[#C00010] ${
                                                                            details.appointmentTime ===
                                                                                slot &&
                                                                            selectedTab ===
                                                                                'AM'
                                                                                ? 'bg-Tmred text-[#fff]'
                                                                                : ''
                                                                        }`}
                                                                    >{`${slot}AM`}</button>
                                                                )
                                                            )}
                                                        </div>
                                                    )}

                                                {pmTimeSlots.length > 0 &&
                                                    selectedTab === 'PM' && (
                                                        <div className="flex overflow-x-auto w-full">
                                                            {pmTimeSlots.map(
                                                                (
                                                                    slot,
                                                                    index
                                                                ) => (
                                                                    <button
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            handleTimeSlotSelect(
                                                                                slot,
                                                                                'PM',
                                                                                e
                                                                            )
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={`p-2 mx-2 my-3 text-[#201A19] rounded-lg border-[#857371] border text-[14px] hover:border-[#C00010] ${
                                                                            details.appointmentTime ===
                                                                                slot &&
                                                                            selectedTab ===
                                                                                'PM'
                                                                                ? 'bg-Tmred text-[#fff]'
                                                                                : ''
                                                                        }`}
                                                                    >{`${slot}PM`}</button>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="py-1 border-t border-t-[#D8C2BF] mt-6 flex justify-end">
                                <button
                                    disabled={
                                        !isDirty ||
                                        !isValid ||
                                        isLoading ||
                                        details.appointmentTime === ''
                                    }
                                    type="submit"
                                    className={` h-[40px] flex justify-center rounded-lg mt-10 p-4 items-center text-[#9d9b9b] font-semibold   transition-all duration-500  ${
                                        !isValid ||
                                        !isDirty ||
                                        isLoading ||
                                        details.appointmentTime === ''
                                            ? 'hover:cursor-not-allowed bg-[#1D1B201F] '
                                            : 'bg-Tmred text-white hover:cursor-pointer] '
                                    } `}
                                >
                                    {isLoading
                                        ? 'Booking...'
                                        : 'Book Appointment'}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="flex flex-col  w-[30%] bg-white  rounded-2xl p-4  h-fit">
                        <h1 className="text-center py-2 text-[22px] font-medium tracking-wide bg-lightTmrRed ">
                            Other Services
                        </h1>
                        {fetchAllServicesQuery.isLoading ? (
                            <div className="flex flex-col justify-center items-center h-screen w-full">
                                <span>Loading services...</span>
                                <Lottie
                                    animationData={loadingState}
                                    loop={true}
                                    className="lottie"
                                />
                            </div>
                        ) : fetchAllServicesQuery.isError ? (
                            <div className="flex flex-col justify-center items-center h-screen">
                                <span>
                                    Error fetching services. Please try again
                                    later.
                                </span>
                            </div>
                        ) : (
                            <>
                                {serviceList.map((service) => (
                                    <button
                                        key={service._id}
                                        onClick={() =>
                                            handleServiceSelect(service._id)
                                        }
                                        style={{
                                            background:
                                                selectedServices.includes(
                                                    service._id
                                                )
                                                    ? '#FFDAD5'
                                                    : 'transparent',
                                            color: selectedServices.includes(
                                                service._id
                                            )
                                                ? '#201A19'
                                                : '#201A19',
                                        }}
                                        className="flex items-center justify-between w-full rounded-lg border m-2 hover:border-[#857371]"
                                    >
                                        <span className="p-2 text-[14px] font-medium">
                                            {service.name}
                                        </span>
                                        <p className="text-[14px] font-medium p-2">
                                            {standardAmountFormat(
                                                service.amount,
                                                currency
                                            )}
                                        </p>
                                    </button>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {searchModal && (
                <Modal
                    closeModal={handleCloseModal}
                    updateTmrNumber={updateTmrNumber}
                    onPatientAdd={() => setPatientAdded(true)}
                />
            )}

            <DocModal
                showModal={isModalOpen}
                onClose={handleCloseDocModal}
                onSave={handleSave}
                buttonEnabled={docBtnEnabled}
                setButton3={setDocBtnEnabled}
                setFormData3={setFormData3}
                formData3={formData3}
                handleDoctorSearchChange={handleDoctorSearchChange}
                filteredDoctors={filteredDoctors}
            />

            <SpecialtyModal
                showModal={isSpecialtyModalOpen}
                onClose={handleCloseSpecModal}
                onSave={handleSpecSave}
                buttonEnabled3={specBtnEnabled}
                setButton3={setSpecBtnEnabled}
                setFormData3={setFormData2}
                formData3={formData2}
                filteredSpecializations={filteredSpecialties}
                handleSpecializationChange={handleSpecialtySearchChange}
            />
        </div>
    );
};

export default BookAppointment;
