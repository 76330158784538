import React, { useEffect, useState } from 'react';
import rectangle from '../../Images/Rectangle.png';
import tmr from '../../Images/logo.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import hospitalService from '../../services/hospitalService';
import { formatDateDmy } from '../../utils/helpers';
import { SlArrowLeft } from 'react-icons/sl';
import Lottie from 'lottie-react';
import loadingState from '../../Images/loading.json';
import { useHospitalAuthStore } from '../../store/hospital/auth';
import Input from '../../reusable/Forms/Input';
import BlockButton from '../../reusable/BlockButton';
import logger from '../../utils/logger';
import { toast } from 'react-toastify';
import BillReceipt from './BillReceipt';

const BillInvoice = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isloading, setIsLoading] = useState(false);
    const [buttonEnabled, setButton] = useState(false);
    const mutation = useMutation((data: any) => hospitalService.payBill(data));
    const currency = useHospitalAuthStore((state) => state.hospital.currency);
    useEffect(() => {
        if (mutation.isSuccess) {
            setIsLoading(false);
            let payload: any = mutation.data;
            const data = payload.data;
            logger(data.message);
            toast.success('Billing payment was successful');
            setTimeout(() => {
                toast.dismiss();
                window.location.reload();
            }, 2000);
            setFormData({
                balance: '',
            });
            setButton(false);
        }
    }, [mutation.isSuccess, mutation.data]);

    useEffect(() => {
        if (mutation.isError) {
            setIsLoading(false);
            let err: any = mutation.error;
            toast(err.response.data.error.message);
            setTimeout(() => {
                toast.dismiss();
            }, 2000);
        } else {
            // console.log('error');
        }
    }, [mutation.isError, mutation.error]);
    const Paybill = async () => {
        try {
            setIsLoading(true);
            const paymentData = {
                patientTmrNumber: billInvoice?.patientTmrNumber,
                billId: billInvoice?.billId,
                amount: formData.balance,
            };

            await mutation.mutateAsync(paymentData);
        } catch (error) {
            console.error('Error paying bill:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const [formData, setFormData] = useState({
        balance: '',
    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const { data, isLoading, isError } = useQuery(
        ['bill', id],
        () =>
            id ? hospitalService.getSingleBill(id) : Promise.reject('No ID'),
        {
            enabled: !!id, // Only fetch when id is available
        }
    );
    const billInvoice = data?.billing;
    
    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (formData.balance) {
            setButton(true);
        } else {
            setButton(false);
        }
    }, [formData.balance]);
    const calculateServiceTotal = (
        quantity: number,
        amount: number
    ): number => {
        return quantity * amount;
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center py-64">
                {' '}
                <Lottie
                    animationData={loadingState}
                    loop={true}
                    className="lottie"
                />
            </div>
        );
    }

    if (isError) {
        return <div>Error fetching invoice</div>;
    }
    return (
        <div className=" p-8">
            <div className="flex mb-8 justify-between items-center">
                <div
                    onClick={goBack}
                    className="flex items-center cursor-pointer"
                >
                    <SlArrowLeft className="m-2" />
                    <h3 className="text-[24px] md:text-[28px] font-semibold ">
                        Bill Invoice
                    </h3>
                </div>

                <div className=" flex gap-6">
                    {/* The bill receipt */}
                    <BillReceipt
                        billInvoice={billInvoice}
                    />
                </div>
            </div>

            {/* invoice */}
            <div className="flex gap-3  flex-col sm:flex-row">
                <div className="w-[60%] lg:w-[646px] p-4 rounded-[22px] bg-white">
                    <div className="rounded-[20px] flex justify-between mb-7 p-4 bg-lightPink">
                        <div>
                            <h3 className="text-[24px] mb-6 font-semibold">
                                Bill Invoice
                            </h3>
                            <div className="mb-4">
                                <p className="text-[10px] mb-[4px] text-[#857371]">
                                    Bill id
                                </p>
                                <p className="text-[12px] font-medium">
                                    {billInvoice?.billId}
                                </p>
                            </div>

                            <div>
                                <p className="text-[10px] mb-[4px] text-[#857371]">
                                    Issued on
                                </p>
                                <p className="text-[12px] font-medium">
                                    {formatDateDmy(billInvoice?.createdAt)}
                                </p>
                            </div>
                        </div>

                        <div>
                            <div className="mb-[16px]">
                                <p className="text-[10px] mb-[8px] text-[#857371]">
                                    Billed to:
                                </p>
                                <p className="text-[12px] mb-[8px] font-medium">
                                    {billInvoice?.patientTmrNumber}
                                </p>
                                <p className="text-[12px] font-medium">
                                    {`${billInvoice?.patient?.firstName} ${billInvoice?.patient?.lastName}`}
                                </p>
                            </div>
                            <div>
                                <p className="text-[10px] mb-[8px] text-[#857371]">
                                    Contact
                                </p>
                                <p className="text-[12px] mb-[8px] font-medium">
                                    {billInvoice?.patient?.phoneNumber}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="md:w-[614px]">
                        <table className="w-full py-2 px-4">
                            <thead className="bg-[#FAFAFB]">
                                <tr>
                                    <th className="py-2  px-4 text-center">
                                        <p className="text-[10px] text-left text-[#534341]">
                                            Services
                                        </p>
                                    </th>
                                    <th className="text-center">
                                        <p className="text-[10px] text-[#534341]">
                                            Qty
                                        </p>
                                    </th>
                                    <th className="text-center">
                                        <p className="text-[10px] text-[#534341]">
                                            Price
                                        </p>
                                    </th>
                                    <th className="text-center">
                                        <p className="text-[10px] text-[#534341]">
                                            Total
                                        </p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {billInvoice?.services?.map((service) => (
                                    <tr key={service._id}>
                                        <td className="py-2 px-4 ">
                                            <p className="text-[12px]">
                                                {service.name}
                                            </p>
                                        </td>
                                        <td className="py-2  px-4 text-center">
                                            <p className="text-[12px]">
                                                {service.quantity}
                                            </p>
                                        </td>
                                        <td className="py-2 px-4 text-center">
                                            <p className="text-[12px] text-center">
                                                {service.amount.toLocaleString(
                                                    'en-NG',
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </p>
                                        </td>
                                        <td className="py-2 px-4 text-center">
                                            <p className="text-[12px]">
                                                {calculateServiceTotal(
                                                    service.quantity,
                                                    service.amount
                                                ).toLocaleString('en-NG', {
                                                    style: 'currency',
                                                    currency: 'NGN',
                                                })}
                                            </p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="flex my-[28px] items-center">
                            <div>
                                <img src={rectangle} alt="" />
                            </div>

                            <button className="rounded-[12px] bg-[#930009] h-[40px] w-[238.34px] flex items-center justify-between px-[20px]">
                                <p className="text-white text-[8px] md:text-[11px]">
                                    Total (NGN)
                                </p>
                                <p className="text-white text-[12px] md:text-[16px] font-medium">
                                    {billInvoice?.totalAmount.toLocaleString(
                                        'en-NG',
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </p>
                            </button>
                        </div>
                        {/* <div className="grid mb-[28px] justify-end">
                        <p className="text-[11px] mb-[8px]">by Doctor</p>
                        <p className="text-[12px] font-semibold">
                            Kurtis Brown
                        </p>
                    </div>

                    <div className="grid justify-end">
                        <p className="mb-[6px] text-[12px] font-medium">
                            by Doctor
                        </p>
                        <p className="font-medium">Kurtis Brown</p>
                    </div> */}

                        {/* <div>
                        <img className="w-[40px]" src={hospitalAuthStore.logo} alt="" />

                        <div className="flex justify-between gap-4">
                            <div>
                                <h6 className="text-[14px] font-bold my-[8px]">
                                    {hospitalAuthStore.name}
                                </h6>
                                <p className="text-[10px] mb-2">
                                    {hospitalAuthStore.email}
                                </p>
                                <p className="text-[10px] font-semibold">
                                  {hospitalAuthStore.address}
                                </p>
                            </div>

                            <div>
                                <h6 className="text-[14px] font-bold my-[8px]">
                                    Bank
                                </h6>
                                <p className="text-[10px] mb-2">
                                    Federal Replica Bank (FRP)
                                </p>
                                <p className="text-[10px] mb-2">
                                    Account No. : 7584 8747 8485
                                </p>
                                <p className="text-[10px] mb-2">
                                    {hospitalAuthStore.name}
                                </p>
                            </div>

                            <div>
                                <h6 className="text-[14px] font-bold my-[8px]">
                                    Additional Notes
                                </h6>
                                <p className="text-[10px] mb-2">
                                    Have a great day
                                </p>
                            </div>
                        </div>
                    </div> */}

                        <div className="bg-lightPink flex rounded-lg py-2 mt-[22px] justify-center">
                            <div className="flex items-center justify-center gap-[12px]">
                                <p className="text-[11px]">Produced by TMR</p>
                                <img className="w-[24px]" src={tmr} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[40%] lg:w-[454px] p-4 sm:p-6 rounded-[22px] bg-white  flex flex-col">
                    <p className="text-[14px] font-normal text-[#000]">
                        Amount left to be paid
                    </p>
                    <p className="text-[20px] font-semibold text-[#000]">
                        {billInvoice?.outstandingAmount.toLocaleString(
                            'en-NG',
                            {
                                style: 'currency',
                                currency: `${currency}`,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }
                        )}
                    </p>
                    <form
                        action=""
                        className="flex flex-col gap-4 py-4 mt-5"
                        onSubmit={(e) => {
                            e.preventDefault();
                            Paybill();
                        }}
                    >
                        <Input
                            type="number"
                            name="balance"
                            label="Amount to pay"
                            value={formData.balance}
                            onChange={handleChange}
                            disabled={billInvoice?.outstandingAmount === 0} // Disable if outstandingAmount is 0
                        />
                        <BlockButton
                            text="Submit"
                            disabled={
                                !buttonEnabled ||
                                billInvoice?.outstandingAmount === 0
                            }
                            type="submit"
                            isLoading={isloading}
                        />
                    </form>
                    <div className="my-3 bg-[#FAFAFB] p-4 rounded-lg w-full">
                        <p className="text-[16px] font-normal text-[#000] pb-2">
                            Amount paid by
                        </p>
                        {billInvoice?.paidAmount > 0 ? (
                            <>
                                <div className="mt-2 max-h-[400px] overflow-y-auto w-full">
                                    {billInvoice?.billPayment?.map(
                                        (payment, index) => (
                                            <div
                                                key={payment._id}
                                                className={`text-[12px] p-3 flex items-center justify-between w-full ${
                                                    index !==
                                                    billInvoice.billPayment
                                                        .length -
                                                        1
                                                        ? 'border-b border-[#D8C2BF]'
                                                        : ''
                                                }`}
                                            >
                                                <div>
                                                    <h3 className="text-[#201A19] font-medium text-[14px] flex-wrap">
                                                        {payment?.paidBy?.name}
                                                    </h3>
                                                </div>
                                                <div className="flex flex-col gap-2 ">
                                                    <span className="text-[#534341] font-semibold text-[14px]">
                                                        {payment.amountPaid.toLocaleString(
                                                            'en-NG',
                                                            {
                                                                style: 'currency',
                                                                currency: 'NGN',
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )}
                                                    </span>
                                                    <span className="text-[#857371] font-medium text-[12px]">
                                                        {formatDateDmy(
                                                            payment.createdAt
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </>
                        ) : (
                            <p className="text-[14px] font-semibold text-[#000] text-center">
                                No amount paid
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BillInvoice;
